export const Localization = () => {
    return (
        <div
            className="rounded-lg border overflow-hidden w-max"
            dangerouslySetInnerHTML={{__html:`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.1601326291153!2d11.547636352475878!3d3.886989880974477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bc5bff09c0027%3A0xba9dfe40d2e19522!2sField%20Legality%20Advisory%20Group%20(FLAG)!5e0!3m2!1sen!2scm!4v1691585972401!5m2!1sen!2scm" width="600" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}}
        >
            
        </div>
    );
}

export default Localization;