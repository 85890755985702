import SADElement from "./SADElement"

const html = `
<p class="font-bold">
<ul class="list-disc list-inside">
<p class="font-bold">
<ul class="list-disc list-inside">
<li>
    Problématiques de développement durable et de la gestion des ressources naturelles
</li>
<li>
    Sensibilisation sur les enjeux environnementaux pertinents
</li>
<li>
    FLAG s’engage à mettre à la disposition des acteurs (grand public, bailleurs et fonds, experts, etc.) à travers les TIC, une information fiable sur les enjeux et les conséquences de l’exploitation illégale des RN et du commerce associé sur l’économie nationale et les conditions de vie des populations
</li>

</ul>

</p>
</ul>

</p>

`

const InformationQualityAndAvailability = () => {
    return (
        <SADElement
            DAS="qualite-et-disponibilite-de-linfo"
            sadPoints={html}
            title="Qualité et Disponibilié de l'information"
        />
    )
};

export default InformationQualityAndAvailability;