import Blog from "../Blog";


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];

const Legality = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            // tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={""}
            // title="La société civile en quête de nouvelles techniques d’investigation"
            // bannerImgUrl="http://flag-cmr.org/pictures/actus/IMG_20996.JPG"
            // bannerImgCaption="Observation Indépendante, Du 6 au 9 février 2018 0 Kribi"
            programTitle="PROGRAMME LÉGALITÉ"
            programPoints={[
                "Réforme et application des lois relatives à la gestion des ressources naturelles ;",
                "Actions de veille juridique sous-régionale ;",
                "Partenariats stratégiques pour le suivi de la légalité dans la gestion des ressources naturelles dans le bassin du Congo."
            ]}
            // sideArticles={sideArticles}
        />
    )
};

export default Legality;