import { BsGear, BsImages, BsPlusCircle, BsTrash, BsTrash3 } from "react-icons/bs";
import CustomButton from "../../components/CustomButton";
import { useEffect, useState } from "react";
import { AlbumElementType, PhotoElementType } from "../../global/types";
import { collection, deleteDoc, doc, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../App";
import { DeleteConfirmation } from "../../components/Modals";
import { ChipSelect, CustomDropdown } from "../../components/CustomInput";
import { useNavigate, useSearchParams } from "react-router-dom";

type PhotoItemType = {
    title: string;
    literalDate: string;
    albumUrl: string;
    imgUrl: string;
};

const photos: PhotoItemType[] = [
    {
        title: "Formation USINL",
        literalDate: "Mars 2023, Douala",
        albumUrl: "https://www.flickr.com/photos/193093979@N03/albums/72177720308375223",
        imgUrl: "https://live.staticflickr.com/65535/52906487154_49d8fc754a_w.jpg",
    },
    {
        title: "Formation USINL",
        literalDate: "Février 2023, Bertoua",
        albumUrl: "https://www.flickr.com/photos/193093979@N03/albums/72177720308360267",
        imgUrl: "https://live.staticflickr.com/65535/52906359731_9dc75c32d4_w.jpg",
    },
    {
        title: "Formation USINL",
        literalDate: "Février 2023, Sangmelima",
        albumUrl: "https://www.flickr.com/photos/193093979@N03/albums/72177720308375523",
        imgUrl: "https://live.staticflickr.com/65535/52906514899_b0653af1fd_w.jpg"
    },
    {
        title: "Forum sur la gouvernance forestière",
        literalDate: "30-31 octobre 2018, Brazzaville",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157700667332612",
        imgUrl: "https://farm5.staticflickr.com/4824/31071873237_fd689ec0dc_n.jpg",
    },
    {
        title: "Atelier de lancement du projet OTP",
        literalDate: "25 septembre 2018",
        albumUrl: "https://www.flickr.com/gp/142888816@N04/3C016i",
        imgUrl: "https://farm2.staticflickr.com/1930/43382982900_ac5455d4c1.jpg",
    },
    {
        title: "Techniques d'investigation",
        literalDate: "2018",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157700735565871",
        imgUrl: "https://farm2.staticflickr.com/1972/31365293778_d60466d37f_n.jpg",
    },
    {
        title: "Fête du travail",
        literalDate: "1er mai 2018",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157696437849550",
        imgUrl: "https://farm2.staticflickr.com/1975/44516871334_a47a71a4f5_n.jpg",
    },
    {
        title: "Séminaire APV",
        literalDate: "Avril 2018",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157699062796152",
        imgUrl: "https://farm2.staticflickr.com/1978/43425872390_21ab0d3850_n.jpg",
    },
    {
        title: "Kinshasa OI",
        literalDate: "Avril 2018",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157696437156220",
        imgUrl: "https://farm2.staticflickr.com/1911/44516458334_5918d8dfe5_n.jpg",
    },
    {
        title: "Journée Internationale de la Femme",
        literalDate: "08 mars 2018",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157674362637928",
        imgUrl: "https://farm2.staticflickr.com/1917/31364807448_c82972b514_n.jpg",
    },
    {
        title: "Atelier CIDT Interpol",
        literalDate: "Novembre 2017",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157702088894254",
        imgUrl: "https://farm2.staticflickr.com/1933/31364747748_65f777643f_n.jpg",
    },
    {
        title: "Side Event  FLAG PFBC",
        literalDate: "Octobre 2017",
        albumUrl: "https://www.flickr.com/photos/142888816@N04/albums/72157702088791814",
        imgUrl: "https://farm2.staticflickr.com/1909/45238265771_51237ab0cf_n.jpg",
    }
];


const PhotoItem: React.FC<PhotoElementType&{showSettings?:boolean, onDeleteClick:(title:string, id:string) => void, onClick?:() =>void, isAlbum:boolean}>
    = ({ id, imageUrl, category, index, subtitle, title, showSettings, onDeleteClick, onClick, albumId, isAlbum }) => {
    return (
        <div className="relative">
            <div
                // data-flickr-embed="true"
                // href={imageUrl}
                // aria-disabled
                title={title}
                className="px-1 py-2 group rounded-md"
            >
                <div onClick={onClick} className="mb-2 rounded cursor-pointer relative">
                    <BsImages size={32} className="text-slate-200 absolute right-4 top-4" />
                    <img
                        className="rounded mx-auto group-hover:scale-95 group-hover:shadow-lg transition"
                        src={imageUrl}
                        alt={title}
                    />
                </div>
                <div className="bg-primary px-4 py-1 rounded-tl-[20px] leading-tight">
                    <span className="text-white text-sm lg:text-base font-medium">{title}</span>
                    <br />
                    <span className="text-white text-xs lg:text-sm">{subtitle}</span>
                </div>
            </div>
            {
                showSettings?
                <div className="flex gap-x-2">
                    <CustomButton
                        title=""
                        navigateTo={'modifier?id='+id}
                        state={{content:{id, imageUrl,category, index, subtitle, title,}}}
                        icon={<BsGear size={16}/>}
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                    <CustomButton
                        title=""
                        onClick={() => onDeleteClick(title, id as unknown as string)}
                        icon={<BsTrash size={16}/>}
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                    <CustomButton
                        title="Ajouter une Photo"
                        navigateTo={'nouveau?albumId='+albumId}
                        icon={<BsPlusCircle size={16}/>}
                        className="h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                </div>
                :
                null
            }
        </div>
    )
}

type PhotosMediathequePropsType = {
    showSettings?:boolean;
};

const PhotosMediatheque:React.FC<PhotosMediathequePropsType> = ({showSettings}) => {
    const [albums, setAlbums] = useState<AlbumElementType []>([]);
    const [photos, setPhotos] = useState<PhotoElementType[]>([]);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [deleteLoading, setDdeleteLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    const navigate = useNavigate();
    const photosSubCategory = searchParams.get('subcategory');
    const albumId = searchParams.get('albumid')

    const onDeleteMedia = async () => {
        if(deleteLoading || !deleteMedia?.id) return;
        setDdeleteLoading(true);
        let docPath:string;
        
        if(albumId) {
            docPath = 'mediatheque/'+albumId+'/photos/'+deleteMedia.id;
        } else {
            docPath = 'mediatheque/'+deleteMedia.id
        }

        const dbDoc = doc(db, docPath);
        await deleteDoc(dbDoc);
        setAlbums(albums.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setPhotos(photos.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setDdeleteLoading(false);
    };
    
    const getAndSetMediathequeAlbums = async() => {
        if(loading) return;
        setLoading(true);

        const q = query(collection(db, 'mediatheque'), orderBy('index', 'desc'), where('category', '==', 'photos'));
        const albumsSnapshot = await getDocs(q);
        const dbAlbums = albumsSnapshot.docs.map(album => ({...album.data(), id:album.id}) as unknown as AlbumElementType);
        
        setAlbums(dbAlbums);
        setLoading(false);
    };

    const getAndSetMediathequePhotos = async () => {
        if(loading) return;
        setLoading(true);

        const q = query(collection(db, 'mediatheque/'+albumId+'/photos'), orderBy('index', 'desc'));
        const photosSnapshot = await getDocs(q);
        const dbPhotos = photosSnapshot.docs.map(photo => ({...photo.data(), id:photo.id}) as unknown as PhotoElementType);
        
        setPhotos(dbPhotos);
        setLoading(false);
    };


    const photosCategories = [
        {label:'Accompagnement Communautaire', value:'accompagnement-communautaire'},
        {label:'Surveillance environnementale', value:'surveillance-environnementale'},
        {label:'Formation Continue (CEAS GRNE)', value:'formation-continue'},
        {label:"Qualité et Disponibilié de l'information", value:'qualite-et-disponibilite-de-linfo'},
        {label:'Encadrement du Secteur Artisanal', value:'supervision-du-secteur-artisanal'},
        {label:'FLAG', value:'flag'},
        // {label:'Tout', value:'all'}
    ];

    const gridItems = albumId?photos:albums.filter(item => item.subCategory == photosSubCategory)

    useEffect(() => {
        getAndSetMediathequePhotos();
    }, [albumId]);

    useEffect(() => {
        if(photosSubCategory) getAndSetMediathequeAlbums();
    }, [photosSubCategory]);
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <ChipSelect
                label="Catégorie des Photos"
                chipOptions={photosCategories}
                value={photosSubCategory||undefined}
                onSelect={(option) => {
                    searchParams.set('subcategory', option.value);
                    navigate('?'+searchParams.toString(), {preventScrollReset:true, replace:true})
                }}
                className="pb-2.5 mt-0.5 mb-4 text-base border"
                labelClassName="font-medium mb-1 text-lg"
            />
            
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >
                    { albumId? 'Album '+albums.find(item => item.id?.toString() === albumId)?.title:'Albums Photos'}
                </h1>
                <div className="flex gap-x-4">
                    {showSettings?<CustomButton
                        title="Créer un album"
                        className="py-2.5"
                        navigateTo="nouvel-album"
                    />:null}
                    {(showSettings && albumId)?<CustomButton
                        title="Ajouter des Photos"
                        className="py-2.5"
                        navigateTo={'nouveau?albumId='+albumId}
                    />:null}
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-6">
                {gridItems.map(item => 
                    <PhotoItem
                        key={item.id}
                        isAlbum={Boolean(!albumId)}
                        {...item}
                        onClick={() => {
                            // only navigate to an album when we are not currently viewing another album photos
                            if(!albumId) {
                                searchParams.set('albumid', item.id as unknown as string);
                                navigate('?'+searchParams.toString());
                            }
                        }} 
                        albumId={item.id as unknown as string}
                        onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                        showSettings={showSettings}
                    />
                )}
                
            </div>
            {
                loading?
                <h1 className="text-gray-400 text-xl lg:text-xl mb-2 font-bold" >
                    Chargement...
                </h1>
                :
                gridItems.length ===0?
                    <h1 className="text-gray-400 text-xl lg:text-xl mb-2 font-bold" >
                        {photosSubCategory?'Aucun media ici pour le moment...':"Veuillez Sélectionner Une Catégorie S'il Vous Plaît"}
                    </h1>
                :
                null
            }
            <DeleteConfirmation
                hideModal={() => setDeleteMedia(null)}
                message={'Voulez vous supprimer "'+deleteMedia?.title+'" ?'}
                title={'Supprimer '+ (albumId?"l'album":'la photo')}
                showModal={deleteMedia?.id?true:false}
                confirmModal={onDeleteMedia}
            />
        </div>
    )
};


export default PhotosMediatheque;