import SADElement from "./SADElement"

const html = `
Les actions de FLAG dans l’accompagnement communautaire visent à soutenir les communautés locales et peuples autochtones, riveraines des ressources naturelles au Cameroun pour qu’elles subviennent dignement à leurs besoins primaires et bénéficient des retombés d’exploitation de ces ressources, sans altérer leurs usages culturels. FLAG milite par ailleurs à ce qu’elles s’impliquent par leurs propres moyens dans la gestion durables des ressources naturelles. 
<ul class="list-disc list-inside">
    <li>
        Dans le secteur faunique, FLAG soutien spécifiquement la participation des communautés locales et peuples autochtones dans la gestion des aires protégées à travers d’une part, l’élaboration et l’utilisation des méthodologies de surveillance de la faune adaptées au contexte communautaire et d’autre part, la mise en œuvre des activités génératrices de revenus pour encourager leurs actions de surveillance.
    </li>
    <li>
        Dans le secteur forestier, FLAG contribue à la défense des droits des communautés en matière d’exploitation forestière ; ceci à travers le suivi du respect des cahiers de charges des sociétés d’exploitation forestière et l’encouragement des administrations compétentes à se saisir des cas d’exploitation forestière illégales de manière générale et de violation des droits des communautés en particulier. 
    </li>
</ul>
`

const CommunitySupport = () => {
    return (
        <SADElement
            DAS="accompagnement-communautaire"
            sadPoints={html}
            title="Accompagnement Communautaire"
        />
    )
};

export default CommunitySupport;