import { AiFillFacebook, AiFillLinkedin, AiFillTwitterCircle, AiFillTwitterSquare } from "react-icons/ai";

const teamMembers: TeamMemberPropsType[] = [
    {
        name: "Horline NJIKE épse BILOGUE MVOGO",
        role: "Secrétaire Générale",
        imgUrl: require('../../assets/team/horline-njike-epse-bilogue-mvo.jpeg'),
        about: "Responsable en chef du management et de l’animation générale de l’organisation. Juriste de formation, Horline totalise plus de 10 ans d’expérience dans l'observation indépendante des ressources naturelles dans le Bassin du Congo. Membre fondatrice et administratrice de FLAG, elle est rigoureuse et dotée de très fortes capacités d’analyse."
    },
    {
        name: "Jean Cyrille OWADA",
        role: "Directeur des Programmes",
        imgUrl: require('../../assets/team/jean-cyrille-owada.jpeg'),
        about: "Co-fondateur de FLAG, Jean Cyrille assure également la fonction de Directeur des Programmes. Ingénieur en Chef des Eaux et Forêts, Expert en Contrôle forestier, avec plus de 17 ans d’expérience, il est l’un des pionniers de la mise en œuvre de l’Observation Indépendante des Forêts en Afrique."
    },
    {
        name: "Serge MOUKOURI",
        role: "Directeur chargé du Développement et du Suivi Interne",
        imgUrl: require('../../assets/team/serge-moukouri-.jpeg'),
        about: "Expert en question forestières, Serge développe et met en œuvre depuis deux décennies des actions en rapport avec le suivi indépendant de la gouvernance et de la gestion durable des ressources naturelles en Afrique Centrale et de l’Ouest. Co-fondateur de FLAG, il est impliqué dans l’exécution des projets et supervise la conception et la mise en œuvre de la stratégie de croissance de l’organisation."
    },
    {
        name: "Wilfred MBENDJE BAHANAK",
        role: "Directeur Administratif et Financier",
        imgUrl: require('../../assets/team/wilfried-mbendje-bahanak.jpeg'),
        about: "Wilfred est le Directeur Administratif et Financier. Son rôle au sein de FLAG consiste à coordonner la gestion administrative, financière, du suivi et la mise en œuvre des cahiers de charges des différents bailleurs de fonds. Wilfred est nanti d'une forte expérience dans le management des organisations."
    },
    {
        name:"MENGUE MVONDO Danielle Mireille",
        role:"Responsable de la Logistique",
        imgUrl:require('../../assets/team/mengueAsset 3-50.jpg'),
        about:"Chargée de la Comptabilité et de la Logistique. Mon rôle consiste à tenir la comptabilité de l'organisation et des projets, à contribuer à l'élaboration et au suivi du respect des procédures internes de gestion et à la bonne gestion du patrimoine de l'organisation."
    },
    {
        name: "Théophile TCHOFFO NZOUWO",
        role: "Assistant Technique Principal",
        imgUrl: require('../../assets/team/theophile-tchoffo-nzouwo.jpeg'),
        about: "Ingénieur des eaux, forêts et chasses, MS Audit et Certification des forêts, Théophile est Assistant technique principal en charge du renforcement des capacités des OSC en matière de suivi de la gestion des ressources naturelles. Il est également impliqué dans la mise en œuvre des actions relatives au développement organisationnel au sein de FLAG."
    },
    {
        name: "Daniel Armel OWONA MBARGA",
        role: "Assistant Technique Principal",
        imgUrl: require('../../assets/team/daniel-armel-owona-mbarga.jpeg'),
        about: "Titulaire d'un Master en Droits de l'homme et Action Humanitaire, Daniel officie à FLAG en qualité d’Assistant Technique Juriste. Il s’occupe entre autres de la conception et de la mise en œuvre d'un outil de veille légale, de l’analyse du contentieux forestier initié par les administrations."
    },
    {
        name: "Zora BATUPE",
        role: "Technical Advisor",
        imgUrl: require('../../assets/team/zora-batupe.jpg'),
        about: "The young lady has a Bachelor of Science degree in environmental sciences and a Master’s degree in Environmental management. Being passionate about biodiversity conservation she decided to pursue a career in the domain. Zora is part of a very dynamic team of young experts at FLAG and participated in the conception, implementation and follow up of ongoing projects."
    },
    {
        name:"BENENGUEGNE Marc Clément",
        role:"Assistant Technique",
        imgUrl:require('../../assets/team/benenguegne_marc_clement.jpg'),
        about:"Ingénieur Forestier, Valorisation du Bois et des Fibres (Bambou & Rotin), Marc Clément dispose de près de 8 ans d’expérience pratique dans l’élaboration et la mise en œuvre des projets forestiers et fauniques, l’exploitation forestière, l’industrie du bois, la formation & renforcement des capacités… Au sein de FLAG, il participe à la conception et la mise en œuvre des projets de Suivi de la Gestion des Ressources Naturelles à travers l’Observation Indépendante, la Promotion de la légalité du bois et des chaînes d’approvisionnement, la Vulgarisation et la mise en valeur des outils technologiques de surveillance forestière et de commerce du bois..."
    },
    {
        name: " Lore Esther MBOG",
        role: "Responsable Communication et Relations Publiques",
        imgUrl: require('../../assets/team/esther-lore-souhe.jpeg'),
        about: "Sous la supervision du Directeur du Suivi et du Développement Interne, Lore Esther met en place des stratégies de communication interne et externe de tous les projets de l’organisation et assure la collaboration avec les médias dans le but d'accroître la notoriété de l'organisation."
    },
    // {
    //     name: "Merlin FEUDJEU TAKAFO",
    //     role: "Assistant Technique",
    //     imgUrl: require('../../assets/team/merlin-feudjeu-takafo.jpeg'),
    //     about: "Titulaire d’un Master Professionnel en Aménagement et Gestion des Ressources Naturelles, spécialité Audit et Certification des forêts, Merlin est Assistant Technique en charge de la conception et la gestion des bases de données forestières. Il intervient également dans la mise en œuvre des actions liées au développement organisationnel au sein de FLAG."
    // },
    {
        name:"Josée Sylviane OWONA FOUDA",
        role:"Assistante Communication",
        imgUrl:require('../../assets/team/josee_sylviane_owona_fouda.jpg'),
        about:"Titulaire d'une licence en Communication option journalisme et Master 2 en cours de validation, Josée officie à FLAG en tant qu'Assistante communication. Sous la supervision de la Responsable Communication et Relations publiques, elle s'occupe de la rédaction et l'animation des plateformes numériques, participe l'élaboration de communication de l'organisation, ainsi que les relations -presse."
    },
    {
        name:"Zogou Mengle Georges Ismaël",
        role:"Chauffeur",
        imgUrl:require('../../assets/team/georges-zouglou.jpg'),
        about:"Titulaire d’un CAP en mécanique auto, Georges officie à FLAG comme chauffeur dans le cadre duquel il prend part aux missions d’investigation sur le terrain  avec les équipes techniques. Il s’assure de l’entretien et du contrôle technique du véhicule. Et s’occupe également de la transmission des courriers dans les services administratifs."
    },
    {
        name:"EDOA Yves Stéphane",
        role:"IT Officer",
        imgUrl:require('../../assets/team/edoa-yves-stephane.png'),
        about:"Ingénieur de Conception en génie informatique, excelle en tant qu'IT Officer chez FLAG. Possède des bonnes bases dans la gestion des infrastructures informatiques, la Sécurité Informatique, la Gestion des Systèmes et des Logiciels, l'Assistance Technique, et la Gestion des Données, il apporte une expertise pointue pour optimiser les opérations informatiques et garantir la sécurité des systèmes au sein de l'organisation."
    },
    {
        name:"Manuela Ruth MBOE",
        role:"Assistante logisticienne",
        imgUrl:require('../../assets/team/mboe-ruth-manuela.png'),
        about:"Titulaire d'un BTS en Logistique et Transport et actuellement en cours de validation pour une Licence professionnelle, Manuela occupe le rôle d'Assistante logisticienne junior chez FLAG. Sous la supervision attentive de la Responsable Comptable et Logistique, elle joue un rôle essentiel dans plusieurs aspects de la gestion logistique de l'organisation."
    },
    {
      name:"ÉMILE STEVE NOAH ELOUNDOU",
      role:"Assistant Juriste",
      imgUrl:require('../../assets/team/emile_steve_noah_eloundou.jpg'),
      about:`Émile Steve  est un juriste passionné par les questions de gouvernance environnementale et les problématiques sociales. Titulaire de deux masters en droit, l'un en droit public interne et l'autre en histoire du droit et socio-anthropologie juridique, il est actuellement doctorant à l'université de Douala et de Yaoundé 2 à SOA. Actuellement il occupe le poste de juriste assistant technique au sein de FLAG. Ses compétences en tant que juriste et consultant socio-économiste lui permettent d'apporter une expertise précieuse à l'organisation.
      Avec son parcours diversifié et son engagement dans des domaines cruciaux tels que la gouvernance environnementale et les questions sociales, il est un acteur clé dans la mise en oeuvre des projets au sein de FLAG.`
    }
]

type TeamMemberPropsType = {
    name: string;
    role: string;
    imgUrl: string;
    about: string;
}

const TeamMember: React.FC<TeamMemberPropsType> = ({ name, role, imgUrl, about }) => {
    return (
        <div className="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-gray-200 dark:bg-slate-900 dark:border-gray-700">
            <div className="flex items-center gap-x-4">
                <img
                    className="rounded-full w-32 h-32"
                    src={imgUrl}
                    alt={name}
                />
                <div className="grow">
                    <h3 className="font-medium text-gray-800 dark:text-gray-200">
                        {name}
                    </h3>
                    <p className="text-xs uppercase text-gray-500">
                        {role}
                    </p>
                </div>
            </div>

            <p className="mt-3 mb-4 text-gray-500 whitespace-pre-wrap">
                {about}
            </p>
            <div className="mt-auto space-x-1">
                <a className="inline-flex justify-center items-center text-gray-500 border border-gray-200 p-0.5 rounded-md hover:text-gray-800 hover:shadow-sm dark:hover:text-gray-200 dark:border-gray-700 dark:hover:shadow-slate-700/[.7]" href="#">
                    <AiFillTwitterSquare
                        size={22}
                    />
                </a>
                <a className="inline-flex justify-center items-center text-gray-500 border border-gray-200 p-0.5 rounded-md hover:text-gray-800 hover:shadow-sm dark:hover:text-gray-200 dark:border-gray-700 dark:hover:shadow-slate-700/[.7]" href="#">
                    <AiFillFacebook
                        size={22}
                    />
                </a>
                <a className="inline-flex justify-center items-center text-gray-500 border border-gray-200 p-0.5 rounded-md hover:text-gray-800 hover:shadow-sm dark:hover:text-gray-200 dark:border-gray-700 dark:hover:shadow-slate-700/[.7]" href="#">
                    <AiFillLinkedin
                        size={22}
                    />
                </a>
            </div>
        </div>
    )
}

const Team = () => {
    return (
        <div className="w-full bg-black bg-opacity-50 my-10 lg:pt-8 pb-12">

            <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8  mx-auto">
                <div className="max-w-2xl mx-auto text-center mb-6 lg:mb-8">
                    <h2 className="text-2xl font-bold md:text-4xl text-white md:leading-tight dark:text-black">Équipe</h2>
                    {/* <p className="mt-1 text-gray-600 dark:text-gray-400">Creative people</p> */}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {
                        teamMembers.map(member => <TeamMember {...member} />)
                    }
                    {/* <a className="col-span-full lg:col-span-1 group flex flex-col justify-center text-center rounded-xl p-4 md:p-6 border border-dashed border-gray-200 hover:shadow-sm dark:border-gray-700" href="#">
                    <h3 className="text-lg text-gray-800 dark:text-gray-200">
                        We are hiring!
                    </h3>
                    <div>
                        <span className="inline-flex items-center gap-x-2 text-blue-600 group-hover:text-blue-700 dark:text-blue-500 dark:group-hover:text-blue-400">
                            See all opening positions
                            <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </span>
                    </div>
                </a> */}
                </div>
            </div>
        </div>
    )
};


export default Team;