import { sideArticles } from "../../assets/examples";
import Blog from "../Blog"

const beforeContent = `
<div>
    <strong>Field Legality Advisory Group (FLAG)</strong> est une organisation à vocation régionale créée en
    Mai 2012 au Cameroun par un groupe d’experts (juristes et forestiers) qui ont contribué depuis de longues
    années à la mise en œuvre du suivi indépendant des activités forestières en Afrique. Ces derniers ont
    œuvré pour le compte de plusieurs ONG internationales pionnières dans le domaine du suivi indépendant
    dans différents pays du bassin du Congo. Et à la suite des changements intervenus dans les orientations
    stratégiques de ces organisations, il s’est avéré opportun de mettre en marche le processus domestication
    et l’internalisation des interventions relevant jadis desdites organisations dans le suivi des activités
    forestières avec comme perspective de les améliorer, étendre et pérenniser. Les fondateurs se sont donc
    employés à donner un contenu à la dimension de leurs ambitions en matière de suivi indépendant de la
    gestion des ressources naturelles. Tout au long de ce processus, l’équipe des fondateurs de FLAG a
    bénéficié de l’appui technique de Well Grounded et de la facilité logistique de Green Development Advocate
    à travers la mise à disposition du cadre de travail. C’est au détour d’un échange sur la conception d’un
    projet sous régional que l’idée de la mise en place de FLAG se concrétise et que commence la longue
    odyssée qui conduira à la légalisation en tant qu’association le 21 septembre 2012 (référence
    <strong>N°00001549/RDA/J06/DAPP</strong>) avec une poignée de membres.
</div>
`
const content = `<div>
    Le processus de structuration de l’organisation se poursuit avec la mise en place progressive des
    instances de gestion et de supervision. Parallèlement, l’équipe se diversifie et s’agrandit à la faveur
    de la mise en œuvre de différents projets.
</div>
`


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];


const WhoAreWe = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={content}
            programTitle="QUI SOMMES-NOUS ?"
            beforeContent={beforeContent}
            bannerImg={require('../../assets/about/qui_sommes_nous.jpg')}
            // sideArticles={sideArticles}
        />
    )
};

export default WhoAreWe;