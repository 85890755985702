import { HiOutlineDocumentText } from "react-icons/hi2";
import MainContent from "./MainContent";
import SidePanel from "./SidePanel";
import CustomButton from "../../components/CustomButton";



export type SideArticleType = {
    title:string;
    imageUrl?:string;
    subtitle:string;
};

export type MainContentPropsType = {
    programTitle?: string;
    programPoints?: string[];
    bannerImgCaption?: string;
    bannerImgUrl?: string;
    bannerImg?:any;
    title?: string;
    articleContent: string;
    tags?: string[];
    usefulLinks?:{title:string, linkTo:string}[];
    beforeContent?:string;
    document?:BlogDocumentType;
};

export type SidePanelPropsType = {
    sideArticles?: SideArticleType[];
    showDASContent?:boolean;
    showDocuments?:boolean;
    showSocials?:boolean
};

export type BlogDocumentType = {
    title:string;
    fileUrl:string;
    description?:string
};


const Blog:React.FC<MainContentPropsType&SidePanelPropsType> = ({...props}) => {
    return (
        <div
            className="max-w-6xl px-4 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl"
        >
            <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 md:gap-x-4">
                <MainContent 
                    {...props}
                />
                <SidePanel
                    sideArticles={props.sideArticles}
                    showDocuments={props.showDocuments}
                    showDASContent={props.showDASContent}
                    showSocials={props.showSocials}
                />
            </div>
        </div>
    )
};


export default Blog;