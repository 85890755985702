import Blog from "./Blog";
import Carousel from "./Carousel";
import Partners from "./Partners";
import carousel1 from '../../assets/carousels/carousel1.jpg';
import carousel2 from '../../assets/carousels/carousel2.jpg';
import carousel3 from '../../assets/carousels/carousel3.jpg';
import carousel4 from '../../assets/carousels/carousel4.jpg';
import carousel5 from '../../assets/carousels/carousel5.jpg';
import carousel6 from '../../assets/carousels/carousel6.jpg';
import carousel7 from '../../assets/carousels/carousel7.jpg';
import carousel8 from '../../assets/carousels/carousel6.jpg';
import Search from '../../components/Search';
import Features from "./Features";


const Home = () => {
    return (
        <div className="flex flex-1 flex-col">
            <div className="my-4 lg:my-8">
                <Carousel
                    carousels={[
                        carousel1, carousel2, carousel3, carousel4,
                        carousel5, carousel6, carousel7, carousel8
                    ]}
                />
                <Blog/>
                <div className="bg-primary bg-opacity-80 p-4">
                    <Features/>
                </div>
                <div className="w-full py-5 md:h-[40vh] bg-white ">
                 <div className=' flex flex-1 items-center h-full w-full justify-center rounded-xl'>
                    <Search
                        containerClassName='flex-1 max-w-lg px-3'
                    />
                </div>
                </div>
                
                <Partners/>
            </div>
        </div>
    )
};

export default Home;