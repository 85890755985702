import { useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { ImSearch, ImSpinner8 } from 'react-icons/im';
import { PiArticleFill, PiChatsFill, PiFileTextFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';


type SearchPropsType = {
    titleClassName?:string;
    containerClassName?:string;
};

const Search:React.FC<SearchPropsType> = ({titleClassName, containerClassName}) => {
    const [searchCategory, setSearchCategory] = useState<'sad'|'lex'|'forum'>('sad');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    const onSearch = async () => {
        let searchRoute = '';
        if(searchCategory ==='sad'){
            searchRoute ='/das';
        } else if(searchCategory ==='lex') {
            searchRoute ='/lex';
        } else if (searchCategory ==='forum') {
            searchRoute ='/forums';
        }
        navigate(`${searchRoute}?`+ new URLSearchParams({quicksearch:`${title}`}));
    };
    
    const routeTitleMap = {
        'sad':"Domaines d'Actions",
        'lex':'LEX',
        'forum':'le Forum'
    };
    return (
        <div className={"max-w-[85rem] mx-auto sm:py-8 "+ containerClassName}>
            <div className="text-center">
                <h1 className={"text-lg font-bold text-black uppercase "+titleClassName}>
                    Rechercher sur le site...
                </h1>

                <div className="mt-2 sm:mt-6 mx-auto max-w-xl relative">
                    <div className="relative flex  bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:shadow-gray-900/[.2]">
                        <div className="flex-[1_0_0%]">
                            <label htmlFor="hs-search-article-1" className="block text-sm text-gray-700 font-medium dark:text-white"><span className="sr-only">Search article</span></label>
                            <input
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                type="text"
                                name="hs-search-article-1"
                                id="hs-search-article-1"
                                className="customInput  p-1.5 pl-4 h-full placeholder:text-base"
                                placeholder={"Rechercher Dans "+routeTitleMap[searchCategory]+'...'}
                                autoComplete="off"
                            />
                        </div>
                        <div className="flex-[0_0_auto]">
                            <button onClick={onSearch} className="p-2 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-primary-dark transition-all text-sm dark:focus:ring-offset-gray-800">
                                {
                                    // searchLoading?
                                    false?
                                        <ImSpinner8 size={20} className="animate-spin" />
                                    : 
                                        <ImSearch size={20}/>
                                }

                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <button 
                        onClick={() => setSearchCategory('sad')}
                        className={"searchCategoryButton "+ (searchCategory ==='sad'?'searchCategoryFocused':'')}
                    >
                        <PiArticleFill size={20}/>
                        Domaines d'Actions
                    </button>
                    <button
                        onClick={() => setSearchCategory('lex')}
                        className={"searchCategoryButton "+ (searchCategory ==='lex'?'searchCategoryFocused':'')}
                    >
                        <PiFileTextFill size={20}/>
                        LEX
                    </button>
                    <button 
                        onClick={() => setSearchCategory('forum')}
                        className={"searchCategoryButton "+ (searchCategory ==='forum'?'searchCategoryFocused':'')}
                    >    <PiChatsFill size={20}/>
                        Forum
                    </button>
                </div>
            </div>
        </div>
    )
};

export default Search;