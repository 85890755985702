import React, { useState, useEffect } from 'react';

interface AuthModalProps {
    onClose?: () => void;
}

/** Use emailRegex.test(value) to detect wether a string is formated as an email address or not.
 * Returns true if string has email format, false otherwise.
 */
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const AuthModal: React.FC<AuthModalProps> = ({ onClose }) => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const closeModal = () => {
        setIsModalVisible(false);
        // onClose();
    };

    const handleLogin = () => {
        if (!emailRegex.test(email)) {
            setError('Invalid email address');
            return;
        }

        if (password.length < 6) {
            setError('Password must be at least 6 characters long');
            return;
        }

        // Perform authentication logic here (e.g., API call)
        // If authentication succeeds, close the modal
        closeModal();
    };

    useEffect(() => {
        // Disable scrolling when the modal is open
        document.body.style.overflow = isModalVisible ? 'hidden' : 'auto';
    
        // Ensure that scrolling is re-enabled when the modal is closed
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isModalVisible]);
    
    useEffect(() => {
        setIsModalVisible(true);
    }, []);

    return (
        <div
            className={`${isModalVisible ? 'fixed' : 'hidden'
                } inset-0 flex items-center h-[100vh] justify-center z-50`}
        >
            <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>

            <div className="z-50 w-96 p-4 bg-white rounded-lg shadow-lg">
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    onClick={closeModal}
                >
                    Close
                </button>
                <h2 className=" text-lg lg:text-xl font-semibold mb-4">Authentification</h2>
                <p>Connectez vous a votre compte pour pouvoir mettre un commentaire</p>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Email Address"
                        className="w-full p-2 border rounded"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="password"
                        placeholder="Password"
                        className="w-full p-2 border rounded"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={handleLogin}
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default AuthModal;
