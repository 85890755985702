import { AiOutlineHeart, AiOutlineMessage, AiOutlineShareAlt } from "react-icons/ai";
import { BlogDocumentType, MainContentPropsType } from ".";
import CustomButton from "../../components/CustomButton";
import { FaFilePdf } from "react-icons/fa";


const interactions = [
    {
        icon: <AiOutlineHeart size={16} />,
        label: "875",
    },
    {
        icon: <AiOutlineMessage size={16} />,
        label: "16",
    },
    {
        icon: <AiOutlineShareAlt size={16} />,
        label: "Share"
    }
]

const Interactions = () => {
    return (
        <div className="flex justify-end items-center gap-x-1.5">
            <div className="inline-block">
                {
                    interactions.map(item => (
                        <div className="inline-block mx-1.5">
                            <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-x-1.5 sm:gap-x-2 rounded-md font-medium bg-white text-gray-700 align-middle hover:bg-gray-100  text-sm ">
                                {item.icon}
                                {item.label}
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};


export const BlogDocumentItem:React.FC<BlogDocumentType> = ({title, fileUrl, description}) => {
    return (
        <div className="flex  hover:ring-1 hover:ring-primary/[.5] hover:shadow-md shadow-primary rounded-md px-1 lg:px-4 py-3">
            <div>
            <FaFilePdf size={48} color="#777" />
            </div>
            <div className="border-l-2 pl-2 w-full">
                <div className="flex justify-between">
                    <p className="text-lg lg:text-xl font-medium w-full">{title}</p>
                    <CustomButton
                        navigateTo={fileUrl}
                        title="Télécharger"
                        className="w-min h-min px-2 lg:px-3 py-1.5 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                </div>
                <p className="text-sm leading-tight mt-2">
                    {description}
                </p>
            </div>
        </div>
    )
};


const MainContent: React.FC<MainContentPropsType> = ({ bannerImgCaption, title, articleContent, beforeContent, ...props }) => {
    const ProgramTitle = () => {
        if (!props.programTitle) return null;

        return (
            <h2 className="text-2xl font-bold lg:text-3xl dark:text-white">
                {props.programTitle}
            </h2>
        );

    };


    const ProgramPoints = () => {
        if (!props.programPoints) return null;

        return (
            <p className="font-bold">
                <ul className="list-disc list-inside">
                    {
                        props.programPoints.map(point =>
                            <li>
                                {point}
                            </li>
                        )
                    }
                </ul>

            </p>
        )
    };

    const Tags = () => {
        if (!props.tags) return null;

        return (
            <div>
                {props.tags.map(tag => (

                    <a className="m-0.5 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200" href="#">
                        {tag}
                    </a>
                ))}
            </div>
        )
    };

    const UsefulLinks = () => {
        if (!props.usefulLinks) return null;

        return (
            <div >
                <p className="text-xl font-bold px-8 mb-4">Liens Utiles</p>
                <div className="text-sm leading-7 px-8 text-justify">
                    {props.usefulLinks.map(link => 
                        <span className="border-2 border-primary text-primary hover:bg-primary hover:text-white px-3 pt-0.5 pb-[0.15rem] cursor-pointer bg-opacity-80 hover:bg-opacity-100 transition rounded mx-3">
                            {link.title}
                        </span>
                        )}
                </div>
            </div>
        )
    };
    
    return (
        <div className="lg:col-span-2">
            <div className="py-8 lg:pr-8">
                <div className="space-y-5 lg:space-y-8">
                    <a className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline dark:text-blue-400" href="#">
                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        Retour à la page d'acceuil
                    </a>

                    <ProgramTitle />
                    <ProgramPoints />
                    {/* <div className="flex items-center gap-x-5">
                                <a className="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-800 dark:text-gray-200" href="#">
                                    Company News
                                </a>
                                <p className="text-xs sm:text-sm text-gray-800 dark:text-gray-200">January 18, 2023</p>
                            </div> */}

                    {/* <p className="text-lg text-gray-800 dark:text-gray-200">At preline, our mission has always been focused on bringing openness and transparency to the design process. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow.</p>

                            <p className="text-lg text-gray-800 dark:text-gray-200">We're proud to be a part of creating a more open culture and to continue building a product that supports this vision.</p>

                            <div className="text-center">
                                <div className="grid lg:grid-cols-2 gap-3">
                                    <div className="grid grid-cols-2 lg:grid-cols-1 gap-3">
                                        <figure className="relative w-full h-60">
                                            <img
                                                className="w-full h-full absolute top-0 left-0 object-cover rounded-xl"
                                                src="https://images.unsplash.com/photo-1670272505340-d906d8d77d03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                                                alt="Image Description"
                                            />
                                        </figure>
                                        <figure className="relative w-full h-60">
                                            <img
                                                className="w-full h-full absolute top-0 left-0 object-cover rounded-xl"
                                                src="https://images.unsplash.com/photo-1671726203638-83742a2721a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                                                alt="Image Description"
                                            />
                                        </figure>
                                    </div>
                                    <figure className="relative w-full h-72 sm:h-96 lg:h-full">
                                        <img
                                            className="w-full h-full absolute top-0 left-0 object-cover rounded-xl"
                                            src="https://images.unsplash.com/photo-1671726203394-491c8b574a0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"
                                            alt="Image Description"
                                        />
                                    </figure>
                                </div>

                                <span className="mt-3 block text-sm text-center text-gray-500">
                                    Working process
                                </span>
                            </div> */}

                        {beforeContent?
                            <div
                                dangerouslySetInnerHTML={{ __html: beforeContent }}
                            />
                            :null
                        }
                    {
                        (props.bannerImg||props.bannerImgUrl) ?
                        <figure>
                            <img
                                className="w-full object-cover rounded-xl"
                                src={props.bannerImg||props.bannerImgUrl}
                                alt="Image Description"
                            />
                            <figcaption className="mt-3 text-sm text-center text-gray-500">
                                {bannerImgCaption}
                            </figcaption>
                        </figure>
                        :null
                    }

                    <div className="space-y-3">
                        <h3 className="text-xl font-semibold dark:text-white">
                            {title}
                        </h3>

                        <div
                            dangerouslySetInnerHTML={{ __html: articleContent }}
                        />
                    </div>

                    {/* <div className="grid lg:flex lg:justify-between lg:items-center gap-y-5 lg:gap-y-0">
                        <Tags />
                        <Interactions />
                    </div> */}
                </div>
            </div>
            {/* <Chat 
                previewMode
            /> */}
            {/* <UsefulLinks/> */}
            {props.document? <BlogDocumentItem {...props.document}/> : null}
        </div>

    )
};

export default MainContent;