import { Link, To } from "react-router-dom";

type CustomButtonPropsType = {
    icon?:React.ReactNode;
    title:string;
    className?:string;
    type?:"button" | "submit" | "reset" | undefined;
    variant?:'btnPrimary'|'btnDanger'|'btnWarning';
    onClick?:() => void
    navigateTo?:To
    state?:any
    disabled?:boolean
};


const CustomButton:React.FC<React.PropsWithChildren<CustomButtonPropsType>> =
({ className, title, type, icon, onClick, variant='btnPrimary', navigateTo, state, ...props}) => {
    if(navigateTo) {
        return (
            <Link
                to={navigateTo}
                state={state}
                aria-disabled={props.disabled}
                type={type}
                onClick={onClick}
                className={"customButton w-max "+className+` ${variant}`}
            >
                    {icon}
                    {title}
            </Link>
        )
    } else {
        return (
            <button
                type={type}
                onClick={onClick}
                className={"customButton "+className+` ${variant}`}
                disabled={props.disabled}
            >
                {icon}
                {title}
            </button>
        )
    }
};

export default CustomButton;