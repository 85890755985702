import { useEffect, useState } from 'react';
import { ForumElementType, SADElementType } from "../../global/types";
import CustomButton from "../../components/CustomButton";
import { BsChevronLeft, BsGear, BsTrash } from "react-icons/bs";
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../App';
import { Link, useSearchParams } from 'react-router-dom';
import { forumsIndex } from '../../utils';


export const ForunItem:React.FC<ForumElementType &{showSettings?:boolean}> = ({description, index, title, id, showSettings}) => {
    return (
        <Link to={'forum?id='+id}>
            <div className="flex  hover:bg-gray-50 rounded-md px-1 lg:px-3 py-2">
                <div className="border-l-2 pl-2 w-full">
                    <div className="flex justify-between">
                    <p className="text-base lg:text-lg font-medium w-full">{title}</p>
                    {
                        showSettings?
                    <div className="grid grid-cols-2 gap-x-2">
                        <CustomButton
                            title=""
                            navigateTo={'modifier?id='+id}
                            state={{content:{description, index, title, id}}}
                            icon={<BsGear size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                        <CustomButton
                            title=""
                            icon={<BsTrash size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                    </div>
                    :
                    // <CustomButton
                    //     navigateTo={'forum?id='+id}
                    //     title="Voir"
                    //     className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    // />
                    null
                    }
                    </div>
                    <p className="text-sm leading-tight mt-2">
                        {description}
                    </p>
                </div>
            </div>
        </Link>
    )
};


const Forums:React.FC<{showSettings?:boolean}> = ({showSettings}) => {
    const [forums, setForums] = useState<ForumElementType[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const quicksearch = searchParams.get('quicksearch');
    
    const getAndSetForums = async () => {
        let q = query(collection(db, 'forums'), orderBy('index', 'desc'));
        const forumsSnapshot = await getDocs(q);
        const docsData = forumsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as ForumElementType);
        setForums(docsData);
    };

    const getAndSetSearchForums = async (title:string) => {
        if(!title.trim()) return;
        if(loading) return;
        setLoading(true);
        let results:ForumElementType[]= [];

        try {
            const { hits } = await forumsIndex.search(title);
        
            results = hits.map(item => {
                const {
                    description,
                    createdAt,
                    index,
                    title,
                    objectID:id,
                } = item as unknown as ForumElementType & {objectID:string};
                return {description, createdAt, id:Number(id), title, index};
            });

            setForums(results);
        } catch (error) {
            console.log('Error while searching algolia: ', error)
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if(quicksearch) {
            getAndSetSearchForums(quicksearch)
        } else {
            getAndSetForums();
        };
    }, []);
    return (
        <div
            className="max-w-6xl px-2 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl"
        >
            {/* <MainContent 
                articleContent=""
                {...props}
            /> */}
            <div className="">
                <div className="py-8 lg:pr-8">
                    <div className="space-y-5 lg:space-y-8">
                        <a className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline dark:text-blue-400" href="#">
                            <BsChevronLeft size={12} />
                            Retour à la page d'acceuil
                        </a>
                        <div className="mb-8 ml-2">
                            <div>
                                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Forums de Discussions</h1>
                                {showSettings?
                                <CustomButton
                                    title="Créer un nouveau forum"
                                    className="py-2.5"
                                    navigateTo="nouveau"
                                />:null}
                            </div>
                            <p className='text-xs font-medium text-gray-500'>
                                (Les commentaires et posts publiés dans ces forums de discussions relèvent de
                                la seule responsabilité de l'auteur et ne peuvent en aucun cas être considérés comme
                                réflétant l'avis de FLAG)
                            </p>
                        </div>
                        {(quicksearch) ?
                                <p className="font-medium text-xl lg:text-2xl px-2 mb-8 underline underline-offset-4">
                                    Résultats de la recherche: "{quicksearch}"
                                </p>
                            :
                        null}
                        <div className="grid  gap-y-6">
                            {forums.map(item => (
                                // <a href={item.fileUrl} className="group transition-all">
                                //     <div className="group-hover:text-secondary group-hover:font-medium border-b p-1 group-hover:border-b-2 group-hover:border-secondary">
                                //         {item.title}
                                //     </div>
                                // </a>
                                <ForunItem
                                    createdAt={item.createdAt}
                                    key={item.id}
                                    title={item.title}
                                    description={item.description}
                                    id={item.id}
                                    showSettings={showSettings}
                                />
                            ))}
                            {
                                !loading && quicksearch && forums.length ==0?
                                <div className='bg-gray-100 rounded-lg px-6 py-4 text-xl text-gray-700'>
                                    Aucun résultat pour la recherche
                                </div>
                                :null
                            }
                        </div>
                        {/* <Chat 
                            previewMode
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Forums;