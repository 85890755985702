import React, { useState, useRef, useEffect } from 'react';
import { RiReplyFill } from 'react-icons/ri';
import CustomButton from '../../components/CustomButton';
import CustomInput, { CustomTextArea } from '../../components/CustomInput';
import { addDoc, collection, doc, getDocs, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { db } from '../../App';
import { useSearchParams } from 'react-router-dom';
import { getCollectionHighestIndex } from '../Admin/Publish';
import AuthModal from '../../components/AuthModals';
import { ImSpinner8 } from 'react-icons/im';

interface Comment {
    id: number;
    index: number;
    text: string;
    username: string;
    email: string;
    timestamp: string;
    quotedId?: number;
    isHighlighted?: boolean;
}

const ForumSection: React.FC = () => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [userInfos, setUserInfos] = useState({ name: '', email: '', storeLocally: true, });
    const [quotedCommentId, setQuotedCommentId] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const forumRef = useRef<HTMLDivElement | null>(null);
    const forumId = searchParams.get('id');
    const forumCommentsRef = query(
        collection(db, 'forums/' + forumId + '/comments'),
        orderBy('index', 'desc')
    );


    const handleCommentSubmit = async () => {
        if (loading) return;
        setLoading(true);

        if (newComment.trim() === '') return;

        // Check if name and email are provided
        if (!userInfos.name || !userInfos.email) {
            alert("S'il vous plait, remplissez votre nom et votre addresse email.");
            return;
        }

        // Store email and name in localStorage if 'storeLocally' is checked
        if (userInfos.storeLocally) {
            localStorage.setItem('userEmail', userInfos.email);
            localStorage.setItem('userName', userInfos.name);
        }

        const commentsDBRef = collection(db, 'forums/' + forumId + '/comments');

        const highestIndex = await getCollectionHighestIndex(commentsDBRef);

        const newCommentObj: Comment = {
            id: typeof highestIndex == 'number' ? highestIndex + 1 : 1,
            text: newComment,
            index: typeof highestIndex == 'number' ? highestIndex + 1 : 1,
            username: userInfos.name, // Use the provided name
            timestamp: new Date().toLocaleString(), // Replace with actual timestamp
            email: userInfos.name
        };

        const newCommentDBRef = doc(
            db,
            'forums/' + forumId + '/comments/' + newCommentObj.index
        );

        if (quotedCommentId) {
            newCommentObj.quotedId = quotedCommentId;
            setQuotedCommentId(null);
        }
        const { id, ...dbCommentObj } = newCommentObj;

        await setDoc(newCommentDBRef, dbCommentObj);

        setComments([...comments, newCommentObj]);
        setNewComment('');
        setLoading(false);
    };

    const scrollToComment = (commentId: number) => {
        if (forumRef.current) {
            const commentElement = forumRef.current.querySelector(
                `#comment-${commentId}`
            );

            if (commentElement) {
                commentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });

                // Highlight the quoted comment temporarily
                const updatedComments = comments.map((comment) =>
                    comment.id === commentId
                        ? { ...comment, isHighlighted: true }
                        : { ...comment, isHighlighted: false }
                );

                setComments(updatedComments);

                setTimeout(() => {
                    // Remove the highlight after 2 seconds (adjust as needed)
                    const resetComments = updatedComments.map((comment) => ({
                        ...comment,
                        isHighlighted: false,
                    }));
                    setComments(resetComments);
                }, 2000); // 2 seconds
            }
        }
    };


    const getAndSetForumComments = async () => {
        const snapshot = await getDocs(forumCommentsRef);
        const dbComments = snapshot.docs.map(
            (comment) => ({ ...comment.data(), id: comment.id } as unknown as Comment)
        );
        setComments(dbComments);
        console.log('DB comments: ', dbComments);
    };

    useEffect(() => {
        getAndSetForumComments();

        // Check local storage for user data and initialize inputs
        const storedUserEmail = localStorage.getItem('userEmail');
        const storedUserName = localStorage.getItem('userName');

        if (storedUserEmail && storedUserName) {
            setUserInfos({
                ...userInfos,
                email: storedUserEmail,
                name: storedUserName,
            });
        }

        // Subscribe to real-time updates using onSnapshot
    const unsubscribe = onSnapshot(forumCommentsRef, (snapshot) => {
        const dbComments = snapshot.docs.map(
            (comment) => ({ ...comment.data(), id: comment.id } as unknown as Comment)
        );
        setComments(dbComments);
        
      });
      
      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }, []);

    return (
        <div className="p-2 lg:p-4 xl:px-8 lg:mx-4 xl:mx-6" ref={forumRef}>
            <div className="mb-4">
                <CustomTextArea
                    placeholder="Écrivez le commentaire..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />

                <div className="grid lg:grid-cols-3 mt-4 gap-x-4 gap-y-2">
                    <CustomInput
                        placeholder="Addresse email (confidentielle)"
                        value={userInfos.email}
                        onChange={(e) =>
                            setUserInfos((value) => ({ ...value, email: e.target.value }))
                        }
                    />
                    <CustomInput
                        placeholder="Nom"
                        value={userInfos.name}
                        onChange={(e) =>
                            setUserInfos((value) => ({ ...value, name: e.target.value }))
                        }
                    />
                    <div className="flex items-end py-1">
                        <label>
                            <input
                                type="checkbox"
                                checked={userInfos.storeLocally}
                                className="mx-1 h-4 w-4"
                                onChange={(e) =>
                                    setUserInfos((value) => ({
                                        ...value,
                                        storeLocally: !value.storeLocally,
                                    }))
                                }
                            />
                            Enregistrer pour la prochaine fois
                        </label>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                {quotedCommentId && (
                    <button
                        className="text-blue-500 hover:underline"
                        onClick={() => setQuotedCommentId(null)}
                    >
                        Annuler la citation
                    </button>
                )}
            </div>

            <CustomButton
                icon={loading ? <ImSpinner8 className="animate-spin" /> : null}
                title="Commenter"
                onClick={handleCommentSubmit}
            />

            <div className="mt-4">
                {comments.map((comment) => (
                    <div
                        key={comment.id}
                        id={`comment-${comment.id}`}
                        className={`border p-3 mb-4 rounded ${quotedCommentId === comment.id
                                ? 'bg-gray-100 border-l-4 border-secondary'
                                : comment.isHighlighted
                                    ? 'bg-yellow-100'
                                    : ''
                            }`}
                    >
                        <div className="flex mb-2">
                            <div className="rounded-full h-8 w-8 bg-gray-400 flex items-center justify-center text-white font-semibold mr-2">
                                {comment.username[0].toUpperCase()}
                            </div>
                            <div>
                                <p className="font-semibold text-sm lg:text-base">
                                    {comment.username}
                                </p>
                                <p className="text-gray-500 text-xs lg:text-sm">
                                    {comment.timestamp}
                                </p>
                            </div>
                        </div>
                        <div className="text-sm lg:text-base">
                            {comment.quotedId && (
                                <div className="bg-gray-200 p-2 rounded border border-gray-300 mb-2">
                                    <div className="font-semibold">Commentaire cité:</div>
                                    <div className='truncate'>
                                        {
                                            comments.find((c) => c.id === comment.quotedId)?.text
                                        }
                                    </div>
                                </div>
                            )}
                            <p>{comment.text}</p>
                        </div>
                        {comment.quotedId && (
                            <button
                                className="text-blue-500 hover:underline text-sm lg:text-base"
                                onClick={() => scrollToComment(comment.quotedId as number)}
                            >
                                Voir le commentaire cité
                            </button>
                        )}
                        <button
                            className="text-secondary hover:underline text-sm lg:text-base flex items-center mt-2"
                            onClick={() => setQuotedCommentId(comment.id)}
                        >
                            <RiReplyFill className="inline" /> Repondre
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ForumSection;
