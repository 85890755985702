import { useState } from "react";
import { BsCheckCircleFill, BsChevronLeft, BsChevronRight, BsPlusLg, BsThreeDots } from "react-icons/bs";

type User = {
    imgUrl?:string;
    name?:string;
    email:string;
    status?:string;
    createdAt:string;
};


let users:User[] = [
    {
        name:"Stream Lab",
        email:"amanda@site.com",
        createdAt:"28 Dec, 12:12",
        status:"Successful",
    },
    {
        email:"christina@site.com",
        imgUrl:"https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
        createdAt:"20 Dec, 09:27",
        status:"Warning"
    },
    {
        name:"David Harrison",
        email:"brian@site.com",
        createdAt:"18 Dec, 15:20",
        status:"Warning"
    },
    {
        email:"jackob@site.com",
        createdAt:"18 Dec, 15:20",
        status:"Successful"
    },
    {
        name:"Anne Richard",
        email:"amanda@site.com",
        imgUrl:"https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
        createdAt:"15 Dec, 14:41",
    },
    {
        email:"baba@site.com",
        imgUrl:"https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
        createdAt:"11 Dec, 18:51",
        status:"Danger"
    },
    {
        email:"amanda@site.com",
        createdAt:"20 Dec, 09:27"
    },
    {
        name:"Brian Halligan",
        email:"mickeal@site.com",
        createdAt:"18 Dec, 15:20",
        status:"Danger"
    }
];


users= users.concat(users).concat(users);
const TableRow:React.FC<User> = ({email, createdAt, imgUrl, name, status}) => {
    return (
        <tr>
            {/* <td className="h-px w-px whitespace-nowrap">
                <div className="pl-6 py-3">
                <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                    <input type="checkbox" className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-at-with-checkboxes-1"/>
                    <span className="sr-only">Checkbox</span>
                </label>
                </div>
            </td> */}

            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <span className="text-sm text-gray-600 dark:text-gray-400">{name}</span>
                </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <div className="flex items-center gap-x-2">
                    {
                        imgUrl?
                        <img className="inline-block h-6 w-6 rounded-full" src={imgUrl} alt="Image Description"/>
                        :
                        <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-300 dark:bg-gray-700">
                            <span className="text-xs font-medium text-gray-800 leading-none dark:text-gray-200">{name? name[0].toUpperCase():email[0].toUpperCase()}</span>
                        </span>
                    }
                    <div className="grow">
                    <span className="text-sm text-gray-600 dark:text-gray-400">{email}</span>
                    </div>
                </div>
                </div>
            </td>
            {/* <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <button type="button" className="inline-flex flex-shrink-0 justify-center items-center gap-x-2.5 py-2 px-2.5 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800">
                    Copy Key
                    <BsClipboardPlus className="h-3.5 w-3.5" />
                </button>
                </div>
            </td> */}
            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                    <BsCheckCircleFill className="h-2.5 w-2.5" />
                    {status}
                </span>
                </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <span className="text-sm text-gray-600 dark:text-gray-400">{createdAt}</span>
                </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-3">
                <span className="text-sm text-gray-600 dark:text-gray-400">{createdAt}</span>
                </div>
            </td>
            
            <td className="h-px w-px whitespace-nowrap">
                <div className="px-6 py-1.5">
                <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
                    <button id="hs-table-dropdown-1" disabled type="button" className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                    <BsThreeDots className="w-4 h-4" />
                    </button>
                    <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-table-dropdown-1">
                    <div className="py-2 first:pt-0 last:pb-0">
                        <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
                        Rename
                        </a>
                        <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
                        Regenrate Key
                        </a>
                        <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
                        Disable
                        </a>
                    </div>
                    <div className="py-2 first:pt-0 last:pb-0">
                        <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700" href="#">
                        Delete
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </td>
        </tr>
    )
};

const TableHead = () => {
    return (
        <thead>
              <tr>
                {/* <th scope="col" className="pl-6 py-3 text-left">
                  <label htmlFor="hs-at-with-checkboxes-main" className="flex">
                    <input type="checkbox" className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-at-with-checkboxes-main"/>
                    <span className="sr-only">Checkbox</span>
                  </label>
                </th> */}

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Nom
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Addresse Email
                    </span>
                  </div>
                </th>

                {/* <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      API Key
                    </span>
                  </div>
                </th> */}

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Status
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Created
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Dernière interaction
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-right"></th>
              </tr>
            </thead>

    )
}


const Users = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const onNextClick = () => {
        if((pageNumber*10) < users.length ){
            setPageNumber(pageNumber => pageNumber+1);
        };
    };

    const onPrevClick = () => {
        if(pageNumber>1) setPageNumber(pageNumber => pageNumber-1);
    }
    return (
<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="flex flex-col">
    <div className="-m-1.5 overflow-x-auto">
      <div className="p-1.5 min-w-full inline-block align-middle">
        <div className="bg-white border border-gray-200 pt-4 lg:pt-8 xl:pt-12 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
          {/* <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                API Keys
              </h2>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Keys you have generated to connect with third-party clients or access the <a className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="#">Preline API.</a>
              </p>
            </div>

            <div>
              <div className="inline-flex gap-x-2">
                <a className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" href="#">
                  View all
                </a>

                <a className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800" href="#">
                  <BsPlusLg className="h-4 w-4" size={16} />
                  Create
                </a>
              </div>
            </div>
          </div> */}
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <TableHead/>
            <tbody>
              {
                users.slice((pageNumber-1)*10, pageNumber*10).map(user => <TableRow {...user} />)
              }
            </tbody>
          </table>
          <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                <span className="font-semibold text-gray-800 dark:text-gray-200">{users.length} </span> resultats
                <span className="mx-4">

                Page<span className="font-semibold text-gray-800 dark:text-gray-200"> {pageNumber} </span>
                sur <span className="font-semibold text-gray-800 dark:text-gray-200"> {Math.ceil(users.length/10)} </span>
                </span>
              </p>
            </div>

            <div>
              <div className="inline-flex gap-x-2">
                <button onClick={onPrevClick} disabled={pageNumber<2} type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <BsChevronLeft className="h-3 w-3" />
                  Prev
                </button>

                <button onClick={onNextClick} type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                  Next
                  <BsChevronRight className="h-3 w-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    )
};

export default Users;
