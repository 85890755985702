import { sideArticles } from "../../assets/examples";
import Blog from "../Blog"


const content = `
<div>
    Etant orienté sur l’amélioration de la gouvernance dans la gestion des ressources naturelles, les nos
    valeurs sont ancrées dans les principes de bonne gouvernance et expriment notre volonté de gérer notre
    organisation et ses interactions avec les différents interlocuteurs dans un esprit de transparence, de
    responsabilité, de redevabilité, de légalité, d’équité, de participation et de prise en compte du genre.
</div>
<br/><br/>
<div>
    <ul class="list-disc list-inside">
        <li>
            <b>Transparence</b> <br>
            FLAG met l’accent sur une communication effective en rapport avec ses actions, politiques,
            convictions et méthodes de travail. Il s’agit de porter à la connaissance de ses partenaires et
            bénéficiaires, les informations relatives à ses orientations stratégiques et techniques de façon
            à créer un cadre de partage sincère où l’information pourra circuler et facilement accessible à
            tout acteur intéressée ou concernée. FLAG est une organisation transparente, mais examinera le
            caractère confidentiel d’un certain type d’information au cas par cas avant de les porter à
            l’attention de ses personnels et/ou membres ou plus généralement du grand public.
        </li>
        <br/>
        <li>
            <b>Responsabilité et redevabilité</b> <br>
            FLAG remplira ses engagements avec professionnalisme et dans le respect des engagements pris.
            De ce fait, il est exigé des membres et du personnel employé un niveau élevé de professionnalisme
            et d’honnêteté dans l’accomplissement de leurs tâches quotidiennes. La responsabilité au sein de
            FLAG se traduira aussi par l’adoption et l’application de standards de comportements éthiques et
            de lutte contre la corruption au sein de l’organisation. FLAG s’engage à être rendre compte de
            ses actions et de leur impact à ses membres, son personnel aux bénéficiaires directs et à
            ses partenaires.
        </li>
        <br/>
        <li>
            <b>Légalité</b> <br>
            Le respect des lois et règlementations nationales et/ou internationales et être une organisation
            citoyenne sont nos crédos. FLAG défend les droits individuels, la liberté d’opinion et d’expression.
            Elle prône et respecte le droit du travail tel que défini dans le pays où elle intervient, reste
            impartiale et veille au respect de ses politiques et règles intérieures.
        </li>
        <br/>
        <li>
            <b>Équité, Égalité et Participation</b> <br>
            FLAG offre les mêmes opportunités sur le plan du développement professionnel à tous ses membres
            sans distinction. Le travail au sein de FLAG s’effectue dans un esprit d’ouverture d’esprit qui
            laisse libre champs à l’expression des potentialités intellectuelles et morales de ses membres.
            Le débat contradictoire, l’esprit d’initiative, la prise en compte et le respect des points de
            vue de ses membres tout comme l’implication de tous dans l’application des décisions prises de
            façons consensuelles sont des principes fondamentaux dans le fonctionnement de FLAG.
            <br>
            FLAG prend en compte l’équité dans le développement et la mise en œuvre de ses directives et
            politiques internes, la mise en œuvre de ses actions et activités de même que dans la recherche
            et la conclusion de partenariats
        </li>
    </ul>
</div>
`


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];

const WhoAreWe = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={content}
            programTitle="VALEURS"
            sideArticles={sideArticles}
        />
    )
};

export default WhoAreWe;