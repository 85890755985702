import { useEffect, useState } from "react"
import Blog, { BlogDocumentType } from "../Blog"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../../App"


const OTP_OIContent = `
<p class="font-medium my-2"> Projet : « Promotion de la transparence du secteur forestier au Cameroun par la vulgarisation de l’Open Timber Portal et la mise en œuvre de l’observation indépendante » </p>
Débuté en octobre 2021, ce projet est une action menée avec l’appui financier de l’Union Européenne dans le cadre du 11e FED et s’étend jusqu’en 2027. Son cahier de charge pour finalité de permettre à FLAG de travailler en étroite collaboration avec les services en charge du Contrôle au sein du MINFOF dans le suivi de la légalité des activités forestières avec comme point d’ancrage les parcs de rupture. FLAG s’assure ainsi de la vérification de la légalité dans les parcs de rupture avec possibilité d’aller en amont (site d’exploitation) ou en aval (voie d’exportation) selon la nature du problème qui est identifié dans un parc.  
<p class="font-medium my-1 underline">Objectif </p>
OTP-OI Cameroun entend entre autres, augmenter la quantité, la qualité et l’accessibilité aux informations émanant des observateurs indépendants (OI) et les rendre plus facilement utilisables par les acteurs concernés, pro avec pour pont d’encrage la promotion de     la transparence dans les opérations forestières et la légalité forestière sur la base de l’APV FLEGT au Cameroun.
<p class="font-medium my-1 underline">Les activités cibles</p> 
Membre du consortium constitué de FODER et du CED, sous la coordination de World Resources Institute (WRI), les activités de FLAG portent essentiellement sur la mise en œuvre de l’OI mandatée avec comme point de départ les Parcs de Rupture. Il est également question promouvoir dans le cadre de ce projet, l’Open Timber Portail (OTP) au Cameroun, plateforme web indépendante qui promeut le respect de la légalité dans la production et le commerce du bois sur la base du cadre juridique de l’APV. 
`

const NORADContent=`
<p class="font-medium my-2">Projet : « Technologies innovantes pour la lutte contre l’exploitation illégale du bois : mise à l’échelle des plateformes d’identification et de transparence du bois, composante du bassin du Congo ».</p>
Le but global visé par ce projet mis en œuvre depuis octobre 2021, est de mettre à la disposition des décideurs, des informations crédibles sur l’exploitation forestière illégale afin d’encourager l’adoption de mesures permettant de réduire ce fléau. Pour ce faire, Field Legality Advisory Group (FLAG), s’est associé à World Resources Institute (WRI), l’Observatoire de la Gouvernance Forestière (OGF) en République Démocratique du Congo et Brainforest au Gabon. Financé par l’Agence Norvégienne pour la Coopération et le Développement (NORAD), il est question entre autres, d’augmenter la contribution du suivi indépendant dans la lutte contre l’exploitation forestière illégale dans le bassin du Congo à l’aide du portail Open Timber. 
<p class="font-medium my-1 underline">Objectifs spécifiques</p>
Au niveau du Cameroun, l’objectif est d’accroître la transparence dans le secteur forestier en renforçant la visibilité et la crédibilité du travail des observateurs indépendants des forêts du bassin du Congo via le portail OTP (Open Timber Portal) mais aussi de former des spécialistes praticiens de l’observation indépendante des forêts au Centre d’Excellence et d’Application pour le Suivi de la Gestion des Ressources Naturelles et l’Environnement (CEAS-GRNE). 
<p class="font-medium my-1 underline">Quelques activités du projet</p>
<ul class="list-disc list-inside">
  <li> Mettre en place un système d’appui adapté aux besoins d’OGF, Brainforest et des autres organisations de la société civile (OSC) pour augmenter leur appropriation des meilleure pratiques d’OI;</li> 
  <li> Former des spécialistes praticiens de l’observation indépendante des forêts au Centre d’Excellence et d’Application pour le Suivi de la Gestion des Ressources Naturelles et l’Environnement (CEAS-GRNE) ;</li>
</ul>
`

// const NICFIContent = `
// <p>
//   Augmenter la contribution du suivi indépendant dans la lutte contre l’exploitation forestière illégale
//   dans le Bassin du Congo c’est le leitmotiv du projet qui va durer 5 ans a pour objectifs spécifiques,
//   accroître la quantité et la qualité des observations publiées par les observateurs indépendants des
//   forêts en RDC, au Gabon et en République du Congo en améliorant l’efficacité de ces acteurs et leur
//   coordination à l’échelle régionale. Pour y arriver FLAG a mis en place un système d’appui adapté aux
//   besoins des personnels de OGF, Brainforest, CAGDF et autres OSC pour augmenter l'appropriation des
//   meilleures pratiques d'OI.
// </p>
// <p>
//   Dans la mise en place de ce projet, l’opérationnalisation du Centre d’Excellence et d’application pour
//   le Suivi et la Gestion des Ressources Naturelles et l’Environnement (CEAS-GRNE) a été un point essentiel
//   effectivement conclu à la première année du projet. Il a ainsi permis d'établir des partenariats avec
//   les institutions clés, d’acquérir du matériel adapté, de définir des profils de formateurs et de former
//   des experts de l’observation indépendante. Un travail fait de manière participative en impliquant à la
//   fois les partenaires du projet et les autres membres de la PA-OI, afin d’assurer l’appropriation du
//   Centre.
// </p>
// <p>
//   La sélection des apprenants quant à elle, donne la priorité aux jeunes staffs issus de OGF, de
//   Brainforest et des autres OSC sélectionnées, membres de la PA-OI qui mènent régulièrement des actions
//   d’observation indépendante dans la sous-région du Bassin du Congo. Cependant, les représentants de
//   l’administration et d’autres acteurs du secteur qui souhaitant améliorer leur capacité de suivi de la
//   déforestation illégale sont également concernés.
// </p>
// `


const RALFContent = `
<p class="font-medium my-2">Projet : « Suivi indépendant de l’application des lois forestières au Cameroun : impliquer les communautés locales et la justice pour dénoncer et sanctionner les pratiques illégales ».</p> 
Dans sa réalisation, le suivi indépendant fait encore face à diverses contraintes liées à l’implication de certains acteurs de la gestion des ressources forestières notamment les communautés locales et la justice. Pour contribuer à y remédier, Field Legality Advisory Group (FLAG) s’est associé à l’organisation non gouvernementale Conservation Justice (CJ) du Gabon dans le cadre de ce projet qui fixe les axes de la gestion forestière durable au Cameroun. 
<p class="font-medium my-1">Objectif général</p> 
De manière spécifique, l’objectif est de de renforcer la lutte contre l’exploitation forestière illégale selon le modèle ALEFI de CJ, et la mise en œuvre de l’action menée se fait dans 4 régions respectivement : le Centre, l’Est, le Littoral, le Sud et tout le long des circuits de commercialisation des bois et produits bois au Cameroun. En effet, la forte expérience de CJ, en la matière, leur financement couplé à l’expertise de FLAG dans le Bassin du Congo et particulièrement au Cameroun sont mis à contribution pour la réalisation dudit projet. Aussi, des sessions de recyclages animées sont organisées en parallèle dans l’optique d’accroitre l’efficacité des services régionaux du MINFOF, dans la gestion des cas d’activités forestières illégales constatées. 
<p class="font-medium my-1">Cibles</p>
<ul>
<li>Les services juridiques du MINFOF et les communautés locales</li>
<li>Les services de la justice et du MINFOF pour une collaboration optimale dans l’instruction des affaires contentieuses de la faune et de la forêt.</li>  
</ul> 
`

const IMWILDContent = `
<p class="font-medium my-2">Projet : « Renforcement de la surveillance communautaire du commerce illégal de la faune et du braconnage dans les aires protégées du Cameroun : déploiement des plans locaux opérationnels de surveillance »</p>
Pour s’assurer de l’implication effective des communautés locales dans la lutte contre le braconnage et le commerce illégal des espèces sauvages, le projet mis en œuvre par FLAG, introduit le suivi indépendant de la gestion des aires protégées par les communautés. Cette approche qui vise la participation des communautés, renforce les capacités de ces dernières en matière de surveillance faunique.
<p class="font-medium my-1"> Objectif global</p>
Déployé en 2020, dans la boucle Nord et Sud de la réserve de la biosphère du Dja (RBD), ledit projet vise l’intensification de ses activités par le déploiement des plans locaux opérationnels de surveillance de chaque communauté cible, soutenus par des incitations économiques et socioculturelles telles que des activités génératrices de revenus. Il s’emploie également à introduire et à mettre en œuvre une approche communautaire des surveillances du commerce illégal de la faune et du braconnage dans la zone écologique du Parc National du Mbam et Djerem, avec l’appui des partenaires tels que le MINFOF, les services de conservation de la RBD et du PNMB, ECODEV et les communautés concernées. 
<p class="font-medium my-1>Cibles</p>
Les principales cibles concernées par le présent projet, sont les communautés locales et autochtones vivant autours et dans les aires protégées
`

const USINLContent = `
<p class="font-medium my-2">Projet « Renforcer la collaboration transfrontalière pour lutter contre l’exploitation illégale dans le bassin du Congo »</p>
Le projet entend accroître le renforcement des capacités d'application de la loi au Cameroun et en République du Congo pour prévenir, détecter et enquêter sur le bois illégal grâce à des formations et des outils spécialisés. FLAG par cette initiative travaille davantage à renforcer les capacités des agents d'application de la loi au Cameroun et en République du Congo.
<p class="font-medium my-1">Objectif </p> 
Il s’agit spécifiquement dans ce projet financé par le Département d’Etat américain – Bureau des Affaires internationales des stupéfiants et de répressions des infractions (USINL) avec l’appui technique de World Resources Institutes (WRI), de fournir des informations pertinentes aux agents chargés de l'application de la loi dans un format et une langue utilisables ; de renforcer leur capacité à accéder à ces informations et à les utiliser pour mener à bien leur mandat d'application de la loi, de favoriser la collaboration inter-agences et transfrontalière entre les agents chargés de l'application de la loi dans le Bassin du Congo, en les aidant à intégrer les outils : OTP, Atlas forestiers nationaux et CITES fin d’assoir la collaboration inter-agences et transfrontalière dans leurs procédures internes. 
<p class="font-medium my-1">Cibles</p>
<ul class="list-disc">
  <li>Le personnel clé des services en charge de l’application de la loi dans le format et la langue appropriés, </li>
  <li>Les agents chargés de l’application de la loi dans le bassin du Congo pour une meilleure collaboration inter-agence et transfrontalière entre </li>
</ul>
`


const OurActions = ({content, title, actionId}:{content:string, title:string, actionId:string}) => {
    const [document, setDocument] = useState<BlogDocumentType|null>(null);

    const getDocumentData = async () => {
        const actionSnapshot = (await getDoc(doc(db, 'ouractions/'+actionId))).data();
        if(actionSnapshot) {
            setDocument(actionSnapshot.document as BlogDocumentType);
        }
    };

    useEffect(() => {
        getDocumentData();
    }, [])
    return (
        <Blog
            tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={content}
            programTitle={title}
            showDASContent={false}
            showDocuments={false}
            showSocials={false}
            document={document?{
                description:"Téléchargez le document présentatif de "+title,
                fileUrl:document?.fileUrl||"",
                title:document?.title||""
            }:undefined}
        />
    )
};

export default OurActions;

export const OTP_OICameroon = () => <OurActions title="OTP-OI Cameroun" content={OTP_OIContent} actionId="otp-oicameroun"/>   
export const NORAD = () => <OurActions title="NORAD" content={NORADContent} actionId="norad"/>;
export const RALFF  = () => <OurActions title="RALFF" content={RALFContent} actionId="ralff"/>;
export const IMWILD = () => <OurActions title="IMWILD" content={IMWILDContent} actionId="imwild"/>;
export const USINL = () => <OurActions title="USINL" content={USINLContent} actionId="usinl"/>;
// export const NICFI = () => <OurActions title="NICFI" content={NICFIContent} actionId="nicfi"/>;