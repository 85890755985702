import { useContext, useEffect, useState } from 'react';
import CustomButton from './CustomButton';
import CustomInput from './CustomInput';
import { createUserWithEmailAndPassword, sendSignInLinkToEmail, signInWithCredential, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../App';
import { ImSpinner8 } from 'react-icons/im';
import { AuthContext } from '../global/context';

type AlertModalProps = {
    showModal: boolean;
    hideModal: () => void;
    title:string;
};

export const Alert: React.FC<AlertModalProps> = ({ title, showModal, hideModal }) => {
    useEffect(() => {
        if (showModal) {
          // Add the class to disable scrolling
          document.body.classList.add('overflow-hidden');
        } else {
          // Remove the class to enable scrolling
          document.body.classList.remove('overflow-hidden');
        }
      }, [showModal]);
    return (
        <div style={{top:window.scrollY}} className={`fixed inset-0 h-[100vh] z-50 bg-[rgba(0,0,0,0.5)] transition ${showModal ? 'block transition' : 'hidden transition'}`} onClick={hideModal}>
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white w-full md:w-1/2 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <div className="p-4 lg:px-6">
                        <div className="text-xl lg:text-2xl font-semibold text-black mb-4">{title}</div>
                    </div>
                    <div className="flex justify-end px-4 gap-x-2 py-2">
                        <CustomButton
                            title='Okay'
                            onClick={hideModal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


type DeleteConfirmationProps = {
    showModal: boolean;
    hideModal: () => void;
    confirmModal?: () => void;
    message: string;
    title:string;
};

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ title, showModal, hideModal, confirmModal, message }) => {
    useEffect(() => {
        if (showModal) {
          // Add the class to disable scrolling
          document.body.classList.add('overflow-hidden');
        } else {
          // Remove the class to enable scrolling
          document.body.classList.remove('overflow-hidden');
        }
      }, [showModal]);
    return (
        <div style={{top:window.scrollY}} className={`fixed inset-0 h-[100vh] z-50 bg-[rgba(0,0,0,0.5)] transition ${showModal ? 'block transition' : 'hidden transition'}`} onClick={hideModal}>
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white w-full md:w-1/2 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <div className="p-4 lg:px-6">
                        <div className="text-xl font-semibold text-red-600 mb-4">{title}</div>
                        <div className="text-brown-300">{message}</div>
                    </div>
                    <div className="flex justify-end px-4 gap-x-2 py-2">
                        <CustomButton
                            title='Annuler'
                            onClick={hideModal}
                        />
                        <CustomButton
                            title='Confirmer'
                            onClick={confirmModal}
                            variant='btnDanger'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

type SignInModalProps = {
    showModal: boolean;
    hideModal: () => void;
};

export const SignIn: React.FC<SignInModalProps> = ({ showModal, hideModal }) => {
    const [formValues, setFormValues] = useState({email:'', password:''});
    const [loading, setLoading] = useState(false);
    const [signInError, setSignInError] = useState('');
    const setUser = useContext(AuthContext)?.setUser;

    const onSignIn = async () => {
        if(loading) return;
        setLoading(true);

        try {
            // await sendSignInLinkToEmail(auth, formValues.email, {url:window.location.host+'/auth', handleCodeInApp:true})
            // window.localStorage.setItem('emailForSignIn', formValues.email);

            try {
                await signInWithEmailAndPassword(auth, formValues.email, formValues.password);
                
            } catch (error) {
                await createUserWithEmailAndPassword(auth, formValues.email, formValues.password);
            };

            if(setUser) setUser({email:formValues.email})
            hideModal();
            setSignInError('');
        } catch (error) {
            console.log('Error while signing in: ', error);
            setSignInError('Une erreur est survenue.')
        }

        setLoading(false);
    };
    
    
    useEffect(() => {
        if (showModal) {
          // Add the class to disable scrolling
          document.body.classList.add('overflow-hidden');
        } else {
          // Remove the class to enable scrolling
          document.body.classList.remove('overflow-hidden');
        }
      }, [showModal]);
    return (
        <div style={{top:window.scrollY}} className={`fixed inset-0 h-[100vh] z-50 bg-[rgba(0,0,0,0.5)] transition ${showModal ? 'block transition' : 'hidden transition'}`} onClick={hideModal}>
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white px-6 max-w-md w-full md:w-1/2 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
                    <div className="p-4 lg:px-6">
                        <div className="text-xl lg:text-2xl font-semibold text-black mb-4">Connectez vous a votre compte</div>
                    </div>
                    <div>
                        <CustomInput
                            placeholder='Addresse email'
                            label='Addresse email'
                            value={formValues.email}
                            onChange={e => setFormValues(value=> ({...value, email:e.target.value}))}
                        />
                        <CustomInput
                            placeholder='Mot de passe'
                            label='Mot de passe'
                            type='password'
                            value={formValues.password}
                            onChange={e => setFormValues(value=> ({...value, password:e.target.value}))}
                        />
                    </div>
                    {signInError? <p className='text-xs text-red-700'>{signInError}</p>:null}
                    <div className="flex justify-end px-4 gap-x-2 py-2">
                        <CustomButton
                            title='Se Connecter'
                            className='px-12 text-sm'
                            onClick={onSignIn}
                            icon={loading? <ImSpinner8 className='animate-spin' size={20} />:null}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};