// Liens utiles, ajouter/retirer
// Mediatheque Photos
// Mediatheque Video
// Mediatheque Documents
// Newsletter emails list

import { Link } from "react-router-dom";


export type MenuItemType = {
    title: string;
    linkTo: string;
    description: string;
};

const menus = [
    {
        title: "Gestion des Contenus",
        linkTo: "gestion-des-contenus",
        description: "Ajouter/Supprimer des contenus dans les catégories clées du site"
    },
    // {
    //     title: "Liens Utiles",
    //     linkTo: "liens-utiles",
    //     description: "Ajoutez ou retirez des liens utiles visibles sur site web"
    // },
    // {
    //     title: "Utilisateurs",
    //     linkTo: "utilisateurs",
    //     description: "Consulter la liste des utilisateurs enregistrés sur la plateforme"
    // },
    {
        title: "Newsletter Emails List",
        linkTo: "emails-de-newsletter",
        description: "Consultez et gérez la liste d'adresses e-mail de la newsletter"
    },
];


export const AdminMenuItem: React.FC<MenuItemType> = ({ title, description,linkTo }) => {
    return (
        <Link
            className="group flex flex-col my-4 border bg-[#fcfcfc]  shadow-sm rounded-xl hover:shadow-gray-200 hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800"
            to={linkTo}
        >
            <div className="p-2 md:p-3 md:px-4">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="group-hover:text-primary font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                            {title}
                        </h3>
                        <p className="text-sm text-gray-500">
                            {description}
                        </p>
                    </div>
                    <div className="pl-3">
                        <svg className="w-3.5 h-3.5 text-gray-500 group-hover:text-primary group-hover:scale-125 transition" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const Admin = () => {
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Menu Administrateur</h1>
                <span className="text-xl text-gray-600">Modifiez les paramètres généraux du site web ici.</span>
            </div>
            {
                menus.map(item => (
                    <AdminMenuItem {...item} />
                ))
            }

        </div>
    )
};

export default Admin;