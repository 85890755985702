import algoliasearch from "algoliasearch/lite";

export const searchClient = algoliasearch('CUCXOTWQ7M', 'a6c2b3cf5c0da895c208d2bfba92435b');
export const sadIndex = searchClient.initIndex('flag-site_sad');
export const lexIndex = searchClient.initIndex('flag-site_lex');
export const forumsIndex = searchClient.initIndex('flag-site_forums');

export function getContactHtml(
    userName: string,
    userEmail: string,
    userPhone: string,
    userMessage: string
): string {
    return `
    <!DOCTYPE html>
    <html lang="fr">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Message depuis flag-cmr.org</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f8f8f8;
                margin: 0;
                padding: 0;
            }

            .container {
                background-color: #10672F;
                border-radius: 10px;
                padding: 20px;
                max-width: 400px;
                margin: 0 auto;
            }

            h1 {
                color: #fff;
            }

            p {
                color: #dedede;
            }

            .message {
                background-color: #fff;
                border-radius: 10px;
                padding: 20px;
                margin-top: 20px;
            }
            
            .message p {
                color:black;
            }

            #displayText {
                white-space: pre-wrap;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <h2>Message depuis flag-cmr.org</h2>
            <p>Chère équipe de FLAG,</p>
            <p>Vous avez reçu un message d'un utilisateur de flag-cmr.org :</p>

            <div class="message">
                <p><strong>Message de :</strong> ${userName}</p>
                <p><strong>Addresse email :</strong> ${userEmail}</p>
                <p><strong>Téléphone de l'utilisateur :</strong> ${userPhone}</p>
                <p><strong>Message de l'utilisateur :</strong></p>
                <div style="border: 1px solid #ccc; width:100%; margin-top:10px; margin-bottom:4px"/>
                <span id="displayText">${userMessage}</span>
            </div>
        </div>
    </body>
    </html>
    `;
}

