import SADElement from "./SADElement"

const html = `
<h3 class=" text-lg mb-8">
    Centre d’Excellence et d’Application pour le Suivi de la Gestion des Ressources
    Naturelles et de l’Environnement (CEAS-GRNE)
</h3>
<span class="text-indent-16">
    Très soucieux du transfert des compétences dans le domaine de la gestion du suivi
    des ressources naturelles en Afrique Tropicale Humide et Dense, FLAG a créé le
    Centre d’Excellence et d’Application pour le Suivi de la Gestion des Ressources
    Naturelles (CEAS-GRNE). 1ère école de ce type dans la sous- région et opérationnelle
    depuis 2022, ce centre de formation est capable de fournir des solutions adéquates
    aux problématiques qui interpellent les actions de suivi. Il met en symbiose l’innovation,
    la coordination et la complémentarité en matière de surveillance afin de réduire les
    mauvaises pratiques qui minent le secteur de la gestion des ressources naturelles.
    <br/><br/>

    05 itinéraires de formation constitueront à terme l’offre globale de formation du CEAS-GRNE.
    Il s’agit des Praticiens du suivi indépendant de la gestion des ressources forestières ;
    des spécialistes du suivi de la gestion de la faune et des aires protégées ; les experts du
    suivi de la gestion des ressources halieutiques ; les praticiens du suivi environnemental et
    les acteurs du suivi de l’extraction des mines solides.
    <br/> <br/>
    
    Le Centre d’Excellence permettra aux apprenants de réaliser des ambitions élevées de
    surveillance de l’application des lois et règlements relatives à la gestion des ressources
    naturelles (Forêts, Faune, Mines, Pêche, Environnement) dans les pays africains.
    <br/> <br/>
</span>

`

const ContinuousTraining = () => {
    return (
        <SADElement
            DAS="formation-continue"
            sadPoints={html}
            title="Formation Continue"
        />
    )
};

export default ContinuousTraining;