import { MdArrowForward, MdArrowForwardIos, MdOutlineEmail, MdOutlineLibraryBooks, MdQuestionMark } from "react-icons/md";
import CustomButton from "../../components/CustomButton";
import CustomInput, { CustomTextArea } from "../../components/CustomInput";
import Search from "../../components/Search";
import Localization from "../../components/Localization";
import { useState } from "react";
import { Alert } from "../../components/Modals";
import { db } from "../../App";
import { addDoc, collection } from "firebase/firestore";
import { emailRegex } from "../../components/AuthModals";
import { ImSpinner8 } from "react-icons/im";
import { getContactHtml } from "../../utils";
import { Link } from "react-router-dom";


const Contact = () => {
    const [formValues, setFormValues] = useState({name:'', surname:'', email:'', phone:'', message:''});
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const onContactMessageSubmit = async () => {
        if(loading) return;
        if(!formValues.name || !emailRegex.test(formValues.email) || !formValues.message) return;
        setLoading(true);
        
        const mailsCollectionRef = collection(db, 'mails');
        await addDoc(mailsCollectionRef, {
            to:'mailflag@flag-cm.org',
            message:{
                subject:`Message laissé par ${formValues.name} ${formValues.email} sur flag-cmr.org`,
                // text:,
                html:getContactHtml(formValues.name+' '+formValues.surname, formValues.email, formValues.phone, formValues.message) 
            }
        });

        setFormValues({email:'', message:'', name:'', phone:'', surname:''});
        setLoading(false);
        setShowAlert(true);
    }

    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-auto my-[10vh] bg-white rounded-xl">
            <div className="max-w-2xl lg:max-w-5xl mx-auto">
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
                        Écrivez nous
                    </h1>
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                        Ce serait un plaisir de discuter sur comment nous pouvons vous aider.
                    </p>
                </div>

                <div className="mt-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-16">
                    <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700">
                        <h2 className="mb-8 text-xl font-semibold text-gray-800 dark:text-gray-200">
                            Remplir le formulaire
                        </h2>

                        <div className="grid gap-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <CustomInput
                                    label="Nom"
                                    type="text"
                                    className="p-2.5"
                                    name="hs-firstname-contacts-1"
                                    id="hs-firstname-contacts-1"
                                    placeholder="Nom"
                                    value={formValues.name}
                                    onChange={e => setFormValues(values => ({...values, name:e.target.value}))}
                                />
                                <CustomInput
                                    label="Prénom"
                                    type="text"
                                    className="p-2.5"
                                    name="hs-lastname-contacts-1"
                                    id="hs-lastname-contacts-1"
                                    placeholder="Prénom"
                                    value={formValues.surname}
                                    onChange={e => setFormValues(values => ({...values, surname:e.target.value}))}
                                />
                            </div>
                            <CustomInput
                                label="Email"
                                type="email"
                                className="p-2.5"
                                name="hs-email-contacts-1"
                                id="hs-email-contacts-1"
                                placeholder="Email"
                                autoComplete="email"
                                value={formValues.email}
                                onChange={e => setFormValues(values => ({...values, email:e.target.value}))}
                            />
                            <CustomInput
                                label="Numéro de Téléphone"
                                type="text"
                                className="p-2.5"
                                name="hs-phone-number-1"
                                id="hs-phone-number-1"
                                placeholder="Numéro de Téléphone"
                                value={formValues.phone}
                                onChange={e => setFormValues(values => ({...values, phone:e.target.value}))}
                            />

                            <CustomTextArea
                                label="Détails"
                                type="text"
                                className="p-2.5"
                                name="hs-about-contacts-1"
                                id="hs-about-contacts-1"
                                placeholder="Écrivez votre message ici..."
                                rows={4}
                                value={formValues.message}
                                onChange={e => setFormValues(values => ({...values, message:e.target.value}))}
                            />
                        </div>

                        <CustomButton
                            className="mt-4 w-full text-base "
                            title="Envoyer"
                            icon={loading?<ImSpinner8 className="animate-spin" size={20} />:null}
                            onClick={onContactMessageSubmit}
                            disabled={loading}
                        />

                        <div className="mt-3 text-center">
                            <p className="text-sm text-gray-500">
                                Nous vous répondrons dès que possible
                            </p>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-200 dark:divide-gray-800">
                        <h2 className="mt-4 mb-8 text-xl font-semibold text-gray-800 dark:text-gray-200">
                            Regardez aussi...
                        </h2>

                        <div className="flex gap-x-7 py-6">
                            <MdOutlineLibraryBooks
                                className="flex-shrink-0 w-6 h-6 mt-1.5 text-gray-800 dark:text-gray-200"
                            />
                            <div>
                                <h3 className="font-semibold text-gray-800 dark:text-gray-200">Base Documentaire</h3>
                                <p className="mt-1 text-sm text-gray-500">Le site web de FLAG comporte une panoplie d'articles sur la transparence et la legalité dans l'utilisation des resources forestières</p>
                                <Link to={'/lex'} className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
                                    Consulter le FLAG LEX
                                    <MdArrowForward
                                        className="mt-1 h-4 w-4 transition ease-in-out group-hover:translate-x-1"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="flex gap-x-7 py-6">
                            <MdQuestionMark
                                className="flex-shrink-0 w-6 h-6 mt-1.5 text-gray-800 dark:text-gray-200"
                            />
                            <div>
                                <h3 className="font-semibold text-gray-800 dark:text-gray-200">À propos de nous</h3>
                                <p className="mt-1 text-sm text-gray-500">Découvrez qui est FLAG et comment nous oeuvrons dans le suivi indépendant des activités forestières</p>
                                <Link to={'/a-propos/qui-sommes-nous'} className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
                                    Qui Sommes Nous?
                                    <MdArrowForward
                                        className="mt-1 h-4 w-4 transition ease-in-out group-hover:translate-x-1"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className=" flex gap-x-7 py-6">
                            <MdOutlineEmail
                                className="flex-shrink-0 w-6 h-6 mt-1.5 text-gray-800 dark:text-gray-200"
                            />
                            <div>
                                <h3 className="font-semibold text-gray-800 dark:text-gray-200">Nous contacter par email</h3>
                                <p className="mt-1 text-sm text-gray-500">Si vous souhaiter nous contacter via email, svp utilisez</p>
                                <a className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" href="#">
                                    mailflag@flag-cm.org
                                </a>
                            </div>
                        </div>
                        <Search />
                    </div>
                </div>
            </div>
            <Alert
                hideModal={() => setShowAlert(false)}
                showModal={showAlert}
                title="Votre message a été envoyé"
            />
            <Localization />
        </div>
    )
};


export default Contact;