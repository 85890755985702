import { useEffect, useMemo, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import CustomButton from "../../components/CustomButton";
import CustomInput, { CustomDateRangePicker, CustomDropdown, CustomTextArea } from "../../components/CustomInput";
import { AlbumElementType, DateRange, DocumentElementType, ForumElementType, LEXElementType, LEXSubCategory1Type, MediaDocCategoryType, PhotoElementType, PressElementType, SADElementType, VideoElementType } from "../../global/types";
import CustomRichEditor from "../../components/CustomRichTextEditor";
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";
import { useLocation, useSearchParams } from "react-router-dom";
import { collection, doc, query, orderBy, limit, getDocsFromServer, CollectionReference, DocumentData, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../App";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ImSpinner8 } from 'react-icons/im';


export const getCollectionHighestIndex = async (collection:CollectionReference<DocumentData, DocumentData>) => {
    try {
        const q = query(collection, orderBy('index', 'desc'), limit(1));
        const querySnapshot = await getDocsFromServer(q);
        return querySnapshot.docs?.at(0)?.data().index as number|undefined;
    } catch (error) {
        throw error;
    }    
}

type formInputsTypes = {
    bannerImg:File|null;
    posterImg?:File|null;
    bannerVideo?:File|null;
    attachedFile:File|null;
    articleUrl?:string;
    title:string;
    subtitle?:string;
    description?:string;
    richTextContent?:string;
    dasCategory?:string;
    DAS?:'accompagnement-communautaire'|'surveillance-environnementale'|'formation-continue'|'qualite-et-disponibilite-de-linfo'|'supervision-du-secteur-artisanal'
    LEX?:'agriculture'|'forests'|'mines'|'fauna';
    period?:string;
    dateRange?:DateRange|null;
    lexCategory?:string;
    lexSubCategory1?:string;
    mediaDocSubCategory?:MediaDocCategoryType;
    ////////////////// file updating optionnal fields ///////////////////
    imageUrl?:string;
    fileUrl?:string;
    posterUrl?:string;
    videoUrl?:string;
    bannerImgUrl?:string;
    documentUrl?:string;
};


const formInitial:formInputsTypes = {
    richTextContent:"",
    subtitle:"",
    description:"",
    title:"",
    bannerImg:null,
    bannerVideo:null,
    attachedFile:null,
    posterImg:null,
    dasCategory:"",
    dateRange:null,
    lexCategory:"",
    lexSubCategory1:"",
    period:"",
    DAS:undefined,
    LEX:undefined,
    articleUrl:"",
    bannerImgUrl:"",
    documentUrl:"",
    fileUrl:"",
    imageUrl:"",
    posterUrl:"",
    videoUrl:""
} 

type PublishPropsType = {
    inputVisibles?:{
        [T in keyof formInputsTypes]?:boolean
    };
    title?:string;
    submitTitle?:string;
};

const Publish:React.FC<PublishPropsType> = ({inputVisibles, title, submitTitle}) => {
    // const inputVisibles = {
    //     bannerImg:true,
    //     title:true,
    //     subtitle:true,
    //     description:true,
    //     richTextContent:true,
    //     DAS:true,
    //     period:true,
    //     dateRange:true,
    //     lexCategory:true,
    //     attachedFile:true
    // };
    const location = useLocation();
    const stateContent = location.state?.content;

    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState<formInputsTypes>(formInitial);
    const [searchParams, setSearchParams] = useSearchParams();


    const videoLocalUrl = useMemo(() => {
        if(formValue.bannerVideo) {
            return URL.createObjectURL(formValue.bannerVideo)
        } else return undefined
    }, [formValue.bannerVideo]);

    
    const isSADContent = location.pathname.startsWith('/admin/gestion-des-contenus/das/');
    const isLEXContent = location.pathname.startsWith('/admin/gestion-des-contenus/lex/');
    const isPhotoContent = location.pathname.startsWith('/admin/gestion-des-contenus/mediatheque-photos/nouveau');
    const isAlbum = location.pathname.startsWith('/admin/gestion-des-contenus/mediatheque-photos/nouvel-album');
    const isVideoContent = location.pathname.startsWith('/admin/gestion-des-contenus/mediatheque-videos/');
    const isDocumentContent = location.pathname.startsWith('/admin/gestion-des-contenus/mediatheque-documents/');
    const isPressbookContent= location.pathname.startsWith('/admin/gestion-des-contenus/pressbook/');
    const isForumContent= location.pathname.startsWith('/admin/gestion-des-contenus/forums/');
    

    //  let url = "/admin/gestion-des-contenus/das/modifier?id=1";
    //  let match = url.match(regex);
    //  if (match) {
    //     console.log(match[1]); // Outputs: "gestion-des-contenus"
    //     console.log(match[2]); // Outputs: "das"
    //     console.log(match[3]); // Outputs: "1"
    //  }
    
    const albumId = searchParams.get('albumId');

    const isUpdate = location.pathname.includes('/modifier');
    let itemId:string|null = null;
    if(isUpdate) itemId = searchParams.get('id');
    
    const onFormSubmit = async () => {
        if(loading) return;
        if(isUpdate && !itemId) return;
        if(!formValue.title) return;
        if(isPhotoContent && !albumId) return;

        setLoading(true);
        
        const dbItemId = itemId as unknown as  number;
        try {
            if (isSADContent) {
                const sadCollectionRef = collection(db, 'sad');
                const highestIndex = await getCollectionHighestIndex(sadCollectionRef);
                
                const dbObject:SADElementType={
                    category:formValue.dasCategory||"",
                    DAS:formValue.DAS||'accompagnement-communautaire',
                    createdAt:new Date().toDateString(),
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    subtitle:formValue.subtitle||"",
                    title:formValue.title,
                    rawHtml:formValue.richTextContent,
                    period:formValue.period
                };
                
                let bannerImgUrl:string="";
    
                if(formValue.bannerImg) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.bannerImg.name.split('.').pop();
                    const filePath = `sad/bannerImgs/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.bannerImg, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    bannerImgUrl = await getDownloadURL(storageRef);
                    dbObject.bannerImgUrl = bannerImgUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'sad/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newSAD = doc(db, 'sad/'+dbObject.index);
                    await setDoc(newSAD, dbObject);
                }
            } else if(isLEXContent) {
                const lexCollectionRef = collection(db, 'lex');
                const highestIndex = await getCollectionHighestIndex(lexCollectionRef);
                
                const dbObject:LEXElementType={
                    category:formValue.lexCategory||"",
                    LEX:formValue.LEX||'agriculture',
                    createdAt:new Date().toDateString(),
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    title:formValue.title,
                };
                
                if(formValue.lexCategory ==='legislation') {
                    dbObject.subCategory1=(formValue.lexSubCategory1 as LEXSubCategory1Type) ||"circular"
                };
                
                let documentUrl:string="";
    
                if(formValue.attachedFile) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.attachedFile.name.split('.').pop()
                    const filePath = `lex/documents/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.attachedFile, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    documentUrl = await getDownloadURL(storageRef);
                    dbObject.documentUrl = documentUrl;
                }

                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'lex/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'lex/'+dbObject.index);
                    await setDoc(newLEX, dbObject);
                }
            } else if (isAlbum) {
                const albumCollectionRef = collection(db, 'mediatheque');
                const highestIndex = await getCollectionHighestIndex(albumCollectionRef);
                
                const dbObject:AlbumElementType={
                    category:'photos',
                    subtitle:formValue.subtitle||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    createdAt:new Date().toDateString(),
                    subCategory:formValue.DAS||'accompagnement-communautaire'
                };
                
                let imageUrl:string="";
    
                if(formValue.bannerImg) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.bannerImg.name.split('.').pop();
                    const filePath = `mediatheque/photos/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.bannerImg, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    imageUrl = await getDownloadURL(storageRef);
                    dbObject.imageUrl = imageUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'mediatheque/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'mediatheque/'+dbObject.index);
                    await setDoc(newLEX, dbObject);
                }
            } else if(isPhotoContent && albumId) {
                const photosCollectionRef = collection(db, 'mediatheque/'+albumId+'/photos');
                const highestIndex = await getCollectionHighestIndex(photosCollectionRef);
                
                const dbObject:PhotoElementType={
                    albumId:albumId,
                    category:'photos',
                    subtitle:formValue.subtitle||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    createdAt:new Date().toDateString(),
                };
                
                let imageUrl:string="";
    
                if(formValue.bannerImg) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.bannerImg.name.split('.').pop();
                    const filePath = `mediatheque/photos/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.bannerImg, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    imageUrl = await getDownloadURL(storageRef);
                    dbObject.imageUrl = imageUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updatePhoto = doc(db, 'mediatheque/'+albumId+'/photos/'+dbItemId);
                    await updateDoc(updatePhoto, updateObj)
                } else {
                    const newPhoto = doc(db, 'mediatheque/'+albumId+'/photos/'+dbObject.index);
                    await setDoc(newPhoto, dbObject);
                }
            } else if(isVideoContent) {
                const videosCollectionRef = collection(db, 'mediatheque');
                const highestIndex = await getCollectionHighestIndex(videosCollectionRef);
                
                const dbObject:VideoElementType={
                    category:'videos',
                    subtitle:formValue.subtitle||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    createdAt:new Date().toDateString(),
                };
                
                
                let videoUrl:string="";
                if(formValue.bannerVideo) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.bannerVideo.name.split('.').pop();
                    const filePath = `mediatheque/videos/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.bannerVideo, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    videoUrl = await getDownloadURL(storageRef);
                    dbObject.videoUrl = videoUrl;
                };

                
                let posterImageUrl:string="";
                if(formValue.posterImg) {
                    const fileName = formValue.title+'_Poster_'+Date.now()+'.'+formValue.posterImg.name.split('.').pop();
                    const filePath = `mediatheque/videos/poster_${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.posterImg, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    posterImageUrl = await getDownloadURL(storageRef);
                    dbObject.posterUrl = posterImageUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'mediatheque/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'mediatheque/'+dbObject.index);
                    await setDoc(newLEX, dbObject);
                }
            } else if (isDocumentContent) {
                const documentsCollectionRef = collection(db, 'mediatheque');
                const highestIndex = await getCollectionHighestIndex(documentsCollectionRef);
                
                const dbObject:DocumentElementType={
                    category:'documents',
                    description:formValue.description||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    createdAt:new Date().toDateString(),
                    subCategory:formValue?.mediaDocSubCategory||'ami'
                };
                
                let documentUrl:string="";
    
                if(formValue.attachedFile) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.attachedFile.name.split('.').pop();
                    const filePath = `mediatheque/photos/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.attachedFile, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    documentUrl = await getDownloadURL(storageRef);
                    // @ts-ignore
                    dbObject.documentUrl = documentUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'mediatheque/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'mediatheque/'+dbObject.index);
                    await setDoc(newLEX, dbObject);
                }
            } else if (isPressbookContent) {
                const pressbookCollectionRef = collection(db, 'pressbook');
                const highestIndex = await getCollectionHighestIndex(pressbookCollectionRef);
                
                const dbObject:PressElementType={
                    description:formValue.description||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    articleUrl:formValue.articleUrl||'',
                    period:formValue.period||'',
                    createdAt:new Date().toDateString(),
                };
                
                let imageUrl:string="";
    
                if(formValue.bannerImg) {
                    const fileName = formValue.title+'_'+Date.now()+'.'+formValue.bannerImg.name.split('.').pop();
                    const filePath = `pressbook/${fileName||('unknown_name'+Date.now())}`;
                    
                    const storageRef = ref(storage, filePath);
                    await  uploadBytes(storageRef, formValue.bannerImg, {contentDisposition:fileName?`attachment; filename="${fileName}"`:undefined});
                    imageUrl = await getDownloadURL(storageRef);
                    dbObject.imageUrl = imageUrl;
                }
            
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'pressbook/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'pressbook/'+dbObject.index);
                    await setDoc(newLEX, dbObject);
                }
            } else if (isForumContent) {
                const forumsCollectionRef = collection(db, 'forums');
                const highestIndex = await getCollectionHighestIndex(forumsCollectionRef);
                
                const dbObject:ForumElementType={
                    description:formValue.description||'',
                    title:formValue.title,
                    index:typeof highestIndex =='number'? highestIndex+1:1,
                    createdAt:new Date().toDateString(),
                };
                
                if(isUpdate) {
                    //remove the "index" property before updating
                    const { index, ...updateObj} = dbObject;
                    
                    const updateSAD = doc(db, 'forums/'+dbItemId);
                    await updateDoc(updateSAD, updateObj)
                } else {
                    const newLEX = doc(db, 'forums/'+dbObject.index);
                    await setDoc(newLEX, {...dbObject});
                }
            }
            
            setFormValue(formInitial);
        } catch (error) {
            console.log('Error while publishing content: ', error);
        }

        setLoading(false);
    };


    useEffect(() => {
        if(!stateContent) return;
        if(isSADContent) {
            const updateContent = stateContent as SADElementType;
            setFormValue({
                ...updateContent,
                dasCategory:updateContent.category,
                attachedFile:null,
                bannerImg:null
            })
        } else if (isLEXContent) {
            const updateContent = stateContent as LEXElementType;
            setFormValue({
                ...updateContent,
                lexCategory:updateContent.category,
                attachedFile:null,
                bannerImg:null
            });
            console.log({
                ...updateContent,
                lexCategory:updateContent.category,
                attachedFile:null,
                bannerImg:null
            })            
        } else if (isPhotoContent) {
            const updateContent = stateContent as PhotoElementType;
            setFormValue({
                ...updateContent, attachedFile:null, bannerImg:null});
        } else if (isVideoContent) {
            const updateContent = stateContent as VideoElementType;
            setFormValue({...updateContent, attachedFile:null, bannerImg:null});
        } else if (isDocumentContent) {
            const updateContent = stateContent as DocumentElementType;
            setFormValue({...updateContent, attachedFile:null, bannerImg:null});
        } else if (isPressbookContent) {
            const updateContent = stateContent as PressElementType;
            setFormValue({...updateContent, attachedFile:null, bannerImg:null});
        } else if(isForumContent) {
            const updateContent = stateContent as ForumElementType;
            setFormValue({...updateContent, attachedFile:null, bannerImg:null});
        }
        
        
    }, [])
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <div className="max-w-2xl lg:max-w-5xl mx-auto">

                <h2 className="mb-8 text-2xl lg:text-3xl font-semibold text-gray-800 dark:text-gray-200">
                    {title||'Publier'} 
                </h2>

                    <div className="grid gap-4">
                        {
                            inputVisibles?.bannerImg?
                            <Dropzone
                                onDrop={acceptedFiles => setFormValue(values => ({...values, bannerImg:acceptedFiles.at(0)||null}))}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="border-2 border-dashed  w-full rounded-lg px-4 py-4 cursor-pointer hover:bg-slate-50 ring-slate-700"
                                        {...getRootProps()}
                                    >
                                        <input 
                                            // name="publication-banner-image"
                                            // id="publication-banner-image"
                                            {...getInputProps()}
                                            onChange={e => setFormValue(values => ({...values, bannerImg:e.target.files?.item(0)||null}))}
                                        />
                                        <div className="my-4">
                                            {
                                                (formValue.bannerImg || formValue.bannerImgUrl)?
                                                <img
                                                    src={
                                                        formValue.bannerImg?
                                                        URL.createObjectURL(formValue.bannerImg)
                                                        :formValue.bannerImgUrl
                                                    }
                                                    alt="Banner"
                                                    className="object-contain max-h-[80vh] w-full"
                                                />
                                                :
                                                <p>Déposez une image ici ou vous pouvez cliquer pour choisir une image</p>
                                            }
                                        </div>

                                    </div>
                                )}
                            </Dropzone>
                            :null
                        }
                        {
                            inputVisibles?.bannerVideo?
                            <Dropzone
                                onDrop={acceptedFiles => setFormValue(values => ({...values, bannerVideo:acceptedFiles.at(0)||null}))}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="border-2 border-dashed  w-full rounded-lg px-4 py-4 cursor-pointer hover:bg-slate-50 ring-slate-700"
                                        {...getRootProps()}
                                    >
                                        <input 
                                            // name="publication-banner-image"
                                            // id="publication-banner-image"
                                            {...getInputProps()}
                                            onChange={e => setFormValue(values => ({...values, bannerVideo:e.target.files?.item(0)||null}))}
                                        />
                                        <div className="my-4">
                                            {
                                                (formValue.bannerVideo||formValue.videoUrl)?
                                                <video
                                                    controls
                                                    className="max-h-[80vh] mx-auto rounded"
                                                    src={videoLocalUrl || formValue.videoUrl}
                                                />
                                                :
                                                <p>Déposez une vidéo ici ou vous pouvez cliquer pour choisir un fichier</p>
                                            }
                                        </div>

                                    </div>
                                )}
                            </Dropzone>
                            :null
                        }
                        {
                            inputVisibles?.posterImg?
                            <Dropzone
                                onDrop={acceptedFiles => setFormValue(values => ({...values, posterImg:acceptedFiles.at(0)||null}))}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="border-2 border-dashed  w-full rounded-lg px-4 py-4 cursor-pointer hover:bg-slate-50 ring-slate-700"
                                        {...getRootProps()}
                                    >
                                        <input 
                                            // name="publication-banner-image"
                                            // id="publication-banner-image"
                                            {...getInputProps()}
                                            onChange={e => setFormValue(values => ({...values, posterImg:e.target.files?.item(0)||null}))}
                                        />
                                        <div className="my-4">
                                            {
                                                (formValue.posterImg || formValue.posterUrl)?
                                                <img
                                                    src={
                                                        formValue.posterImg?
                                                        URL.createObjectURL(formValue.posterImg)
                                                        :formValue.posterUrl
                                                    }
                                                    alt="Poster for the video"
                                                    className="object-contain max-h-[80vh] w-full"
                                                />
                                                :
                                                <p>Déposez une image poster pour la vidéo ici ou vous pouvez cliquer pour choisir un fichier</p>
                                            }
                                        </div>

                                    </div>
                                )}
                            </Dropzone>
                            :null
                        }
                        {
                            inputVisibles?.attachedFile?
                            <Dropzone
                                onDrop={acceptedFiles => setFormValue(values => ({...values, attachedFile:acceptedFiles.at(0)||null}))}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="border-2 border-dashed  w-full rounded-lg px-4 py-4 cursor-pointer hover:bg-slate-50 ring-slate-700"
                                        {...getRootProps()}
                                    >
                                        <input 
                                            // name="publication-attached-file"
                                            // id="publication-attached-file"
                                            {...getInputProps()}
                                            onChange={e => setFormValue(values => ({...values, attachedFile:e.target.files?.item(0)||null}))}
                                        />
                                        
                                        <div className="flex items-center">
                                            <HiOutlineDocumentArrowUp className="h-8 w-8 mr-2"/>
                                            {
                                                (formValue.attachedFile || formValue.fileUrl || formValue.documentUrl)?
                                                <p>{formValue.attachedFile?.name || formValue.fileUrl || formValue.documentUrl}</p>
                                                :
                                                <p>Déposez un fichier ici ou vous pouvez cliquer pour choisir un document</p>
                                            }
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            :null
                        }
                        {/* <div className="grid grid-cols-2 sm:grid-cols-2 gap-4">
                            <CustomInput
                                label="Nom"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                name="hs-firstname-contacts-2"
                                id="hs-firstname-contacts-2"
                                placeholder="Nom"
                            />
                            <CustomInput
                                label="Prénom"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                name="hs-lastname-contacts-2"
                                id="hs-lastname-contacts-2"
                                placeholder="Prénom"
                            />
                        </div> */}
                        {
                            inputVisibles?.title?
                            <CustomInput
                                label="Titre"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                // name="publish-title"
                                // id="publish-title"
                                placeholder="Entrez le titre ici"
                                value={formValue.title}
                                onChange={e => setFormValue(values => ({...values, title:e.target.value}))}
                            />
                            :null
                        }
                        {
                            inputVisibles?.subtitle?
                            <CustomInput
                                label="Sous-titre"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                // name="publish-subtitle"
                                // id="publish-subtitle"
                                placeholder="Entrez le sous titre ici..."
                                value={formValue.subtitle}
                                onChange={e => setFormValue(values => ({...values, subtitle:e.target.value}))}
                            />
                            :null
                        }
                        
                        {
                            inputVisibles?.description?
                            <CustomTextArea
                                label="Description"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                // name="publish-description"
                                // id="publish-description"
                                placeholder="Entrez une description ici"
                                value={formValue.description}
                                rows={4}
                                onChange={e => setFormValue(values => ({...values, description:e.target.value}))}
                            />
                            :null
                        }

                        {
                            inputVisibles?.articleUrl?
                            <CustomInput
                                label="Lien externe vers l'article"
                                type="text"
                                labelClassName="font-medium"
                                className="p-2.5 mt-0.5"
                                // name="publish-subtitle"
                                // id="publish-subtitle"
                                placeholder="Entrez le lien ici..."
                                value={formValue.articleUrl}
                                onChange={e => setFormValue(values => ({...values, articleUrl:e.target.value}))}
                            />
                            :null
                        }
                        {/* <CustomTextArea
                            label="Contenu"
                            type="text"
                            labelClassName="font-medium"
                            className="p-2.5 mt-0.5"
                            name="publication-text-content"
                            id="publication-text-content"
                            placeholder="Entrez du contenu ici..."
                            rows={4}
                        /> */}
                        {
                            inputVisibles?.richTextContent?
                            <CustomRichEditor
                                label="Contenu"
                                labelClassName="font-medium"
                                placeholder="Entrez du contenu ici..."
                                value={formValue.richTextContent}
                                onChange={richText => setFormValue(values => ({...values, richTextContent:richText}))}
                            />
                            :null
                        }
                    </div>

                    {/* <CustomDropdown
                        label="Catégorie"
                        dropdownOptions={[{label:'Article', value:'article'}, {label:'Document', value:'document'}]}
                        placeholder="Choisir une option"
                        dropdownLabel="Catégorie"
                        className="p-2.5 mt-0.5"
                        labelClassName="font-medium mt-4"
                    /> */}
                    
                    {
                        inputVisibles?.DAS?
                        <CustomDropdown
                            label="Domaine d'Action Stratégique"
                            dropdownOptions={[
                                {label:'Accompagnement Communautaire', value:'accompagnement-communautaire'},
                                {label:'Surveillance environnementale', value:'surveillance-environnementale'},
                                {label:'Formation Continue (CEAS GRNE)', value:'formation-continue'},
                                {label:"Qualité et Disponibilié de l'information", value:'qualite-et-disponibilite-de-linfo'},
                                {label:'Encadrement du Secteur Artisanal', value:'supervision-du-secteur-artisanal'},
                            ].concat(isAlbum ?[{label:'FLAG', value:'flag'}]:[])
                        }
                            placeholder="Choisir une option"
                            dropdownLabel="Domaine d'Action Stratégique"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.DAS}
                            // @ts-ignore
                            onSelect={(option) => setFormValue(values=> ({...values, DAS:option.value}))}
                        />
                        :null
                    }
                    {
                        inputVisibles?.dasCategory?
                        <CustomDropdown
                            label="Catégorie du Domaine d'Action Stratégique"
                            dropdownOptions={[
                                {label:'Loi', value:'law'},
                                {label:'Décret', value:'decree'},
                                {label:'Note circulaire', value:'note'},
                                {label:'Rapport', value:'report'},
                                {label:'Arrêté', value:'order'},
                                {label:'Convention', value:'convention'}
                            ]}
                            placeholder="Choisir une option"
                            dropdownLabel="Domaine d'Action Stratégique"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.dasCategory}
                            // @ts-ignore
                            onSelect={(option) => setFormValue(values=> ({...values, dasCategory:option.value}))}
                        />
                        :null
                    }
                    {
                        inputVisibles?.mediaDocSubCategory?
                        <CustomDropdown
                            label="Sous-Catégorie du Média Document"
                            dropdownOptions={[
                                {label:'Ami', value:'ami'},
                                {label:'Documentation', value:'documentation'},
                                {label:'Journal FGF Brazza', value:'journal-fgf-brazza'},
                                {label:'PA-OI', value:'pa-oi'},
                                {label:'Nos Parutions', value:'nos-parutions'},
                                {label:"Rapports d'Activités", value:'rapports-dactivites'},
                                {label:"Rapports de Mission", value:'rapports-de-mission'}
                            ]}
                            placeholder="Choisir une option"
                            dropdownLabel="Sous-Catégorie du Média Document"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.mediaDocSubCategory}
                            // @ts-ignore
                            onSelect={(option) => setFormValue(values=> ({...values, mediaDocSubCategory:option.value}))}
                        />
                        :null
                    }
                    
                    {
                        inputVisibles?.LEX?
                        <CustomDropdown
                            label="Groupe LEX"
                            dropdownOptions={[
                                {label:'Agriculture', value:'agriculture'},
                                {label:"Forêts", value:'forests'},
                                {label:'Mines', value:'mining'},
                                {label:'Faune', value:'fauna'},
                            ]}
                            placeholder="Choisir une option"
                            dropdownLabel="Groupe LEX"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.LEX}
                            // @ts-ignore
                            onSelect={(option) => setFormValue(values=> ({...values, LEX:option.value}))}
                        />
                        :null
                    }
                    {
                        inputVisibles?.LEX?
                        <CustomDropdown
                            label="Catégorie LEX"
                            dropdownOptions={[
                                {label:"Convention d'Exploitation", value:'convention-exploitation'},
                                {label:"Convention Internationales", value:'convention-international'},
                                {label:'Études/Analyses', value:'studies-analysis'},
                                {label:'Jurisprudence', value:'jurisprudence'},
                                {label:'Législation', value:'legislation'},
                            ]}
                            placeholder="Choisir une option"
                            dropdownLabel="Catégorie LEX"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.lexCategory}
                            onSelect={(option) => setFormValue(values=> ({...values, lexCategory:option.value}))}
                        />
                        :null
                    }
                    {
                        (inputVisibles?.LEX && formValue.lexCategory ==='legislation')?
                        <CustomDropdown
                            label="Sous Catégorie LEX"
                            dropdownOptions={[
                                {label:"Arrêté", value:'order'},
                                {label:"Circulaire", value:'circular'},
                                {label:"Constitution", value:'constitution'},
                                {label:"Décret", value:'decree'},
                                {label:"Déscision", value:'descision'},
                                {label:"Lettre Circulaire", value:'circular-letter'},
                                {label:"Loi", value:'law'},
                                {label:"Ordonnance", value:'ordinance'},
                            ]}
                            placeholder="Choisir une option"
                            dropdownLabel="Sous Catégorie LEX"
                            className="p-2.5 mt-0.5"
                            labelClassName="font-medium mt-4"
                            value={formValue.lexSubCategory1}
                            onSelect={(option) => setFormValue(values=> ({...values, lexSubCategory1:option.value}))}
                        />
                        :null
                    }
                    {/* <CustomDatePicker
                        label="Date"
                        labelClassName="font-medium mt-4"
                    /> */}
                    {
                        inputVisibles?.period?
                        <CustomInput
                            label="Période"
                            type="text"
                            autoComplete="none"
                            labelClassName="font-medium"
                            containerClassName="mt-4"
                            className="p-2.5 mt-0.5"
                            // name="text-date"
                            // id="text-date"
                            placeholder="Entrez la période"
                            value={formValue.period}
                            onChange={e => setFormValue(values => ({...values, period:e.target.value}))}
                        />
                        :null
                    }
                    {
                        inputVisibles?.dateRange?
                        <CustomDateRangePicker
                            fromLabel="Visible du "
                            toLabel="Jusqu'au "
                            toLabelClassName="font-medium"
                            fromLabelClassName="font-medium"
                            containerClassName="mt-4"
                            value={formValue.dateRange||undefined}
                            onChange={range => setFormValue(values => ({...values, dateRange:range}))}
                        />
                        :null
                    }

                    <CustomButton
                        className="my-4 text-base"
                        // type="submit"
                        disabled={!formValue.title}
                        icon={loading? <ImSpinner8 className="animate-spin" />:null}
                        onClick={onFormSubmit}
                        title={submitTitle||"Publier"}
                    />
            </div>
        </div>
    )
};

export default Publish;

export const PublishLex = () => (
    <Publish inputVisibles={{
        attachedFile:true,
        title:true,
        LEX:true,
        lexCategory:true,
    }}
    title="Publier dans LEX"
    />
);

export const PublishDAS = () => (
    <Publish inputVisibles={{
        bannerImg:true,
        period:true,
        subtitle:true,
        title:true,
        richTextContent:true,
        DAS:true,
        dasCategory:true,
    }}
    title="Publier un DAS"
    />
)

export const CreateAlbum = () => (
    <Publish
        inputVisibles={{
            bannerImg:true,
            title:true,
            subtitle:true,
            DAS:true,
        }}
        title="Créer un nouvel Album"
        submitTitle="Créer"
    />
);

export const PublishPhoto = () => (
    <Publish inputVisibles={{
        bannerImg:true,
        subtitle:true,
        title:true,
    }}
    title="Publier une photo"
    />
);

export const PublishVideo = () => (
    <Publish inputVisibles={{
        bannerVideo:true,
        subtitle:true,
        title:true,
        posterImg:true
    }} />
);

export const PublishDocument = () => (
    <Publish inputVisibles={{
        attachedFile:true,
        description:true,
        title:true,
        mediaDocSubCategory:true
    }}
    title="Publier un document"
    />
);


export const PublishPressbook = () => (
    <Publish inputVisibles={{
        title:true,
        description:true,
        articleUrl:true,
        period:true,
        bannerImg:true,
    }}
    title="Publier un Article de Presse"
    />
);

export const CreateForum = () => (
    <Publish inputVisibles={{
        title:true,
        description:true,
    }}
    title="Créer un Forum"
    />
);