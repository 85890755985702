import { useEffect, useState } from 'react';
import { SideArticleType, SidePanelPropsType } from ".";
import { AgendaSVG, LogoOTP } from "../../assets/SVG_Logos";

import CustomButton from "../../components/CustomButton";
import Search from "../../components/Search";
import Newsletter from "../../components/Newsletter";
import { HiCalendar } from 'react-icons/hi2';
import { HiOutlineDocumentText } from "react-icons/hi";
import { DocumentElementType, LEXElementType, PublicationType, SADElementType } from "../../global/types";
import { publications } from "../../assets/examples";
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../App';
import { Link } from 'react-router-dom';

const SideArticle: React.FC<SADElementType> = (sideArticle) => {
    return (
        <Link
            className="group flex items-center gap-x-4 border-b border-b-slate-100 mb-3 pb-1"
            to={'/das/'+sideArticle.DAS+'?id='+sideArticle.id}    
        >
            <div className="grow">
                <span className="text-sm font-bold text-gray-800 group-hover:text-primary-light dark:text-gray-200 dark:group-hover:text-blue-500">
                    {sideArticle.title}
                </span>
                <p className="text-xs mt-2">{sideArticle.subtitle}</p>
            </div>

            <div className="flex-shrink-0 relative rounded-lg overflow-hidden w-20 h-20">
                <img
                    className="w-full h-full absolute top-0 left-0 object-cover rounded-lg"
                    src={sideArticle.bannerImgUrl||"http://flag-cmr.org/pictures/actus/logo_flag.png"}
                    alt="Image Description"
                />
            </div>
        </Link>

    )
};


const OTPRef = () => {
    return (
        <div className="flex flex-col p-4 md:p-5 bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800">
            <LogoOTP />
            <div className="mt-4">
                <h3
                    className="font-semibold text-gray-800 dark:hover:text-gray-400 dark:text-gray-200 cursor-default">
                    OTP est une plateforme dédiée à la promotion du commerce des produits forestiers, à la diffusion des informations sur les pratiques de terrain et à la compilation des informations ...
                </h3>
                <CustomButton
                    navigateTo='http://opentimberportal.org/'
                    className="mt-4 -mb-2 font-bold"
                    title="» Aller sur le site"
                />
            </div>
        </div>
    )
};


type AgendaItem = {
    title: string;
};

const agendaItems: AgendaItem[] = [
    {
        title: 'Webinaire de présentation de la 2e édition du Sommier ...',
    },
    {
        title: 'Webinaire de clôture du projet CV4C dans le Bassin du ...',
    },
    {
        title: "Forum sur le bois légal ..."
    }
]

const AgendaCard = () => {
    return (
        <div
            //the same aspect ratio than the agenda card; having the same width, they keep the same size 
            className="flex flex-col aspect-[calc(418.39/474.33)]"
        >
            <AgendaSVG
                className="absolute -z-10"
            />
            <div className="flex gap-x-2 mt-8 items-center text-lg font-medium py-2 px-4">
                Agenda
                <HiCalendar size={22} />
            </div>

            <div className=" border mx-2 mb-2 pb-2 px-2 rounded">
                {
                    agendaItems.map(item => (
                        <div
                            className="bg-slate-50 my-2.5 rounded-md px-3 py-1.5 text-sm border border-slate-100 hover:text-primary-light cursor-pointer"
                        >
                            {item.title}
                        </div>
                    ))
                }
                <CustomButton
                    className="mt-6 font-bold"
                    title="» Voir tous les évènements"
                />
            </div>
        </div>
    )
};



const PublicationsCard: React.FC<{ publications: DocumentElementType[] }> = ({ publications }) => {
    return (
        <div
            //the same aspect ratio than the agenda card; having the same width, they keep the same size 
            className="flex flex-col border rounded-2xl"
        >
            <div className="flex gap-x-2 items-center text-lg font-medium py-2 px-4">
                Publications
                <HiOutlineDocumentText size={24} />
            </div>
            <div className=" border mx-2 mb-2 pb-2 px-2 rounded">
                {
                    publications.map(item => (
                        <a
                            href={item.fileUrl}
                        >
                            <div
                                className="bg-slate-50 my-2.5 rounded-md px-3 py-1.5 text-sm border border-slate-100 hover:text-primary-light cursor-pointer"
                            >
                                {item.title}
                            </div>
                        </a>
                    ))
                }
                <CustomButton
                    navigateTo='/mediatheque/documents'
                    className="mt-6 font-bold"
                    title="» Voir toutes les publications"
                />
            </div>
        </div>
    )
};



const SidePanel: React.FC<SidePanelPropsType> = ({ sideArticles, showDocuments=true, showDASContent=true, showSocials=true }) => {
    const [blogArticles, setBlogArticles] = useState<SADElementType[]>([]);
    const [documents, setDocuments] = useState<DocumentElementType[]>([]);

    const getAndSetSADArticles = async () => {
        if(!showDASContent) return;

        const q = query(collection(db, 'sad'), orderBy('index', 'desc'), limit(3))
        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as SADElementType);
        setBlogArticles(docsData);
    };

    const getAndSetDocuments = async() => {
        if(!showDocuments) return;

        const q = query(collection(db, 'mediatheque'), orderBy('index', 'desc'),
            where('category', '==', 'documents'), limit(4)
        );
        const documentsSnapshot = await getDocs(q);
        const dbDocuments = documentsSnapshot.docs.map(document => ({...document.data(), id:document.id}) as unknown as DocumentElementType);
        
        setDocuments(dbDocuments);
    };

    useEffect(() => {
        getAndSetSADArticles();
        getAndSetDocuments();
    }, []);


   
    
    return (
        <div className="lg:col-span-1 lg:w-full lg:h-full lg:bg-gradient-to-r lg:from-gray-50 lg:via-transparent lg:to-transparent dark:from-slate-800">
            <div className="sticky top-0 left-0 py-8 lg:pl-4">
                {/* <div className="group flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 dark:border-gray-700">
                    <a className="block flex-shrink-0" href="#">
                        <img
                            className="h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                            alt="Image Description"
                        />
                    </a>

                    <a className="group grow block" href="">
                        <h5 className="group-hover:text-gray-600 text-sm font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                            Leyla Ludic
                        </h5>
                        <p className="text-sm text-gray-500">
                            UI/UX enthusiast
                        </p>
                    </a>

                    <div className="grow">
                        <div className="flex justify-end">
                            <button type="button" className="py-1.5 px-2.5 inline-flex justify-center items-center gap-x-1.5 rounded-full border border-transparent font-semibold bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900 text-xs">
                                <svg className="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                </svg>
                                Follow
                            </button>
                        </div>
                    </div>
                </div> */}
                <div className="space-y-6">
                    <div>
                        {
                            blogArticles?.map(article =>
                                <SideArticle
                                    {...article}
                                />
                            )
                        }
                        {
                            sideArticles?
                            <Link
                                to={'/das'}
                                className="text-sm font-bold text-gray-800 cursor-pointer hover:underline underline-offset-2 hover:text-primary dark:text-gray-200 dark:group-hover:text-primary"
                            >
                                Voir Plus...
                            </Link>
                            :
                            null
                        }
                    </div>
                    <OTPRef />
                    {/* <AgendaCard /> */}
                    {
                        showDocuments?
                        <PublicationsCard
                            publications={documents}
                        />
                        :null
                    }
                    <Search />
                    {/* Twitter embedding */}
                    <div
                        style={{ verticalAlign: 'bottom', height: 348, display:showSocials?'block':'none' }}
                    >
                        <div className="twitter-timeline twitter-timeline-rendered">
                            <iframe
                                id="twitter-widget-0"
                                allowTransparency
                                allowFullScreen
                                className="h-[348px] w-full"
                                title="Twitter Timeline"
                                src="https://syndication.twitter.com/srv/timeline-profile/screen-name/flagcameroon?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;origin=http%3A%2F%2Fflag-cmr.org%2Fprogramme-renforcement-des-capacites.php%3Fdhqsvfreq%3D4&amp;sessionId=9de790f9d64a635a194ce2da60da73b3c4d3498f&amp;showHeader=true&amp;showReplies=false&amp;transparent=false&amp;widgetsVersion=aaf4084522e3a%3A1674595607486"
                            >
                            </iframe>
                        </div>
                        <script src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    </div>
                    {/* Facebook embedding */}
                    <div
                        style={{display:showSocials?'block':'none'}}
                        data-href="https://www.facebook.com/OngFLAG/"
                        data-tabs="timeline, messages, events"
                        // data-width="298"
                        // data-height="348"
                        data-small-header="true"
                        data-adapt-container-width="true"
                        data-hide-cover="false"
                        data-show-facepile="true"
                        fb-xfbml-state="rendered"
                        fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=300&amp;height=348&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FOngFLAG%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline%2C%20messages%2C%20events&amp;width=298"
                    >
                        <span style={{ verticalAlign: 'bottom', height: 348 }}>
                            <iframe
                                name="fe66e783bfcc24"
                                width="334px"
                                height="348px"
                                data-testid="fb:page Facebook Social Plugin"
                                title="fb:page Facebook Social Plugin"
                                allowTransparency
                                allowFullScreen
                                allow="encrypted-media"
                                src="https://www.facebook.com/v2.12/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df354627ab59f7ac%26domain%3Dflag-cmr.org%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Fflag-cmr.org%252Ff260894d8bed834%26relation%3Dparent.parent&amp;container_width=334&amp;height=348&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FOngFLAG%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=true&amp;tabs=timeline%2C%20messages%2C%20events&amp;width=334"
                            >
                            </iframe>
                        </span>
                    </div>
                    <Newsletter/>
                </div>
            </div>
        </div>
    )
}


export default SidePanel;