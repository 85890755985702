import { useEffect, useState } from 'react';
import { PressElementType } from "../../global/types";
import CustomButton from "../../components/CustomButton";
import { BsChevronLeft, BsGear, BsTrash } from "react-icons/bs";
import { collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../App';
import { DeleteConfirmation } from '../../components/Modals';


export const PressItem:React.FC<PressElementType &{showSettings?:boolean, onDeleteClick:(title:string, id:string)
    => void}> = ({id, description, index, title, articleUrl, imageUrl, period, onDeleteClick, showSettings,}) => {
    return (
        // <a href={fileUrl}>
            <div className="flex  hover:bg-gray-50 rounded-md px-1 lg:px-3 py-2">
                <div>
                    <img
                        src={imageUrl||"http://flag-cmr.org/pictures/actus/logo_flag.png"}
                        alt="Prévue de l'article de presse"
                        className='h-12 md:h-16 lg:h-24 xl:h-28 aspect-square object-contain'
                    />
                </div>
                <div className="border-l-2 pl-2 w-full">
                    <div className="flex justify-between">
                    <p className="text-sm lg:text-base font-medium w-full">{title}</p>
                    {
                        showSettings?
                    <div className="grid grid-cols-2 gap-x-2">
                        <CustomButton
                            title=""
                            navigateTo={'modifier?id='+id}    
                            state={{content:{id, description, index, title, articleUrl, imageUrl, period}}}
                            icon={<BsGear size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                        <CustomButton
                            title=""
                            onClick={() => onDeleteClick(title, id as unknown as string)}
                            icon={<BsTrash size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                    </div>
                    :
                    <CustomButton
                        navigateTo={articleUrl}
                        title="Voir"
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                    }
                    </div>
                    <p className='font-medium text-gray-800 text-xs lg:text-sm'>{period}</p>
                    <p className="text-sm leading-tight mt-2">
                        {description}
                    </p>
                </div>
            </div>
        // </a>
    )
};


const Pressbook:React.FC<{showSettings?:boolean}> = ({showSettings}) => {
    const [pressBookItems, setPressbookItems] = useState<PressElementType[]>([]);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [loading, setLoading] = useState(false);

    const onDeleteMedia = async () => {
        if(loading || !deleteMedia?.id) return;
        setLoading(true);
        const dbDoc = doc(db, 'pressbook/'+deleteMedia.id);
        await deleteDoc(dbDoc);
        setPressbookItems(pressBookItems.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setLoading(false);
    };
    

    const getAndSetPressbookItems = async () => {
        let q = query(collection(db, 'pressbook'), orderBy('index', 'desc'));
        const pressbookDocumentsSnapshot = await getDocs(q);
        const docsData = pressbookDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as PressElementType);
        setPressbookItems(docsData);
;    };


    useEffect(() => {
        getAndSetPressbookItems();
    }, []);
    return (
        <div
            className="max-w-6xl px-2 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl"
        >
            {/* <MainContent 
                articleContent=""
                {...props}
            /> */}
            <div className="">
                <div className="py-8 lg:pr-8">
                    <div className="space-y-5 lg:space-y-8">
                        <a className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline dark:text-blue-400" href="#">
                            <BsChevronLeft size={12} />
                            Retour à la page d'acceuil
                        </a>
                        <div className="mb-8 ml-2">
                            <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Pressbook (Collection d'Articles de Presse)</h1>
                            {showSettings?
                            <CustomButton
                                title="Ajouter une référence"
                                className="py-2.5"
                                navigateTo="nouveau"
                            />:null}
                        </div>
                        
                        <div className="grid  gap-y-6">
                            {pressBookItems.map(item => (
                                // <a href={item.fileUrl} className="group transition-all">
                                //     <div className="group-hover:text-secondary group-hover:font-medium border-b p-1 group-hover:border-b-2 group-hover:border-secondary">
                                //         {item.title}
                                //     </div>
                                // </a>
                                <PressItem
                                    key={item.id}
                                    createdAt={item.createdAt}
                                    title={item.title}
                                    description={item.description}
                                    articleUrl={item.articleUrl}
                                    imageUrl={item.imageUrl}
                                    id={item.id}
                                    showSettings={showSettings}
                                    period={item.period}
                                    onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                                />
                            ))}
                        </div>
                        {/* <Chat 
                            previewMode
                        /> */}
                    </div>
                    <DeleteConfirmation
                        hideModal={() => setDeleteMedia(null)}
                        message={`Voulez vous supprimer l'article de presse "`+deleteMedia?.title+'" ?'}
                        title="Supprimer l'article"
                        showModal={deleteMedia?.id?true:false}
                        confirmModal={onDeleteMedia}
                    />
                </div>
            </div>
        </div>
    )
};


export default Pressbook;