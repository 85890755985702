import SADElement from "./SADElement";

const html = `
FLAG accompagne les acteurs du secteur artisanal afin de développer les filières
bois et pêche en facilitant l’accès à la ressource, la législation des activités
et le regroupement en association ; la montée en compétences, l’accès au crédit et
aux nouveaux marchés et la promotion des activités/produits à travers la labélisation.
`

const SupervisionOfTheCraftSector = () => {
    return (
        <SADElement
            DAS="supervision-du-secteur-artisanal"
            sadPoints={html}
            title="Encadrement du Secteur Artisanal"
        />       
    )
};

export default SupervisionOfTheCraftSector;