import { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { getCollectionHighestIndex } from "../pages/Admin/Publish";
import CustomButton from "./CustomButton";
import { HiOutlineNewspaper } from "react-icons/hi";
import { db } from "../App";
import { emailRegex } from "./AuthModals";


const Newsletter = () => {
    const newsletterCollectionRef = collection(db, 'newsletter');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const onSubmit = async () => {
        if(loading || !emailRegex.test(email)) return;
        setLoading(true);

        const highestIndex = await getCollectionHighestIndex(newsletterCollectionRef);
        const dbObject = {
            index:typeof highestIndex =='number'? highestIndex+1:1,
            createdAt:(new Date()).toDateString(),
            email:email,
        }
        const newEmail = doc(db, 'newsletter/'+dbObject.index);
        await setDoc(newEmail, dbObject);
        
        setEmail('');
        setLoading(false);
    };

    return (
        <div className="w-full">
            <div className="w-full sm:max-w-lg md:ml-auto">
                <div className="flex gap-x-2 items-center text-lg font-medium py-2 px-4 mb-1">
                    Newsletter
                    <HiOutlineNewspaper size={24} />
                </div>
                <div className="flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
                    <div className="w-full">
                        <input
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Entrez votre addresse email"
                        />
                    </div>
                    <CustomButton
                        onClick={onSubmit}
                        title="Souscrire"
                        className="py-3 text-sm px-3.5"
                    />
                </div>
                <p className="mt-3 text-sm text-gray-500 px-2">
                    Pas de spam, désabonnez vous à tout moment. 
                </p>
            </div>
        </div>
    )
}


export default Newsletter;