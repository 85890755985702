import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth, db } from "../App";
import { doc, getDoc } from "firebase/firestore";

type AuthContextType = {
    user:{email:string}|null;
    isAdmin:boolean;
    setUser:React.Dispatch<React.SetStateAction<{
        email: string;
    } | null>>
}
export const AuthContext = createContext<AuthContextType|null>(null);


const AuthProvider:React.FC<{children:React.ReactNode}> = ({children}) => {
    const [admins, setAdmins] = useState<string[]>([]);
    const [user, setUser] = useState<{email:string}|null>(null);

    const getAndSetAdmins = async () => {
        const adminsRef = doc(db, 'configs/admins');
        const adminsSnapshot = await getDoc(adminsRef);
        const adminsEmails = adminsSnapshot.data()?.admins;
        if(adminsEmails) setAdmins(adminsEmails);
    }
    useEffect(() => {
        getAndSetAdmins()
        onAuthStateChanged(auth, (userState) => {
            if(userState?.email) {
                setUser({email:userState.email})
            } else {
                setUser(null);
            }
        });
    }, [])
    
    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                isAdmin:user?.email? admins.map(item => item.toLowerCase()).includes(user.email.toLowerCase()):false
            }}    
        >
            {children}
        </AuthContext.Provider>
    )
};

export default AuthProvider;