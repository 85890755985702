import 'preline/dist/preline';
import './App.css';
import Header from './components/Header';
import ParallaxWrapper from './components/ParallaxWrapper';
import Footer from './components/Footer';
import AppRoutes from './AppRoutes';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyBJZy46Ib9QA_J9iQm2E1KTgu4aulinZNk",
    authDomain: "flag-site.firebaseapp.com",
    projectId: "flag-site",
    storageBucket: "flag-site.appspot.com",
    messagingSenderId: "160084187973",
    appId: "1:160084187973:web:922eec2ca54bd7c5867c2c",
    measurementId: "G-GSHBF8BFSD"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);


function App() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view')
  }, [location]);
    return (
        <div className="flex flex-1 flex-col">
            <ParallaxWrapper
                bgImages={[
                    require('./assets/background.jpg'),
                ]}
            >
                <div className='z-10'>
                    <Header />
                </div>
                <AppRoutes />
                <Footer/>
            </ParallaxWrapper>
        </div>
    );
}

export default App;
