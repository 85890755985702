import { useEffect, useState } from 'react';
import { DocumentItem } from "../Lex";
import { DocumentElementType, DocumentItemType } from "../../global/types";
import { collection, doc, getDocs, deleteDoc, query, where, orderBy } from "firebase/firestore";
import { db } from "../../App";
import CustomButton from '../../components/CustomButton';
import { DeleteConfirmation } from '../../components/Modals';
import { ChipSelect, CustomDropdown } from '../../components/CustomInput';
import { useNavigate, useSearchParams } from 'react-router-dom';



const documents:DocumentItemType[] = [
    {
        documentTitle:"Appel à manifestation d'intérêt",
        description:"Appel à manifestation d'intérêt pour le recrutement de Praticiens OI CEAS Promotion 2022.pdf. Bien vouloir télécharger le document ci-dessous pour plus d'informaions.",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20manifestation%20d'int%C3%A9r%C3%AAt_Praticiens%20OI_CEAS_Promotion%202022_.pdf",
    },
    {
        documentTitle:"Appel à candidature pour le recrutement d'un.e Stagiaire Juriste",
        description:"Field Legality Advisory Group recrute un.e stagiaire juriste. Pour en savoir davantage, bien vouloir télécharger les Termes de référence ci-après.",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20candidatures_Stagiaire_Juriste.pdf",
    },
    {
        documentTitle:`Leader National en charge de la conduite du processus de rédaction de l'"Index 2022 du suivi environmental par les OSC dans le bassin du Congo" au Cameroun.`,
        description:"Vous êtes intéressé.es ? Alors postulez au plus tard le 10 mars 2023 selon les modalités contenues dans l'appel à manifestation d'intérêt ci-dessous.",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20manifestation%20d'int%C3%A9r%C3%AAt_LN_Index%20suivi%20environnemental_Cameroun_VF.pdf",
    },
];


type DocumentsMediathequePropsType = {
    showSettings?:boolean;
};

const DocumentsMediatheque:React.FC<DocumentsMediathequePropsType> = ({showSettings}) => {
    const [documents, setDocuments] = useState<DocumentElementType []>([]);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    const navigate = useNavigate();
    const documentsSubCategory = searchParams.get('subcategory');
    
    const onDeleteMedia = async () => {
        if(loading || !deleteMedia?.id) return;
        setLoading(true);
        const dbDoc = doc(db, 'mediatheque/'+deleteMedia.id);
        await deleteDoc(dbDoc);
        setDocuments(documents.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setLoading(false);
    };

    const getAndSetMediathequeDocuments = async() => {
        const q = query(collection(db, 'mediatheque'), orderBy('index', 'desc'), where('category', '==', 'documents'));
        const documentsSnapshot = await getDocs(q);
        const dbDocuments = documentsSnapshot.docs.map(document => ({...document.data(), id:document.id}) as unknown as DocumentElementType);
        
        setDocuments(dbDocuments);
    };

    useEffect(() => {
        if(documentsSubCategory && !documents.length) {
            getAndSetMediathequeDocuments();
        }
    }, [documentsSubCategory]);

    return (
        <div className="max-w-6xl px-2 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <ChipSelect
                className="p-2.5 mt-0.5 mb-4"
                labelClassName="font-medium mt-4"
                
                label="Sous-Catégorie du Média Document"
                chipOptions={[
                    {label:'Ami', value:'ami'},
                    {label:'Documentation', value:'documentation'},
                    {label:'Journal FGF Brazza', value:'journal-fgf-brazza'},
                    {label:'PA-OI', value:'pa-oi'},
                    {label:'Nos Parutions', value:'nos-parutions'},
                    {label:"Rapports d'Activités", value:'rapports-dactivites'},
                    {label:"Rapports de Mission", value:'rapports-de-mission'},
                    // {label:'Tout', value:'all'}
                ]}
                onSelect={(option) => {
                    searchParams.set('subcategory', option.value);
                    navigate('?'+searchParams.toString(), {preventScrollReset:true, replace:true})
                }}
                value={documentsSubCategory||undefined}
            />
                    
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Documents</h1>
                {showSettings?
                <CustomButton
                    title="Ajouter un document"
                    className="py-2.5"
                    navigateTo="nouveau"
                />:null}
            </div>
            <div className="grid lg:grid-cols-2 lg:gap-x-4 gap-y-6">
                {
                    documentsSubCategory == null?
                    (
                        <div className='px-6 py-2 text-lg text-slate-400 font-medium'>
                            Veuillez Sélectionner Une Sous-Catégorie S'il Vous Plaît
                        </div>
                    )
                    :
                    documents.filter(item => item.subCategory == documentsSubCategory)
                        .map(item => 
                        <DocumentItem
                            id={item.id}
                            onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                            createdAt={item.createdAt}
                            title={item.title}
                            description={item.description}
                            category={item.category}
                            showSettings={showSettings}
                            // @ts-ignore
                            fileUrl={item.documentUrl}
                        />
                    )
                }
            </div>
            <DeleteConfirmation
                hideModal={() => setDeleteMedia(null)}
                message={'Voulez vous supprimer le document "'+deleteMedia?.title+'" ?'}
                title='Supprimer le document'
                showModal={deleteMedia?.id?true:false}
                confirmModal={onDeleteMedia}
            />
        </div>
    )
};

export default DocumentsMediatheque;