import React from 'react';
import { useParallax } from 'react-scroll-parallax';

type ParallaxWrapperProps = {
    bgImages: string[]
};

const ParallaxWrapper: React.FC<React.PropsWithChildren<ParallaxWrapperProps>> = ({
    bgImages,
    children,
}) => {
    
    const backgroundImage = bgImages.slice(0, 1).reduce((string, path, index) => {
        return string + (index !== 0 ? ', ' : '') + `url('${path}')`;
      }, '');

    //   const parallax = useParallax<HTMLDivElement>({translateY:[-30, 30]});
    return (
        <div style={{ position: 'relative', overflow:'hidden', backgroundImage:`url('${bgImages[0]}')`, backgroundRepeat:'repeat-y', backgroundSize:'cover' }}>
            <div
                // hidden overflow to hide parallax when images height outscale page height 
                className='backdrop-blur-[2px] backdrop-brightness-[85%]'
                >
                {/* <div
                    // easing={'easeInOut'}
                    className='!absolute -z-10 h-min w-full'
                    // speed={10}
                    ref={parallax.ref}
                >
                    {
                        bgImages.concat(bgImages).concat(bgImages).concat(bgImages).concat(bgImages).map((bgImage, index) =>
                            <img
                                src={bgImage}
                                alt="Background"
                                style={{
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    // objectFit: 'cover',
                                    filter: 'brightness(85%) blur(2px)',
                                    zIndex: -1,
                                }}
                            />
                        )
                    }
                </div> */}
                {children}
            </div>
        </div>
    );
};

export default ParallaxWrapper;
