import React, { useState } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';



interface CarouselProps {
    carousels: string[];
}

const Carousel: React.FC<CarouselProps> = ({ carousels }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <div className="max-w-4xl flex flex-col lg:flex-row justify-center mx-auto gap-x-10">
            <div className="relative lg:w-3/4">
                <ResponsiveCarousel
                    // dynamicHeight
                    showThumbs={false}
                    showIndicators={false}
                    selectedItem={currentSlide}
                    autoPlay
                    infiniteLoop
                    useKeyboardArrows
                    axis='vertical'
                    onChange={(index) => setCurrentSlide(index)}
                    
                    autoFocus={false}
                    showArrows={true}
                    swipeable
                    emulateTouch
                >
                    {carousels.map((item) => (
                        <div className='aspect-video rounded-xl overflow-hidden flex items-center' key={item}>
                            <img loading='lazy' src={item} alt="An image" />
                        </div>
                    ))}
                </ResponsiveCarousel>
               
            </div>
            {/* <div className=" mx-auto lg:mx-0 mt-1 lg:mt-0 flex lg:flex-col items-center gap-1.5">
                {carousels.map((item, index) => (
                    <img
                        key={item}
                        src={item}
                        alt={`Thumbnail ${index + 1}`}
                        className={`h-12 w-12 rounded lg:w-20 lg:h-20 object-cover my-2 cursor-pointer ${
                            index === currentSlide ? 'border-2 border-blue-500' : ''
                        }`}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div> */}
        </div>
    );
};

export default Carousel;