import { Link } from "react-router-dom";
import { LogoOTP } from "../../assets/SVG_Logos";



const FeatureIcon = ({icon}:{icon:any}) => {
    return (
        <img
            src={icon}
            alt=""
            className="mx-auto h-auto w-10 sm:w-12 md:w-16 filter invert-[0.9] sepia"
        />
    )
};


const features:{label:string, icon:any, linkTo:string}[] = [
    {
        icon:<FeatureIcon icon={require('./assets/features/document.png')}/>,
        label:"Nos publications",
        linkTo:"/mediatheque/documents",
    },
    // {
    //     icon:require('./assets/features/article.png'),
    //     label:"Des articles précis sur des thématiques pertinentes",
    //     linkTo:"/programme-legalite"
    // },
    {
        icon:<FeatureIcon  icon={require('./assets/features/forum.png')}/>,
        label:"Forum de discussion",
        linkTo:"/forums"
    },
    {
        icon:<FeatureIcon icon={require('./assets/features/team.png')}/>,
        label:"Une équipe dynamique",
        linkTo:"/a-propos/equipe"
    },
    {
        icon: <div className="scale-[.6] sm:scale-75 lg:scale-100"><LogoOTP height="3rem" width="12rem" color="#E6E6E6" className="sepia" /></div> ,
        label:"Open Timber Portal",
        linkTo:"http://opentimberportal.org/"
    }
];


const Feature:React.FC<{label:string, icon:JSX.Element, linkTo:string}> = ({icon, label, linkTo}) => {
    return (
        <Link className="col-span-6 lg:col-span-3 " to={linkTo}>
            <div className="text-center hover:bg-black hover:bg-opacity-30  p-4 lg:px-8  rounded-lg cursor-pointer">
                {icon}
                <div className="mt-2 sm:mt-6">
                    <h3 className="text-base lg:text-lg font-semibold text-gray-300">
                        {label}
                    </h3>
                </div>
            </div>
        </Link>
    )
};


const Features = () => {
    return (
        <div className="max-w-[85rem] mb-4 sm:px-2 lg:p-4 mx-auto">
            <div className="mx-auto max-w-2xl mb-4 lg:mb-8 text-center">
                <h2 className="text-md md:text-xl text-white font-bold">
                    PARCOUREZ NOTRE SITE WEB
                </h2>
                {/* <p className="mt-3 text-gray-800 dark:text-gray-200">
                    The powerful and flexible theme for all kinds of businesses.
                </p> */}
            </div>

            <div className="mx-auto max-w-6xl grid grid-cols-12 md:gap-4 lg:gap-8">
                
                {
                    features.map(feature => <Feature {...feature}/>)
                }
            </div>
        </div>
    
    )
};

export default Features;