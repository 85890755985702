import { useEffect, useState } from 'react';
import { sideArticles } from "../../assets/examples";
import { ChipSelect, CustomDropdown } from "../../components/CustomInput";
import { DocumentElementType, DocumentItemType, LEXCategoryType, LEXElementType } from "../../global/types";
import MainContent from "../Blog/MainContent";
import SidePanel from "../Blog/SidePanel";
import Chat from "../Chat";
import { HiOutlineDocumentText } from "react-icons/hi2";
import CustomButton from "../../components/CustomButton";
import { BsArrowLeft, BsChevronLeft, BsGear, BsTrash } from "react-icons/bs";
import { BiMessageSquareEdit } from "react-icons/bi";
import { collection, deleteDoc, doc, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../App';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteConfirmation } from '../../components/Modals';
import { lexIndex } from '../../utils';
import { GiCrosscutSaw, GiEarthAfricaEurope } from 'react-icons/gi';
import { MdAnalytics, MdBalance, MdBook, MdGrid4X4 } from 'react-icons/md';

export const DocumentItem:React.FC<Omit<DocumentElementType, 'subCategory'> &{showSettings?:boolean, LEX?:LEXCategoryType, onDeleteClick?:(title:string, id:string) => void}>
    = ({id, category, index, description, title, fileUrl, showSettings, onDeleteClick, LEX}) => {
    return (
        // <a href={fileUrl}>
            <div className="flex  hover:bg-gray-50 rounded-md px-1 lg:px-3 py-2">
                <div>
                    <HiOutlineDocumentText color='#AB0000' size={48} />
                </div>
                <div className="border-l-2 pl-2 w-full">
                    <div className="flex justify-between">
                    <p className="text-sm lg:text-base font-medium w-full">{title}</p>
                    {
                        showSettings?
                    <div className="grid grid-cols-2 gap-x-2">
                        <CustomButton
                            title=""
                            navigateTo={'modifier?id='+id}
                            state={{content:{id, category, index, description, title, fileUrl, LEX}}}
                            icon={<BsGear size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                        <CustomButton
                            title=""
                            onClick={() =>{if(onDeleteClick) onDeleteClick(title, id as unknown as string)}}
                            icon={<BsTrash size={16}/>}
                            className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                        />
                    </div>
                    :
                    <CustomButton
                        navigateTo={fileUrl}
                        title="Télécharger"
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                    }
                    </div>
                    <p className="text-sm leading-tight mt-2">
                        {description}
                    </p>
                </div>
            </div>
        // </a>
    )
};


const LEX:React.FC<{showSettings?:boolean}> = ({showSettings}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [lexFiles, setLEXFiles] = useState<LEXElementType[]>([]);
    // const [lexCategory, setLexCategory] = useState<null|LEXCategoryType>(null);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [loading, setLoading] = useState({deleteMedia:false, lexFiles:false});

    const onDeleteMedia = async () => {
        if(loading.deleteMedia || !deleteMedia?.id) return;
        setLoading(value => ({...value, deleteMedia:true}));

        const dbDoc = doc(db, 'lex/'+deleteMedia.id);
        await deleteDoc(dbDoc);
        setLEXFiles(lexFiles.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setLoading(value => ({...value, deleteMedia:false}));
    };

    const lexGroup = searchParams.get('group');
    const lexCategory = searchParams.get('category');
    const lexSubCategory1 = searchParams.get('subcategory1');
    const quicksearch = searchParams.get('quicksearch');
    
    const lexGroupMap = (group:string) => {
        if(group ==='faune') return 'fauna';
        if(group ==='forets') return 'forests';
        if(group ==='agriculture') return 'agriculture';
        if(group ==='mines') return 'mines';
    };

    const navigate = useNavigate();
    
    const getAndSetLEXArticles = async () => {
        if(loading.lexFiles) return;
        setLoading(value => ({...value, lexFiles:true}));

        let q = query(collection(db, 'lex'), orderBy('index', 'desc'));
        if(lexCategory && lexCategory !='all') {
            q = query(q, where('category', '==', lexCategory));
        };
        
        if(lexSubCategory1 && lexSubCategory1 !='all') {
            q = query(q, where('subCategory1', '==', lexSubCategory1));
        };

        // apply the lexgroup if available
        if(lexGroup) q = query(q, where('LEX', '==', lexGroupMap(lexGroup)));

        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as LEXElementType);
        setLEXFiles(docsData);
        
        setLoading(value => ({...value, lexFiles:false}));
    };

    const getAndSetSearchLEXArticles = async (title:string) => {
        if(loading.lexFiles) return;
        setLoading(value => ({...value, lexFiles:true}));
        if(!title.trim()) return;
        
        let results:LEXElementType[]= []
        try {
            const { hits } = await lexIndex.search(title);
        
            results = hits.map(item => {
                const {
                    LEX,
                    category,
                    createdAt,
                    index,
                    title,
                    documentUrl,
                    objectID:id,
                } = item as unknown as LEXElementType & {objectID:string};
                return {category, createdAt, index, LEX, title, documentUrl, id:Number(id)};
            });

            if(lexGroup) results = results.filter(item => item.LEX === lexGroup);
            if(lexCategory && lexCategory !='all') results = results.filter(item => item.category === lexCategory);
            if(lexSubCategory1 && lexSubCategory1 !='all') results = results.filter(item => item.subCategory1 === lexSubCategory1);

            setLEXFiles(results);
        } catch (error) {
            console.log('Error while searching algolia: ', error)
        }
        
        setLoading(value => ({...value, lexFiles:false}));
    }
    
    useEffect(() => {
        if(quicksearch) {
            getAndSetSearchLEXArticles(quicksearch)
        } else {
            if(lexGroup && lexCategory) getAndSetLEXArticles();
        }
    }, []);
    
    useEffect(() => {
        if(quicksearch) {
            getAndSetSearchLEXArticles(quicksearch)
        } else {
            if(lexGroup && lexCategory) getAndSetLEXArticles();
        }
    }, [lexGroup, lexCategory, lexSubCategory1]);
    return (
        <div
            className="max-w-6xl px-2 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl"
        >
            <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 md:gap-x-4">
                {/* <MainContent 
                    articleContent=""
                    {...props}
                /> */}
                <div className="lg:col-span-2">
                    <div className="py-8 lg:pr-8">
                        <div className="space-y-5 lg:space-y-8">
                            <Link to={'/'} className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline dark:text-blue-400">
                                <BsChevronLeft size={12} />
                                Retour à la page d'acceuil
                            </Link>
                                {(quicksearch) ?
                                        <p className="font-medium text-xl lg:text-2xl mb-8 underline underline-offset-4">
                                            Résultats de la recherche: "{quicksearch}"
                                        </p>
                                    :
                                null}
                            <div className='flex justify-between uppercase font-bold text-xl mt-4'>
                                FLAG LEX{lexGroup? ` - ${lexGroup}`:null}
                                {
                                    showSettings?
                                    <CustomButton
                                        title="Nouvelle Publication"
                                        className="py-2.5 normal-case"
                                        navigateTo="nouveau"
                                    />
                                    :
                                    null
                                }
                            </div>
                            {
                                showSettings?
                                <ChipSelect
                                    label="Groupe Lex"
                                    chipOptions={[
                                        {label:'Agriculture', value:'agriculture'},
                                        {label: 'Forêts', value:'forets'},
                                        {label:'Mines', value:'mines'},
                                        {label:'Faune', value:'faune'},
                                    ]}
                                    value={lexGroup||undefined}
                                    onSelect={(option) => {
                                        searchParams.set('group', option.value);
                                        navigate('?'+searchParams.toString(), {preventScrollReset:true, replace:true})
                                    }}
                                    // dropdownLabel="Catégorie LEX"
                                    className="pb-2.5 mt-0.5 text-base border"
                                    labelClassName="font-medium mb-1 text-lg"
                                />
                                :
                                null
                            }
                            <ChipSelect
                                label="Catégorie LEX"
                                chipOptions={[
                                    {label:"Convention d'Exploitation", value:'convention-exploitation', icon:<GiCrosscutSaw size={20}/>},
                                    {label:"Convention Internationales", value:'convention-internationales', icon:<GiEarthAfricaEurope size={20}/>},
                                    {label:'Études/Analyses', value:'studies-analysis', icon:<MdAnalytics size={20}/>},
                                    {label:'Jurisprudence', value:'jurisprudence', icon:<MdBook size={20}/>},
                                    {label:'Législation', value:'legislation', icon:<MdBalance size={20}/>},
                                    // {label:'Tout', value:'all', icon:<MdGrid4X4 size={18}/>},
                                ]}
                                value={lexCategory||undefined}
                                onSelect={(option) => {
                                    searchParams.set('category', option.value);
                                    if(option.value == 'legislation') {
                                        searchParams.set('subcategory1', 'order')                    
                                    } else {
                                        searchParams.delete('subcategory1')
                                    };
                                    navigate('?'+searchParams.toString(), {preventScrollReset:true, replace:true})
                                }}
                                // dropdownLabel="Catégorie LEX"
                                className="pb-2.5 mt-0.5 text-base border"
                                labelClassName="font-medium mb-1 text-lg"
                            />
                            
                            {
                                lexCategory ==='legislation'?
                                <ChipSelect
                                    label="Sous Catégorie LEX"
                                    chipOptions={[
                                        {label:"Arrêté", value:'order'},
                                        {label:"Circulaire", value:'circular'},
                                        {label:"Constitution", value:'constitution'},
                                        {label:"Décret", value:'decree'},
                                        {label:"Déscision", value:'descision'},
                                        {label:"Lettre Circulaire", value:'circular-letter'},
                                        {label:"Loi", value:'law'},
                                        {label:"Ordonnance", value:'ordinance'},
                                        // {label:"Tout", value:'all'}
                                    ]}
                                    value={lexSubCategory1||'order'}
                                    onSelect={(option) => {
                                        searchParams.set('subcategory1', option.value);
                                        navigate('?'+searchParams.toString(), {preventScrollReset:true, replace:true})
                                    }}
                                    className="pb-2.5 mt-0.5 text-base border"
                                    labelClassName="font-medium mb-1 text-lg"
                                />
                                :
                                null
                            }
                            <div className="min-h-[20vh] border p-2  lg:p-4 pb-8 rounded-md">
                                {!loading.lexFiles && !lexFiles.length?
                                    <div className='text-xl font-medium text-center my-[5vh] text-gray-400'>
                                        
                                        {quicksearch? "Aucun document correspondant a la recherche"
                                        :
                                        !lexCategory? "Veuillez Sélectionner Une Catégorie S'il Vous Plaît"
                                        :
                                        "Aucun document ici pour le moment..."
                                        }
                                    </div>
                                        :
                                    null
                                }
                                {lexFiles.map(item => (
                                    // <a href={item.fileUrl} className="group transition-all">
                                    //     <div className="group-hover:text-secondary group-hover:font-medium border-b p-1 group-hover:border-b-2 group-hover:border-secondary">
                                    //         {item.title}
                                    //     </div>
                                    // </a>
                                    <DocumentItem
                                        id={item.id}
                                        key={item.id}
                                        createdAt={item.createdAt}
                                        title={item.title}
                                        description=""
                                        LEX={item.LEX as any}
                                        category={(item.category ||'documents' )as any}
                                        fileUrl={item.documentUrl as string}
                                        showSettings={showSettings}
                                        onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                                    />
                                ))}
                            </div>
                            {/* <Chat 
                                previewMode
                            /> */}
                        </div>
                    </div>
                </div>
                {
                    showSettings?
                    null
                    :
                    <SidePanel
                        sideArticles={sideArticles}
                    />
                }
            </div>
            <DeleteConfirmation
                hideModal={() => setDeleteMedia(null)}
                message={'Voulez vous supprimer la publication LEX "'+deleteMedia?.title+'" ?'}
                title='Supprimer la publication LEX'
                showModal={deleteMedia?.id?true:false}
                confirmModal={onDeleteMedia}
            />
        </div>
    )
};


export default LEX;