import SADElement from "./SADElement"

const html = `
A travers le DAS sur la Surveillance Environnementale FLAG innove dans le suivi indépendant de la gestion des ressources naturelles en promouvant des méthodes standardisées ciblant les activités de restauration et d’exploitation et les aspects sociaux dans les domaines des forêts, mines et fonciers. Les activités menées portent notamment sur l’exploitation, la restauration et le suivi méthodique et régulier.
<ul class="list-disc list-inside">
<li>
Les activités d’exploitation concernent principalement des forêts et les mines. Les aspects pris en compte étant la gouvernance, les aspects sociaux, la traçabilité et le commerce. 
</li>
<li>
Les activités de restauration prennent en compte les forêts (Reboisement et régénération), les mines (remise en état après exploitation) et le Sol (Reboisement, reconstruction/enrichissement et l’agriculture). 
</li>
<li>
Le travail sur le suivi méthodique et régulier porte sur la standardisation des approches et outils par domaine et la diffusion des méthodologies de suivi dans les domaines de l’Observation Indépendante (OI). 
</li>
</ul>
Les prescriptions de ce DAS sont prises en compte dans la recherche des financements et les actions mises en œuvre. Elles font partie des critères d’évaluation de la pertinence des propositions de financement et des idées de projets. Le cas échéant, les aspects liés à la surveillance environnementale sont clairement mis en exergue dans toutes les actions menées. 
`

const EnvironmentalMonitoring = () => {
    return (
        <SADElement
            DAS="surveillance-environnementale"
            sadPoints={html}
            title="Surveillance Environnementale"
        />
    )
};

export default EnvironmentalMonitoring;