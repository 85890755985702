import "../assets/react-quill-snow.css";
import {useRef} from 'react';
import ReactQuill, { Value } from "react-quill";

type CustomRichEditorPropsType = {
    onChange?:(value:string) => void
    value?:Value
    label?:React.ReactNode
    disabled?:boolean
    hidden?:boolean
    className?:string;
    labelClassName?:string;
    placeholder?:string;
};

const CustomRichEditor:React.FC<CustomRichEditorPropsType> = ({label, onChange, value, disabled, hidden, className, labelClassName, placeholder}) => {
    const quillRef = useRef<ReactQuill>(null);
    return (
        <div>
            {label? <div className={"mb-2 "+labelClassName}>{label}</div> : null}
            <ReactQuill
                ref={quillRef}
                className={"textInput max-h-none "+ className+ (hidden? ' inputHidden ':'')}
                theme="snow"
                onChange={onChange}
                value={value}
                readOnly={disabled}
                placeholder={placeholder}
            />
        </div>
    )
};

export default CustomRichEditor;