import "react-datetime/css/react-datetime.css";
import { BiChevronDown } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import ReactDatetime from "react-datetime"
import moment from "moment";
import 'moment/locale/fr'; // Import the French locale
import { DateRange } from "../global/types";


type ChipOption = {
    label: string;
    value: string;
    icon?:React.ReactNode;
};

type ChipSelectPropsType = {
    label?: string;
    labelClassName?: string;
    className?: string;
    value?:string;
    chipOptions?: ChipOption[];
    onSelect?:(T:ChipOption) => void
};

const ChipSelect: React.FC<ChipSelectPropsType> = ({ chipOptions, label, className, labelClassName, ...props }) => {
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState<ChipOption | undefined>(chipOptions?.find(item => item.value === props.value));
    
    
    const onOptionClick = (option: DropdownOption) => {
        setValue(option);
        setExpanded(false);
        if(props.onSelect) props.onSelect(option);
    };

    useEffect(() => {
        setValue(chipOptions?.find(item => item.value === props.value))
    }, [props.value])
    return (
        <div>  
            <label
                className={"inline-block " + labelClassName}
            >
                {label}
            </label>

            <div className="relative">
                {
                    chipOptions?.map(option => (
                        <button 
                            onClick={() => onOptionClick(option)}
                            className={"chipSelectItem "+ (option.icon?'pl-1 ':'') + (value?.value === option.value?'chipSelectItemFocused':'')}
                        >    {option.icon}
                            {option.label}
                        </button>
                    ))
                }
            </div>
        </div>
    )
}


type DropdownOption = {
    label: string;
    value: string;
};

type CustomDropDownPropsType = {
    label?: string;
    labelClassName?: string;
    className?: string;
    placeholder?: string;
    dropdownLabel?: string;
    value?:string;
    dropdownOptions?: DropdownOption[];
    onSelect?:(T:DropdownOption) => void
};

const CustomDropdown: React.FC<CustomDropDownPropsType> = ({ dropdownOptions, dropdownLabel, label, placeholder, className, labelClassName, ...props }) => {
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState<DropdownOption | undefined>(dropdownOptions?.find(item => item.value === props.value));
    
    const dropdownRef: React.LegacyRef<HTMLDivElement> = useRef(null);

    // Define a function to handle the click event on the document
    const handleDocumentClick = (event: MouseEvent) => {
        // Check if the click target is inside or outside the dropdown component
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as (Node | null))) {
            // If outside, hide the dropdown menu
            setExpanded(false);
        }
    };

    const onOptionClick = (option: DropdownOption) => {
        setValue(option);
        setExpanded(false);
        if(props.onSelect) props.onSelect(option);
    };

    useEffect(() => {
        setValue(dropdownOptions?.find(item => item.value === props.value))
    }, [props.value])
    // Use a useEffect hook to add and remove the event listener when the component mounts and unmounts
    useEffect(() => {
        // Add the event listener to the document
        document.addEventListener('click', handleDocumentClick);

        // Return a cleanup function that removes the event listener from the document
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    return (
        <div>  
            <label
                className={"inline-block " + labelClassName}
            >
                {label}
            </label>

            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={(e) => {e.preventDefault(); setExpanded(!expanded)}}
                    className={`customInput py-2 px-3 w-full flex justify-between cursor-default `
                        +
                        (!value?'text-gray-500 ':'')
                        + className
                    }
                >
                    {value ? value.label : placeholder}
                    <BiChevronDown
                        size={22}
                        className=""
                    />
                </button>
                {
                    expanded ?
                        <div
                            className="dropdownOption z-10 bg-[#fafafa]"
                        >
                        {
                            dropdownOptions?.map(option => (
                                <div
                                    key={option.value}
                                    onClick={() => onOptionClick(option)}
                                    className="hover:bg-primary bg-opacity-80 hover:text-white px-3 py-1.5 rounded cursor-pointer"
                                >
                                    {option.label}
                                </div>
                            ))
                        }
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}


type CustomDatePickerPropsType = {
    value?:Date|string
    onChange?:(value:Date) => void
    initialValue?:Date
    className?:string
    label?:string
    labelClassName?:string;
    containerClassName?:string
    isValidDate?:(currentDate: any, selectedDate: any) => boolean
};

const CustomDatePicker:React.FC<CustomDatePickerPropsType> = ({initialValue, onChange, value, label, className, containerClassName, labelClassName, isValidDate}) => {
    return (
        <div className={"flex flex-1 flex-col "+ containerClassName}>
            <label
                className={"inline-block " + labelClassName}
            >
                {label}
            </label>
            <ReactDatetime
                initialValue={initialValue}
                value={moment(value).toDate()}
                onChange={(pickedDate) => {if(onChange) onChange(moment(pickedDate).toDate())}}
                dateFormat='DD MMMM YYYY à'
                locale="fr"
                inputProps={{className:"customInput p-2.5 mt-0.5 cursor-default "+className}}
                isValidDate={isValidDate}
            />
        </div>
    );
};




type CustomDateRangePickerPropsType = {
    value?:DateRange;
    onChange?:(value:DateRange) => void;
    initialValue?:Date;
    className?:string;
    labelClassName?:string;
    fromLabel?:string;
    toLabel?:string;
    fromLabelClassName?:string;
    toLabelClassName?:string;
    containerClassName?:string;
};

const CustomDateRangePicker:React.FC<CustomDateRangePickerPropsType> = ({fromLabel, toLabel, ...props}) => {

    const [dateRange, setDateRange] = useState<DateRange>({fromDate:undefined, toDate:undefined})

    useEffect(() => {
        if(props.onChange) props.onChange(dateRange)
    }, [dateRange])
    return (
        <div className={"grid grid-cols-2 gap-x-4 "+props.containerClassName}>
            <CustomDatePicker
                label={fromLabel}
                value={props.value?props.value.fromDate: dateRange.fromDate}
                isValidDate={(currentDate) => moment(currentDate).isSameOrBefore(dateRange.toDate)}
                onChange={(value) => setDateRange(state => ({...state, fromDate:value}))}
                labelClassName={props.fromLabelClassName}
            />
            <CustomDatePicker
                label={toLabel}
                value={props.value?props.value.toDate: dateRange.toDate}
                isValidDate={(currentDate) => moment(currentDate).isSameOrAfter(dateRange.fromDate)}
                onChange={(value) => setDateRange(state => ({...state, toDate:value}))}
                labelClassName={props.toLabelClassName}
            />
        </div>
    )
};


type CustomInputPropsType = {
    label?: string;
    placeholder: string;
    type?: string;
    id?: string;
    className?: string;
    labelClassName?: string
    containerClassName?: string;
    name?: string
    autoComplete?: string
    value?:string;
    hidden?:boolean
    onChange?:React.ChangeEventHandler<HTMLInputElement>;
};


const CustomInput: React.FC<CustomInputPropsType> = ({ label, placeholder, id, className, name, type, ...props }) => {
    return (
        <div className={props.containerClassName}>
            <label htmlFor={id} className={props.labelClassName}>{label}</label>
            <input
                type={type}
                name={name}
                id={id}
                className={"customInput " + (className || "")}
                autoComplete={props.autoComplete}
                placeholder={placeholder}
                value={props.value}
                onChange={props.onChange}
                hidden={props.hidden}
            />
        </div>
    )
};

type CustomTextAreaProps = {
    onChange?:React.ChangeEventHandler<HTMLTextAreaElement>
    rows?: number,
}
const CustomTextArea: React.FC<CustomInputPropsType & CustomTextAreaProps> = ({ label, labelClassName, placeholder, id, className, rows, name, type, autoComplete, value, onChange }) => {
    return (
        <div>
            <label htmlFor={id} className={labelClassName}>{label}</label>
            <textarea onChange={onChange} rows={rows} value={value} name={name} id={id} className={"customInput " + (className || "")} autoComplete={autoComplete} placeholder={placeholder} />
        </div>
    )
};

export { CustomTextArea, CustomDropdown, CustomDatePicker, CustomDateRangePicker, ChipSelect };
export default CustomInput;