import { sideArticles } from "../../assets/examples";
import Blog from "../Blog"


const content = `
<div>
    FLAG se déploie dans les pays du Bassin du Congo spécifiquement et les autres pays ou les acteurs
    auront manifesté un besoin d’appui dans le suivi de la gouvernance des ressources naturelles.
    Les réponses positives à certains éléments fondamentaux seront déterminantes pour que FLAG s’engage
    ou pas dans une action. Il faudra au préalable que le pays dispose, de ressources naturelles qui
    sont valorisées (ex. mise en exploitation, conservation, tourisme) ; des lois qui règlementent la
    gestion des ressources naturelles; d’un niveau de volonté politique acceptable existe dans le pays
    illustré à travers la signature des Actes, Conventions, Déclarations, etc. liés à la gestion durable
    des ressources naturelles; des ressources financières doivent être disponibles pour pouvoir travailler
    dans le pays ; des ressources naturelles doivent avoir une importance sociale, économique ou écologique
    pour le pays ; une stabilité politique et des conditions minimales de sécurité doivent prévaloir
    dans le pays ...
</div>
`


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];


const InterventionZones = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={content}
            programTitle="ZONES D’INTERVENTION"
            // bannerImgUrl="http://flag-cmr.org/pictures/zones_dintervention.png"
            bannerImg={require('../../assets/about/zones_dintervention.png')}
            sideArticles={sideArticles}
        />
    )
};

export default InterventionZones;