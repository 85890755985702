import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { sideArticles } from "../../assets/examples";
import SidePanel from "../Blog/SidePanel";
import { BlogCard } from "../Home/Blog";
import { useEffect, useState } from "react";
import { PublicationType, SADElementType } from "../../global/types";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { db } from "../../App";
import CustomButton from "../../components/CustomButton";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { sadIndex } from "../../utils";


type SADElementPropsType = {
    /** Strategic actions domains key points */
    sadPoints?:string;
    title?:string;
    DAS?:'accompagnement-communautaire'|'surveillance-environnementale'|'formation-continue'|'qualite-et-disponibilite-de-linfo'|'supervision-du-secteur-artisanal'
    
};

const SADElement:React.FC<SADElementPropsType> = ({sadPoints, title, DAS}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [post, setPost] = useState<SADElementType|null>(null);
    const postId = searchParams.get('id');
    const quicksearch = searchParams.get('quicksearch');
    const [blogArticles, setBlogArticles] = useState<SADElementType[]>([]);
    const [hasMore, setHasMore] = useState(true); // Track if there are more items

    const navigate = useNavigate();
    const pageSize = 6;

    const loadMore = async () => {
        let q = query(
            collection(db, 'sad'),
            orderBy('index', 'desc'),
            startAfter(blogArticles.at(-1)?.index),
            limit(pageSize)
        );

        if(DAS) q = query(
            collection(db, 'sad'),
            orderBy('index', 'desc'),
            where('DAS', '==', DAS),
            startAfter(blogArticles.at(-1)?.index),
            limit(pageSize)
        );
        
        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as SADElementType);
        setHasMore(!dasDocumentsSnapshot.empty && dasDocumentsSnapshot.docs.length === pageSize);
        setBlogArticles(articles => [...articles, ...docsData]);
    };

    const getAndSetSADArticles = async () => {
        let q = query(
            collection(db, 'sad'),
            orderBy('index', 'desc'),
            limit(pageSize)
        );

        if(DAS) q = query(
            collection(db, 'sad'),
            orderBy('index', 'desc'),
            where('DAS', '==', DAS),
            limit(pageSize)
        );
        
        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as SADElementType);
        
        setHasMore(!dasDocumentsSnapshot.empty && dasDocumentsSnapshot.docs.length === pageSize);
        setBlogArticles(docsData);
    };

    const getSearchSAD = async (title:string) => {
        if(!title.trim()) return;
        let results:SADElementType[]= []
        try {
            const { hits } = await sadIndex.search(title);
        
            results = hits.map(item => {
                const {
                    DAS,
                    category,
                    bannerImgUrl,
                    createdAt,
                    index,
                    subtitle,
                    title,
                    objectID:id,
                    period,
                    rawHtml
                } = item as unknown as SADElementType & {objectID:string};
                return {DAS, category, bannerImgUrl, createdAt, index, subtitle, title, id:Number(id), period, rawHtml}
            });

            setBlogArticles(results);
        } catch (error) {
            console.log('Error while searching algolia: ', error)
        }
    }
    
    const onBlogCardClick = (item:SADElementType) => {
        searchParams.set('id', `${item.id}`);
        navigate('/das/'+item.DAS+'?'+searchParams.toString(), {preventScrollReset:true})
    }
    

    useEffect(() => {
        // Independently set the focused post (from the displayed posts list)
        if(postId) {
            const foundPost = blogArticles.find(item => `${item.id}` === postId);
            if(foundPost) {
                setPost(foundPost)
            } else {
                const dbPostRef = doc(db, 'sad/'+postId);
                getDoc(dbPostRef).then(snapshot => {
                    if(snapshot.exists()) {
                        setPost({...snapshot.data(), id:Number(snapshot.id)} as any)
                    }
                })
            };
            
        }
    }, [postId]);
    
    useEffect(() => {
        if(quicksearch) {
            getSearchSAD(quicksearch)
        } else {
            getAndSetSADArticles();
        }
    }, []);
    return (
        <div
            className="max-w-6xl px-4 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl"
        >
            <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 md:gap-x-4">
                {/* <MainContent 
                    articleContent=""
                    {...props}
                /> */}
                <div className="lg:col-span-2">
                    <div className="py-8 lg:pr-8">
                        <div className="space-y-5 lg:space-y-8">
                            <Link to={'/'} className="inline-flex items-center gap-x-1.5 text-primary decoration-2 hover:underline dark:text-primary">
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                                Retour à la page d'acceuil
                            </Link>

                            <div>
                                {
                                    title?
                                    <p className="font-medium text-xl lg:text-2xl mb-8">
                                        {title}
                                    </p>
                                    :
                                    (quicksearch) ?
                                        <p className="font-medium text-xl lg:text-2xl mb-8 underline underline-offset-4">
                                            Résultats de la recherche: "{quicksearch}"
                                        </p>
                                    :
                                    null
                                }

                                {
                                    // the sad points should only be visible when no post is focused
                                    sadPoints && !postId?
                                    <div
                                        className="font-medium"
                                        dangerouslySetInnerHTML={{__html:sadPoints}}
                                    />
                                    :
                                    null
                                }
                            </div>
                            <div className="border-y-4 my-12 py-4 bg-slate-50 px-4">
                                <div className="mb-4">
                                    <p className="text-xl font-medium uppercase">
                                        {postId? post?.title:''}
                                    </p>
                                    <p className="text-lg font-medium text-gray-700">
                                        {postId? post?.subtitle:''}
                                    </p>
                                </div>
                                {
                                    postId && post?
                                    <div
                                        dangerouslySetInnerHTML={{__html:post.rawHtml||''}}
                                    />
                                    :
                                    null
                                }
                            </div>
                            <div className="min-h-[20vh] pt-4 pb-8 grid grid-cols-2 gap-6">
                                {blogArticles.map(item => (
                                    <BlogCard
                                    onClick={() =>onBlogCardClick(item)}    
                                    article={item}
                                    />
                                ))}
                            </div>
                            {
                            quicksearch?
                            null
                            :
                            <div className="flex gap-x-4">
                                <CustomButton
                                    title="Charger plus"
                                    className="px-3 flex-row-reverse"
                                    onClick={loadMore}
                                    disabled={!hasMore}
                                    icon={<BsChevronDoubleRight size={12}/>}
                                />
                            </div>
                            }
                            {/* <Chat 
                                previewMode
                            /> */}
                        </div>
                    </div>
                </div>
                <SidePanel
                    sideArticles={sideArticles}
                />
            </div>
        </div>
    )
};


export default SADElement;