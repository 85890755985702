import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { publications } from "../../assets/examples";
import { PublicationType, SADElementType } from "../../global/types";
import CustomButton from "../../components/CustomButton";
import { BiDotsHorizontal, BiExport, BiSearch } from "react-icons/bi";
import { collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../App';
import { DeleteConfirmation } from '../../components/Modals';
import { BsTrash } from 'react-icons/bs';


// const BeforeTable = () => {
//     return (
//         <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
//             <div className="sm:col-span-1">
//                 <label htmlFor="hs-as-table-product-review-search" className="sr-only">Search</label>
//                 <div className="relative">
//                     <input type="text" id="hs-as-table-product-review-search" name="hs-as-table-product-review-search" className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" placeholder="Search" />
//                     <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
//                         <BiSearch className="h-4 w-4 text-gray-400"/>
//                     </div>
//                 </div>
//             </div>
//             <CustomButton
//                 title="Nouvelle Publication"
//                 className="py-2.5"
//                 navigateTo="nouveau"
//             />
//             <div className="sm:col-span-2 md:grow">
//                 <div className="flex justify-end gap-x-2">
//                     <div className="hs-dropdown relative inline-block [--placement:bottom-right]">
//                         <button id="hs-as-table-table-export-dropdown" type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
//                             <BiExport className="h-4 w-4" />
//                             Export
//                         </button>
//                         <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-[12rem] z-10 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-as-table-table-export-dropdown">
//                             <div className="py-2 first:pt-0 last:pb-0">
//                                 <span className="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
//                                     Options
//                                 </span>
//                                 <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
//                                     <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                         <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
//                                         <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
//                                     </svg>
//                                     Copy
//                                 </a>
//                                 <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
//                                     <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                         <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
//                                         <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
//                                     </svg>
//                                     Print
//                                 </a>
//                             </div>
//                             <div className="py-2 first:pt-0 last:pb-0">
//                                 <span className="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
//                                     Download options
//                                 </span>
//                                 <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
//                                     <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                         <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
//                                         <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
//                                     </svg>
//                                     Excel
//                                 </a>
//                                 <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
//                                     <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                         <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
//                                     </svg>
//                                     .CSV
//                                 </a>
//                                 <a className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="#">
//                                     <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                         <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
//                                         <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
//                                     </svg>
//                                     .PDF
//                                 </a>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="hs-dropdown relative inline-block [--placement:bottom-right]" data-hs-dropdown-auto-close="inside">
//                         <button id="hs-as-table-table-filter-dropdown" type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
//                             <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                 <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
//                             </svg>
//                             Filter
//                             <span className="pl-2 text-xs font-semibold text-blue-600 border-l border-gray-200 dark:border-gray-700 dark:text-blue-500">
//                                 1
//                             </span>
//                         </button>
//                         <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-[12rem] z-10 bg-white shadow-md rounded-lg mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-as-table-table-filter-dropdown">
//                             <div className="divide-y divide-gray-200 dark:divide-gray-700">
//                                 <label htmlFor="hs-as-filters-dropdown-all" className="flex py-2.5 px-3">
//                                     <input type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-as-filters-dropdown-all" checked />
//                                     <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">All</span>
//                                 </label>
//                                 <label htmlFor="hs-as-filters-dropdown-published" className="flex py-2.5 px-3">
//                                     <input type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-as-filters-dropdown-published" />
//                                     <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">Published</span>
//                                 </label>
//                                 <label htmlFor="hs-as-filters-dropdown-pending" className="flex py-2.5 px-3">
//                                     <input type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-as-filters-dropdown-pending" />
//                                     <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">Pending</span>
//                                 </label>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     )
// }

const TableHeader = () => {
    return (
        <thead className="bg-gray-50 dark:bg-slate-800">
            <tr>
                <th scope="col" className="px-6 py-3 text-left">
                    <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Image
                        </span>
                    </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                    <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Article/Document
                        </span>
                    </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                    <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            DAS
                        </span>
                    </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                    <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Date
                        </span>
                    </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                    <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Catégorie
                        </span>
                    </div>
                </th>
                <th scope='col' className='px-6 py-3 w-min text-xs'>
                    X
                </th>
            </tr>
        </thead>
    )
}


const Row: React.FC<SADElementType & {onDeleteClick:(title:string, id:string) => void, navigate:any}> = ({ navigate, onDeleteClick, ...props }) => {
    
    return (
        <tr
            onClick={() => navigate('modifier?id='+props.id, {state:{content:props}})}
            className="hover:bg-gray-50 dark:bg-slate-900 dark:hover:bg-slate-800 cursor-pointer">
            <td className="h-px w-px whitespace-nowrap">
                <div
                    className="block h-full p-6">
                    <img
                        className="flex-shrink-0 rounded-md"
                        src={props.bannerImgUrl||"http://flag-cmr.org/pictures/actus/logo_flag.png"}
                        alt="Image Description"

                    />

                </div>
            </td>
            <td className="h-px w-px">
                <div className="block h-full p-6 grow">
                    {/* <img
                        className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                        src={props.reviewerImgUrl}
                        alt="Image Description"
                    /> */}
                    <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                        {props.title}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 min-w-[18rem]">
                <div className="block h-full p-6">
                    <span className="block text-sm text-gray-500">
                        {props.subtitle}
                    </span>
                </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
                <div className="block h-full p-6">
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                        {props.createdAt}
                    </span>
                </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
                <div className="block h-full p-6">
                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                        <svg className="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        {props.DAS}
                    </span>
                </div>
            </td>
            <td className='h-px w-px'>
                <div
                    onClick={(e) => {e.stopPropagation(); onDeleteClick(props.title, props.id as unknown as string)}}
                    className="h-min w-min p-3 hover:bg-slate-100 rounded-full cursor-pointer"
                >
                    <BsTrash size={18}/>
                </div>
            </td>
        </tr>
    )
}


const TableFooter = () => {
    return (
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
            <div className="max-w-sm space-y-3">
                <select className="py-2 px-3 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option selected>5</option>
                    <option>6</option>
                </select>
            </div>

            <div>
                <div className="inline-flex gap-x-2">
                    <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        Prev
                    </button>

                    <button type="button" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                        Next
                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

const Publications = () => {
    const [publications, setPublications] = useState<SADElementType[]>([]);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [loading, setLoading] = useState(false);
    const [searchTitle, setSearchTitle] = useState('');

    const navigate = useNavigate();

    const onDeleteMedia = async () => {
        if(loading || !deleteMedia?.id) return;
        setLoading(true);
        const dbDoc = doc(db, 'sad/'+deleteMedia.id);
        await deleteDoc(dbDoc);
        setPublications(publications.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setLoading(false);
    };


    const getAndSetSADArticles = async () => {
        let q = query(collection(db, 'sad'), orderBy('index', 'desc'));
        
        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as SADElementType);
        setPublications(docsData);
    };


    useEffect(() => {
        getAndSetSADArticles();
    }, []);
    return (
        <div className="max-w-6xl overflow-x-auto px-4 py-8 sm:px-6 lg:px-8 mx-auto my-[10vh] bg-white rounded-xl">
            <div>
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Gestion des Publications</h1>
                
                {/* BeforeTable */}
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
                    <div className="sm:col-span-1">
                        <label htmlFor="hs-as-table-product-review-search" className="sr-only">Search</label>
                        <div className="relative">
                            <input
                                type="text"
                                className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Rechercher"
                                value={searchTitle}
                                onChange={e => setSearchTitle(e.target.value)}
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                                <BiSearch className="h-4 w-4 text-gray-400"/>
                            </div>
                        </div>
                    </div>
                    <CustomButton
                        title="Nouvelle Publication"
                        className="py-2.5"
                        navigateTo="nouveau"
                    />
                </div>
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <TableHeader />

                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {
                            publications.filter(item => 
                                item.title.toLowerCase().includes(searchTitle.toLowerCase())
                                // item.title.includes(searchTitle) will return "true" if searchTitle="".
                            ).map(forum => 
                            <Row
                                {...forum}
                                navigate={navigate}
                                onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                            />
                        )
                        }
                    </tbody>
                </table>

                {/* <TableFooter/> */}
            </div>
            <DeleteConfirmation
                hideModal={() => setDeleteMedia(null)}
                message={'Voulez vous supprimer la publication "'+deleteMedia?.title+'" ?'}
                title='Supprimer la publication'
                showModal={deleteMedia?.id?true:false}
                confirmModal={onDeleteMedia}
            />
        </div>        
    )
};

export default Publications;