import Chat from "../Chat"
import { publications } from "../../assets/examples";
import { ForumElementType } from "../../global/types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../App";


const ForumIntroduction = ({publication}:{publication:ForumElementType}) => {
    return (
        <div className="relative overflow-hidden">
            <div className="max-w-[85rem] px-4 pb-12 sm:px-6 lg:px-8 lg:pb-16 mx-auto">

                <div className="order lg:grid lg:grid-cols-1 lg:gap-8 lg:items-center">
                    {/* <div className="hidden lg:block lg:col-span-2">
                        <img
                            className="rounded-xl max-h-80 mx-auto"
                            src={publications[1].imageUrl}
                            alt="Image Description"
                        />
                    </div> */}

                    <div className="">
                        <blockquote>
                            <p className="text-xl font-medium text-gray-800 lg:text-2xl lg:leading-normal dark:text-gray-200 mb-4">
                                {publication.title}
                            </p>
                            <p className="font-medium text-gray-600">
                                {publication.description}
                            </p>
                            {/* <footer className="mt-6">
                                <div className="flex items-center">
                                    <div className="lg:hidden flex-shrink-0">
                                        <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1671726203390-cdc4354ee2eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" alt="Image Description"/>
                                    </div>
                                    <div className="ml-4 lg:ml-0">
                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                            Nicole Grazioso
                                        </p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">
                                            Head of Finance
                                        </p>
                                    </div>
                                </div>
                            </footer> */}
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    )
};

const Forum = () => {
    const [forumData, setForumData] = useState<ForumElementType|null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const forumId = searchParams.get('id');
    
    const getAndSetForumData = async () => {
        const forumRef = doc(db, 'forums/'+forumId);
        const forumSnapshot = await getDoc(forumRef);
        
        const forumData = {...forumSnapshot.data(), id:forumSnapshot.id} as unknown as ForumElementType;
        setForumData(forumData)
    }

    useEffect(() => {
        getAndSetForumData();
    }, [])
    return (
        <div className="max-w-6xl mx-2 px-2 py-8 sm:px-3 lg:px-6 xl:px-8 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            {/* <div>
                <p>Forum sur {publication.title}</p>
                <p>Forum sur {publication.subtitle}</p>
                <p>Forum sur {publication.description}</p>
            </div> */}
            {
                forumData?
                <ForumIntroduction publication={forumData}/>
                :
                null
            }
            <Chat />
        </div>
    )
};

export default Forum;