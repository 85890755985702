import { useEffect, useState } from 'react';
import { BsGear, BsTrash } from "react-icons/bs";
import CustomButton from "../../components/CustomButton";
import { collection, deleteDoc, doc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../App';
import { VideoElementType } from '../../global/types';
import { DeleteConfirmation } from '../../components/Modals';


const VideoItem: React.FC<VideoElementType& {showSettings?:boolean, onDeleteClick:(title:string, id:string) => void}>
    = ({ videoUrl, title, posterUrl, category, index, subtitle, id, onDeleteClick, showSettings }) => {
    return (
        <div className="relative">
            <div className="mb-2 rounded">
                <video
                    poster={posterUrl}
                    controls
                    className="rounded mx-auto"
                    src={videoUrl}
                />
            </div>
            <div className="bg-primary px-4 py-1 rounded-tl-[20px] leading-tight">
                <span className="text-white font-medium">{title}</span>
                <br />
                <span className="text-white text-sm">{subtitle}</span>
            </div>
            {
                showSettings?
                <div className="flex gap-x-2">
                    <CustomButton
                        title=""
                        navigateTo={'modifier?id='+id}
                        state={{content:{videoUrl, title, posterUrl, category, index, subtitle, id}}}
                        icon={<BsGear size={16}/>}
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                    <CustomButton
                        title=""
                        icon={<BsTrash size={16}/>}
                        onClick={() => onDeleteClick(title, id as unknown as string)}
                        className="w-min h-min px-2 py-1 bg-transparent border-2 border-primary text-primary hover:text-white"
                    />
                </div>
                :
                null
            }
        </div>
    )
}

type VideosMediathequePropsType = {
    showSettings?:boolean;
};

const VideosMediatheque:React.FC<VideosMediathequePropsType> = ({showSettings}) => {
    const [videos, setVideos] = useState<VideoElementType[]>([]);
    const [deleteMedia, setDeleteMedia] = useState<null|{title:string, id:string}>(null);
    const [loading, setLoading] = useState(false);

    const onDeleteMedia = async () => {
        if(loading || !deleteMedia?.id) return;
        setLoading(true);
        const dbDoc = doc(db, 'mediatheque/'+deleteMedia.id);
        await deleteDoc(dbDoc);
        setVideos(videos.filter(item => (item.id as unknown as string) != deleteMedia.id));
        setDeleteMedia(null);

        setLoading(false);
    };


    const getAndSetMediathequeVideos = async() => {
        const q = query(collection(db, 'mediatheque'),orderBy('index', 'desc'), where('category', '==', 'videos'));
        const videosSnapshot = await getDocs(q);
        const dbVideos = videosSnapshot.docs.map(video => ({...video.data(), id:video.id}) as unknown as VideoElementType);
        
        setVideos(dbVideos);
    }
    useEffect(() => {
        getAndSetMediathequeVideos()
    }, []);
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Vidéos</h1>
                {showSettings?<CustomButton
                    title="Ajouter une Vidéo"
                    className="py-2.5"
                    navigateTo="nouveau"
                />:null}
            </div>
            <div className="grid lg:grid-cols-2 lg:gap-x-4 gap-y-6">
                {videos.map(item =>
                <VideoItem
                    {...item}
                    onDeleteClick={(title, id) => setDeleteMedia({title, id})}
                    showSettings={showSettings}
                    />
                )}
            </div>
            <DeleteConfirmation
                hideModal={() => setDeleteMedia(null)}
                message={'Voulez vous supprimer la vidéo "'+deleteMedia?.title+'" ?'}
                title='Supprimer la vidéo'
                showModal={deleteMedia?.id?true:false}
                confirmModal={onDeleteMedia}
            />
        </div>
    )
};


export default VideosMediatheque;