import { AdminMenuItem, MenuItemType } from "./index";

const menuItems:MenuItemType[] = [
    {
        title:"Domaines d'Action Stratégique",
        linkTo:"das",
        description:"Légalité, Transparence, Renforcement des capacités, Développement organisationnel",
    },
    {
        title:"FLAG Lex",
        linkTo:"lex",
        description:"Agriculture, Forêts, Mines, Faune",
    },
    {
        title: "Mediathèque Photos",
        linkTo: "mediatheque-photos",
        description: "Ajoutez ou supprimez des photos de votre médiathèque"
    },
    {
        title: "Mediathèque Vidéos",
        linkTo: "mediatheque-videos ",
        description: "Ajoutez ou supprimez des vidéos de la médiathèque"
    },
    {
        title: "Mediatheque Documents",
        linkTo: "mediatheque-documents",
        description: "Ajoutez ou supprimez des documents de la médiathèque"
    },
    {
        title: "Pressbook",
        linkTo: "pressbook",
        description: "Ajoutez ou supprimez des références vers des articles de presse"
    },
    {
        title: "Forums",
        linkTo: "forums",
        description: "Gestion des forums"
    },
]

const ManageContents = () => {
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Gestion des Publications</h1>
                <span className="text-xl text-gray-600">
                    Ajouter/Supprimer des articlesdans les catégories clées du site
                </span>
            </div>
            {
                menuItems.map(item => (
                    <AdminMenuItem {...item} />
                ))
            }
        </div>
    )
};

export default ManageContents;