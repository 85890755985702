import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import WhoAreWe from './pages/About/WhoAreWe';
import Values from './pages/About/Values';
import SkillsReinforcement from './pages/Programs/SkillsReinforcement';
import TheoryOfChange from './pages/About/TheoryOfChange';
import InterventionZones from './pages/About/InterventionZones';
import Organigram from './pages/About/Organigram';
import Team from './pages/About/Team';
import OrganizationalDevelopment from './pages/Programs/OrganizationalDevelopment';
import Legality from './pages/Programs/Legality';
import Transparence from './pages/Programs/Transparence';
import Forums from './pages/Forums';
import Forum from './pages/Forum';
import Admin from './pages/Admin';
import UsefulLinks from './pages/Admin/UsefulLinks';
import PhotosMediatheque from './pages/Mediatheque/Photos';
import VideosMediatheque from './pages/Mediatheque/Videos';
import DocumentsMediatheque from './pages/Mediatheque/Documents';
import Pressbook from './pages/Mediatheque/Pressbook';
import NewsletterEmails from './pages/Admin/NewsletterEmails';
import ManageContents from './pages/Admin/ManageContents';
import Publications from './pages/Admin/Publications';
import Lex from './pages/Lex';
import InformationQualityAndAvailability from './pages/SAD/InformationQualityAndAvailability';
import CommunitySupport from './pages/SAD/CommunitySupport';
import EnvironmentalMonitoring from './pages/SAD/EnvironmentalMonitoring';
import ContinuousTraining from './pages/SAD/ContinuousTraining';
import SupervisionOfTheCraftSector from './pages/SAD/SupervisionOfTheCraftSector';
import Users from './pages/Admin/Users';
import { CreateAlbum, CreateForum, PublishDAS,
    PublishDocument,
    PublishLex,
    PublishPhoto,
    PublishPressbook,
    PublishVideo
} from './pages/Admin/Publish';
import SADElement from './pages/SAD/SADElement';
import { auth } from './App';
import { AuthContext } from './global/context';
import { IMWILD, NORAD, OTP_OICameroon, RALFF, USINL } from './pages/OurActions';


const AppRoutes = () => {
    const authContext = useContext(AuthContext);

    return (
        <Routes>
            <Route path='/' element={<Home/>} />
            {
            authContext?.isAdmin?
            <Route path='/admin' >
                <Route path='' element={<Admin/>}/>
                
                <Route path='liens-utiles' element={<UsefulLinks/>} />

                <Route path='emails-de-newsletter' element={<NewsletterEmails/>} />
                <Route path='utilisateurs' element={<Users/>} />
                <Route path='gestion-des-contenus'>
                    <Route path='' element={<ManageContents/>} />
                    <Route path='mediatheque-documents' element={<DocumentsMediatheque showSettings/>} />
                    <Route path='mediatheque-photos' element={<PhotosMediatheque showSettings/>} />
                    <Route path='mediatheque-videos' element={<VideosMediatheque showSettings />} />
                    <Route path='pressbook' element={<Pressbook showSettings />} />
                    <Route path='forums' element={<Forums showSettings />} />

                    <Route path='das' element={<Publications/>} />
                    <Route path='lex' element={<Lex showSettings/>} />
                    <Route path='lex/nouveau' element={<PublishLex/>} />
                    <Route path='das/nouveau' element={<PublishDAS/>} />
                    <Route path='mediatheque-photos/nouveau' element={<PublishPhoto/>} />
                    <Route path='mediatheque-videos/nouveau' element={<PublishVideo/>} />
                    <Route path='mediatheque-photos/nouvel-album' element={<CreateAlbum/>} />
                    <Route path='mediatheque-documents/nouveau' element={<PublishDocument/>} />
                    <Route path='pressbook/nouveau' element={<PublishPressbook />} />
                    <Route path='forums/nouveau' element={<CreateForum />} />

                    <Route path='lex/modifier' element={<PublishLex/>} />
                    <Route path='das/modifier' element={<PublishDAS/>} />
                    <Route path='mediatheque-photos/modifier' element={<PublishPhoto/>} />
                    <Route path='mediatheque-videos/modifier' element={<PublishVideo/>} />
                    <Route path='mediatheque-documents/modifier' element={<PublishDocument/>} />
                    <Route path='pressbook/modifier' element={<PublishPressbook />} />
                    <Route path='forums/modifier' element={<CreateForum />} />
                </Route>
            </Route>
            :
            null
            }
            <Route path='/a-propos'>
                <Route path='qui-sommes-nous' element={<WhoAreWe/>} />
                <Route path='valeurs' element={<Values/>} />
                <Route path='theorie-de-changement' element={<TheoryOfChange/>} />
                <Route path='zones-dintervention' element={<InterventionZones/>} />
                <Route path='organigramme' element={<Organigram/>} />
                <Route path='equipe' element={<Team/>} />
            </Route>
            <Route path='/programmes'>
                <Route path='legalite' element={<Legality/>} />
                <Route path='transparence' element={<Transparence/>} />
                <Route path='renforcement-des-capacites' element={<SkillsReinforcement/>} />
                <Route path='developpement-organisationnel' element={<OrganizationalDevelopment/>} />
            </Route>
            <Route path='/das'>
                <Route path='' element={<SADElement/>} />
                <Route path='accompagnement-communautaire' element={<CommunitySupport/>} />
                <Route path='surveillance-environnementale' element={<EnvironmentalMonitoring/>} />
                <Route path='formation-continue' element={<ContinuousTraining/>} />
                <Route path='qualite-et-disponibilite-de-linfo' element={<InformationQualityAndAvailability/>} />
                <Route path='supervision-du-secteur-artisanal' element={<SupervisionOfTheCraftSector/>} />
            </Route>
            <Route path="/nos-actions">
                <Route path='otp-oi-cameroun' element={<OTP_OICameroon/>} />
                <Route path='norad' element={<NORAD/>} />
                <Route path='ralff' element={<RALFF/>} />
                <Route path='imwild' element={<IMWILD/>} />
                <Route path='usinl' element={<USINL/>} />
                {/* <Route path='nicfi' element={<NICFI/>} /> */}
            </Route>
            <Route path='/lex'>
                <Route path='' element={<Lex/>} />
            </Route>
            <Route path='/mediatheque'>
                <Route path='photos' element={<PhotosMediatheque/>} />
                <Route path='videos' element={<VideosMediatheque/>} />
                {/* Mediatheque documents == publications */}
                <Route path='documents' element={<DocumentsMediatheque/>} />
                <Route path='pressbook' element={<Pressbook/>} />
            </Route>
            <Route path='/contact' element={<Contact/>} />
            <Route path='/forums' element={<Forums/>} />
            <Route path='/forums/forum' element={<Forum/>} />
        </Routes>
    )
};

export default AppRoutes;