import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { FaEnvelope, FaUser, FaRegCalendarAlt } from 'react-icons/fa';
import { db } from '../../App';

interface User {
    id: number;
    name: string;
    email: string;
    newsletterEnabled: boolean;
    createdAt: string;
}

interface UserTableRowProps {
    user: {
        name: string;
        email: string;
        newsletterEnabled: boolean;
        creationDate: string;
    };
}

const UserTableRow: React.FC<UserTableRowProps> = ({ user }) => {
    return (
        <tr className="transition-transform transform hover:scale-105">
            <td className="py-3">
                <div className="flex items-center space-x-2">
                    <div className="w-10 h-10 bg-blue-500 text-white rounded-full flex items-center justify-center text-lg font-semibold">
                        {user.name[0].toUpperCase()}
                    </div>
                    <div className="font-semibold">{user.name}</div>
                </div>
            </td>
            <td className="py-3">
                <div className="flex items-center space-x-2">
                    <FaEnvelope />
                    <span>{user.email}</span>
                </div>
            </td>
            <td className="py-3">
                <div className="flex items-center space-x-2">
                    {user.newsletterEnabled ? (
                        <FaUser className="text-green-500" />
                    ) : (
                        <FaUser className="text-red-500" />
                    )}
                    <span>{user.newsletterEnabled ? 'Enabled' : 'Disabled'}</span>
                </div>
            </td>
            <td className="py-3">
                <div className="flex items-center space-x-2">
                    <FaRegCalendarAlt />
                    <span>{user.creationDate}</span>
                </div>
            </td>
        </tr>
    );
};


const UserTable: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    const getAndSetNewsletterUsers = async () => {
        const newsletterCollectionRef = collection(db, 'newsletter');

        const newsletterSnapshot = await getDocs(query(newsletterCollectionRef, orderBy('index', 'desc')));
        const newsletterEmails = newsletterSnapshot.docs.map(doc =>
            ({ ...doc.data(), id: doc.id, name: "" }) as unknown as User
        );
        setUsers(newsletterEmails);
    }

    useEffect(() => {
        getAndSetNewsletterUsers();
    }, []);
    return (
        <div className="max-w-6xl overflow-x-auto px-4 py-8 sm:px-6 lg:px-8 mx-auto my-[10vh] bg-white rounded-xl">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full text-left text-sm font-light">
                            <thead
                                className="border-b bg-white font-medium dark:border-neutral-500 dark:bg-neutral-600">
                                <tr>
                                    <th scope="col" className="px-6 py-4">#</th>
                                    <th scope="col" className="px-6 py-4">Nom</th>
                                    <th scope="col" className="px-6 py-4">Email</th>
                                    <th scope="col" className="px-6 py-4">Date de creation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((user, index) => (

                                        <tr
                                            className={"border-b " + (index % 2 ?
                                                "bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700"
                                                :
                                                "bg-white dark:border-neutral-500 dark:bg-neutral-600")
                                            }
                                        >
                                            <td className="whitespace-nowrap px-6 py-4 font-medium">{user.id}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{user.name}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{user.email}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{user.createdAt}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserTable;
