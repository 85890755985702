import { sideArticles } from "../../assets/examples";
import Blog from "../Blog"


const content = `
<div>
    <strong>FLAG</strong> considère que la mutualisation des efforts des différents acteurs pour lutter
    contre l’irresponsabilité, l’injustice, l’individualisme, la corruption, l’inefficacité et l’ignorance
    crée un cadre favorable pour le respect des lois et la transparence assurant ainsi la bonne gouvernance
    dans la valorisation des ressources naturelles et la durabilité du développement social, économique
    et écologique.
</div>
`


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];


const TheoryOfChange = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={content}
            programTitle="THÉORIE DE CHANGEMENT"
            bannerImg={require('../../assets/about/theorie_du_changement.png')}
            sideArticles={sideArticles}
        />
    )
};

export default TheoryOfChange;