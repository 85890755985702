import { PublicationType } from "../global/types";
import { SideArticleType } from "../pages/Blog";


export const publications: PublicationType[] = [
    {
        pid:'1',
        title:"CEAS GRNE",
        subtitle:"Centre d’Excellence et d’Application pour le Suivi de la Gestion de la Ressources Naturelles ",
        description:"Capable de fournir des solutions adéquates aux problématiques qui interpellent les actions de suivi. Il met en symbiose l’innovation, la coordination et la complémentarité en matière de surveillance afin de réduire à leur plus simple expression les mauvaises pratiques qui minent le secteur de la gestion des ressources naturelles.",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Plaquette_Finale.pdf",
        imageUrl:"http://flag-cmr.org/pictures/actus/ceas-grne.png",
        type:"document",
        createdAt:'12 May 2022',
        rawHtml:"<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa reiciendis laborum voluptatum iusto repellat nesciunt praesentium error, porro quae quasi rem consectetur veniam ducimus quisquam cumque saepe eum tempora debitis!</p>"
    },
    {
        pid:'2',
        title:"Le CEAS-GRNE accueille sa 2e cuvée d'apprenants",
        subtitle:"Les cours en ligne ont débuté le 13 juin 2023",
        description:"Une initiative de FLAG mise en oeuvre grâce au financement de l’Agence Norvégienne pour la Coopération au Développement (NORAD) dans le cadre du projet « Technologies innovantes pour la lutte contre l’exploitation forestière illégale du bois : mise à l’échelle des plateformes d’identification et de transparence du bois, Composante du bassin du Congo » avec l’appui technique de World Resources Institute (WRI).",
        articleUrl:"http://flag-cmr.org/programme-renforcement-des-capacites.php?dhqsvfreq=67",
        type:"article",
        imageUrl:"http://flag-cmr.org/pictures/actus/Capture%20d'%C3%A9cran%202023-06-13%20150754.png",
        createdAt:'12 March 2022',
        rawHtml:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, blanditiis veritatis. Veniam sunt voluptates porro ipsum suscipit? Dolor itaque ducimus nemo eius voluptate qui est aliquid! Ab quae id odit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quod rerum qui error, ipsa deleniti veritatis iusto iste doloremque corporis accusamus ullam saepe libero voluptatibus ex natus distinctio explicabo. Praesentium!'
    },
    {
        pid:'3',
        title:'CEAS GRNE',
        subtitle:"Appel d'offre pour la sélection d'un prestataire en charge de la restauration des apprenants",
        description:"La prestation à la concurrence est ouverte à égalité de conditions à toutes les sociétés ou prestataires installées au Cameroun, spécialisés dans les domaine de la restauration hotelière",
        articleUrl:"http://flag-cmr.org/programme-developpement-organisationnel.php?dhqsvfreq=66",
        type:'article',
        imageUrl:"http://flag-cmr.org/pictures/actus/appel%20%C3%A0%20la%20candidature_001.jpg",
        createdAt:'06 June 2023'
    },
    {
        pid:'4',
        title:"",
        subtitle:"2e édition de Regards sur le Contentieux des ressources et de l'environnement au Cameroun",
        description:"Sommier des infractions forestières et fauniques au Cameroun : Poursuites judiciaires et arrangements transactionnels",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Sommier%20sur%20les%20Infractions%202019-2020%20Bon.pdf",
        imageUrl:"http://flag-cmr.org/pictures/actus/web_sommier_2019.jpg",
        type:"document",
        createdAt:'1st January 2024'
    },
    // {
    //    pid:'5',
    //     title:"FLAG recrute",
    //     subtitle:"Appel à manifestation d'intérêt",
    //     description:"Appel à manifestation d'intérêt pour le recrutement de Praticiens OI CEAS Promotion 2022.pdf. Bien vouloir télécharger le document ci-dessous pour plus d'informaions.",
    //     fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20manifestation%20d'int%C3%A9r%C3%AAt_Praticiens%20OI_CEAS_Promotion%202022_.pdf",
    //     type:'document',
    //     imageUrl:"http://flag-cmr.org/pictures/actus/logo_flag.png",
    // },
    // {
    //     pid:'6',
    //     title:"FLAG recrute",
    //     subtitle:"Appel à candidature pour le recrutement d'un.e Stagiaire Juriste",
    //     description:"Field Legality Advisory Group recrute un.e stagiaire juriste. Pour en savoir davantage, bien vouloir télécharger les Termes de référence ci-après.",
    //     fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20candidatures_Stagiaire_Juriste.pdf",
    //     imageUrl:"http://flag-cmr.org/pictures/actus/logo_flag.png",
    //     type:'document'
    // },
    // {
    //     pid:'7',
    //     title:"FLAG recrute",
    //     subtitle:`Leader National en charge de la conduite du processus de rédaction de l'"Index 2022 du suivi environmental par les OSC dans le bassin du Congo" au Cameroun.`,
    //     description:"Vous êtes intéressé.es ? Alors postulez au plus tard le 10 mars 2023 selon les modalités contenues dans l'appel à manifestation d'intérêt ci-dessous.",
    //     fileUrl:"http://flag-cmr.org/documents/publications/autres/Appel%20%C3%A0%20manifestation%20d'int%C3%A9r%C3%AAt_LN_Index%20suivi%20environnemental_Cameroun_VF.pdf",
    //     imageUrl:"http://flag-cmr.org/pictures/actus/logo_flag.png",
    //     type:'document'
    // },
    {
        pid:'8',
        title:"Renforcement des capacités",
        subtitle:"Actualisation des connaissances sur les procédures contentieuses en matière forestière au bénéfice des personnels des services de contrôle et de la ce",
        description:"Deux ateliers ont été organisés dans le cadre de la mise en oeuvre du projet « Suivi indépendant de l’application des lois forestières au Cameroun : impliquer les communautés locales et la justice pour dénoncer et sanctionner les pratiques illégales », mis en œuvre avec l’appui de Conservation Justice, Field Legality Advisory Group (FLAG) à Yaoundé et Ebolowa.",
        articleUrl:"http://flag-cmr.org/programme-legalite.php?dhqsvfreq=61",
        imageUrl:"http://flag-cmr.org/pictures/actus/dcf41d6d-443d-46aa-8a5f-4c82667913d1%20-%20Copie.jfif",
        type:'article',
        createdAt:'19 April 2021',
        rawHtml:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum a facere fugiat esse quibusdam perferendis repellendus similique nihil, consequuntur eveniet voluptas, amet reprehenderit saepe velit ducimus debitis, vitae accusantium laudantium. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni et soluta nostrum repudiandae quae. Quis earum corrupti sed esse ullam eius, cum aspernatur consequatur nihil. Unde nesciunt amet in vel? Lorem, ipsum dolor sit amet consectetur adipisicing elit. A, repellat, architecto soluta nihil sequi sapiente fuga laboriosam odio unde, ab consectetur vero? Iusto nisi voluptatem sunt quibusdam quae assumenda deserunt!"
    },
    {
        pid:'9',
        title:"Suivi environnemental",
        subtitle:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        description:"Ce rapport publié présente la situation du suivi des ressources forestières dans ces deux pays pour l’année 2021, en analysant non seulement le travail mené sur le terrain par les OSC, mais aussi l’influence de l’environnement sur leurs actions à travers dix critères regroupés dans deux piliers, en l’occurrence, l’« Opérationnel » et l’ « Environnement de travail ».",
        fileUrl:"http://flag-cmr.org/documents/publications/autres/Index%20du%20suivi%20Cameroun-RDC%202022.pdf",
        type:"document",
        imageUrl:"http://flag-cmr.org/pictures/actus/Index%202021%20.png",
        createdAt:'7 May 2022',
        rawHtml:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. v  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde quod quibusdam dolore delectus recusandae similique neque ipsa, aliquid laborum illo, perspiciatis tempore iure expedita? Odit aliquam architecto error quibusdam dolor?  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem sed culpa nulla eius iure, laborum quis sequi laudantium sit asperiores consequatur magnam amet ipsum delectus ut animi adipisci recusandae. Aut! Harum, sint quibusdam corporis nostrum perferendis animi ullam voluptates repellendus.  <br/>  <br/> Tenetur sint molestiae, necessitatibus doloribus temporibus dolor iusto quas consequatur enim porro. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad quibusdam deleniti dolore atque sed laborum nesciunt accusantium velit quidem molestias voluptas explicabo quae, et nam, unde voluptates suscipit nobis! Cupiditate? Lorem ipsum dolor sit amet consectetur adipisicing elit.  <br/> Sit laborum, voluptate soluta corporis blanditiis odio voluptatibus. Aliquid ut repudiandae error! Voluptatem, itaque id. Neque corporis a, inventore incidunt eos odit? Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas voluptatum cupiditate expedita fuga laboriosam molestias suscipit accusamus non autem ut in hic reiciendis, <strong>eos quibusdam omnis rerum pariatur</strong> veniam assumenda!"
    }
];


export  const sideArticles=publications