import { useState } from 'react';
import { BiEdit, BiEditAlt } from "react-icons/bi";
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';


type LinkItem = {
    title:string;
    linkTo:string;
    id:string
};

const usefulLinks:LinkItem[] = [
    {
        id:'1',
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        id:'2',
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        id:'3',
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        id:'4',
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        id:'5',
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        id:'6',
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        id:'7',
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        id:'8',
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        id:'9',
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];


const UsefulLink:React.FC<LinkItem> = ({...props}) => {
    const [link, setLink] = useState(props);
    const [editing, setEditing] = useState(false);
    return (
        <div className="border-[2px] border-gray-300 rounded-lg my-2">
            <div className='flex flex-1 justify-between '>
                <p className="my-1 py-0.5 mx-3">{link.title}</p>
                <button
                    className="px-1 mx-2 group"
                    onClick={() => setEditing(true)}
                >
                    <BiEditAlt
                        size={24}
                        className="text-gray-400 group-hover:text-gray-900 cursor-pointer self-center"
                    />
                </button>
            </div>
            {
                editing?
            <div className='my-2 mx-3 gap-x-4 gap-y-2 grid md:grid-cols-2'>
                <CustomInput
                    placeholder='Titre du lien'
                    className='bg-gray-50 col-span-1'
                    value={link.title}
                    onChange={e => setLink(link => ({...link, title:e.target.value}))}
                />
                <CustomInput
                    placeholder='Addresse url'
                    className='bg-gray-50 col-span-1'
                    value={link.linkTo}
                    onChange={e => setLink(link => ({...link, linkTo:e.target.value}))}
                />
                <div className='col-span-1 flex gap-x-3 opacity-90'>
                    <CustomButton
                        title='Enregistrer'
                        className='px-2.5'
                    />
                    <CustomButton
                        title='Annuler'
                        variant='btnWarning'
                        className='px-2.5'
                        onClick={() => setEditing(false)}
                    />
                    <CustomButton
                        title='Supprimer'
                        variant='btnDanger'
                        className='px-2.5'
                    />
                </div>
            </div>
            :
            null
            }
        </div>
    )
};


const UsefulLinks = () => {
    return (
        <div className="max-w-6xl px-4 py-8 sm:px-6 lg:px-8 mx-2 lg:mx-auto my-6 lg:my-[10vh] bg-white rounded-xl">
            <div className="mb-8 ml-2">
                <h1 className="text-black text-2xl lg:text-3xl mb-2 font-medium" >Liens Utiles</h1>
            </div>
            {
                usefulLinks.map(item => (
                    <UsefulLink {...item}/>
                ))
            }
        </div>
    )
};

export default UsefulLinks;