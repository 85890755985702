import { sideArticles } from "../../assets/examples";
import Blog from "../Blog"


const content = `
<p className="text-gray-800 dark:text-gray-200">
L'exploitation clandestine des forêts, la coupe illégale et le commerce du bois
abattu illégalement sont des phénomènes très répandus dans grand nombre de pays en
développement, le Cameroun y compris. Bien que l'étendue des activités forestières
illicites soit difficile à quantifier, les pertes liées à ces phénomènes se
chiffrent en terme de milliards FCFA. De ce qui précède, l’observation indépendante (OI)
qui consiste en l’identification présumée des infractions illégales en forêt, est donc une
activité déterminante pour l‘application de la loi et la bonne gouvernance dans ce secteur.
Et c’est dans le cadre du projet « Voix des Citoyens pour le Changement : Observation
forestière dans le bassin du Congo (Projet CV4C), qu’un atelier de formation aux techniques
d’investigation et de suivi des circuits du bois du Cameroun a été organisé du 6 au 9 février
dernier à Kribi, département de l‘Océan, région du Sud.
</p>
<br />
<br />
<p className="text-gray-800">
L’objectif de cette rencontre facilitée par Field Legality Advisory Group (FLAG)
qui œuvre pour la transparence et la légalité dans la gestion des ressources naturelles,
était de permettre à la vingtaine de participants issus des OSC nationales, de « maitriser
l’enchaînement des différents mouvements des bois de manière à réaliser des investigations
ciblées depuis le lieu de l’abattage jusqu’au lieu de la commercialisation », dixit Horline
Njike, secrétaire générale de FLAG. Pour y parvenir, des modules de formation proposés aux
participants tournaient autour de la chaine de production, circuit de transport et de
commercialisation d’une part, et les investigations liées à la criminalité forestière et la
gestion des risques, entre autres. A terme, les participants sont désormais à mesure d’évaluer
les besoins en informations nécessaires pour mener et suivre le bois de la production à l’exportation.
Même si les techniques proposées par les facilitateurs ne sont pas exhaustives. Un résultat greffé à
leur capacité à pourvoir désormais conduire une investigation approfondie sur un élément de la chaine
ou sur tout le circuit de l’attribution à l’export.
</p>
<br />
<p className="text-gray-800">
Il n’est pas superflu de rappeler que l’Observation Indépendante est d’une importance capitale
dans l’application de la loi et incidemment l’amélioration de la gouvernance forestière. Jusqu’ici,
les activités d’observation indépendante se sont faites en forêt et il ressort des différentes études
et analyses faites auprès des cibles et des rapports d’OI que le lien entre les activités observées
en forêt et la destination du bois est souvent difficile à établir.
</p>
`


const usefulLinks:{title:string, linkTo:string}[] = [
    {
        title:"Publication de la 2ème édition de l’Index du suivi environnemental par les OSC dans le Bassin du Congo, Cas du Cameroun et de la RDC",
        linkTo:"#"
    },
    {
        title:"Des entreprises camerounaises tardent à adhérer à l'OTP",
        linkTo:"#"
    },
    {
        title:"Les retombées du projet OTP, quinze mois après sa mise en oeuvre au Cameroun",
        linkTo:"#"
    },
    {
        title:"L'ONG FLAG achève la promotion du portail Open Timber au Cameroun",
        linkTo:"#"
    },
    {
        title:"Logging practices : 15 companies adhere new legality initiatives",
        linkTo:"#"
    },
    {
        title:"La plateforme Africaine d'Observation Indépendante valide ses documents directeurs à Kribi au Cameroun",
        linkTo:"#"
    },
    {
        title:"La page OTP Cameroun opérationnelle  |  Une plateforme Web disponible pour plus de transparence dans le secteur forestier au Cameroun",
        linkTo:"#"
    },
    {
        title:"Les exploitants privés nationaux discutent des documents à publier dans l'OTP",
        linkTo:"#"
    },
    {
        title:"L'open Timber Portal se déploie au",
        linkTo:"#"
    }
];

const SkillsReinforcement = () => {
    return (
        <Blog
            usefulLinks={usefulLinks}
            // tags={["Plan", "Web development", "Free", "Team"]}
            articleContent={""}
            // title="La société civile en quête de nouvelles techniques d’investigation"
            // bannerImgUrl="http://flag-cmr.org/pictures/actus/IMG_20996.JPG"
            // bannerImgCaption="Observation Indépendante, Du 6 au 9 février 2018 0 Kribi"
            programTitle="PROGRAMME RENFORCEMENT DES CAPACITÉS"
            programPoints={[
                "Accroître l’efficacité des parties prenantes au Suivi de la Gestion des Ressources Naturelles (SGRN) ;",
                "Formation théorique et pratique des OSC au SGRN ;",
                "Formation à la carte ;",
                "Accompagnement des OSC formées ;",
                "Centre de ressources pour les pratiques du SGRN."
            ]}
            // sideArticles={sideArticles}
        />
    )
};

export default SkillsReinforcement;