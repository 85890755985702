import { useContext, useEffect, useState } from 'react';
import {
    AiFillFacebook,
    AiFillGoogleCircle,
    AiFillLinkedin,
    AiFillTwitterCircle
} from 'react-icons/ai';

import {
    MdContactMail,
    MdEmail,
    MdLocalPhone,
    MdOutlineShareLocation,
    MdPerson,
} from 'react-icons/md';
import CustomButton from './CustomButton';
import { auth } from '../App';
import { SignIn } from './Modals';
import { AuthContext } from '../global/context';


const Footer = () => {
    const [signInVisible, setSignInVisible] = useState(false);
    const authContext = useContext(AuthContext);

    useEffect(()=> {
        return () => setSignInVisible(false);
    }, []);
    return (
        <footer>
            <div
                style={{backgroundImage: `url(${require('../assets/footer_background.png')})`, backgroundSize:'100% 100%'}}
                className="max-w-[85rem] pt-28 lg:pt-16 pb-2 lg:pb-8 sm:px-6 lg:px-8 mx-auto text-white"
            >
                <div className="grid grid-cols-1 md:grid-cols-3 items-center lg:gap-5 text-center text-sm">
                    <div>
                        <a
                            className="flex-none text-lg font-semibold text-gray-300"
                            href="#"
                            aria-label="Brand"
                        >
                            Addresse
                        </a>
                        <div className='px-4 mt-4 text-xs grid grid-cols-2'>
                            <div className='grid grid-cols-8 my-3 col-span-2'>
                                <MdOutlineShareLocation
                                    size={28}
                                    className='mx-2 col-span-1'
                                />
                                <p className='text-justify col-span-7'>
                                    Mfandena, Rue Tsoungui Oloa (Titi garage)
                                    Immeuble La Grace Divine, 2e étage
                                    BP : 4267 Yaoundé - Cameroun
                                </p>
                            </div>
                            <div className='flex items-center lg:my-3 lg:col-span-2'>
                                <MdLocalPhone
                                    size={22}
                                    className='mx-2'
                                />
                                <p className='text-justify'>
                                    {/* +237 243 001 567 - 222 218 846 - 222 210 036 */}
                                    +237 620986124
                                </p>
                            </div>
                            <div className='flex items-center lg:my-3 lg:col-span-2'>
                                <MdEmail
                                    size={22}
                                    className='mx-2'
                                />
                                <p className='text-justify'>
                                    {/* flagorganisation@gmail.com */}
                                    mailflag@flag-cm.org
                                </p>
                            </div>
                        </div>
                    </div>
                
                    <div
                        className="flex lg:block text-center my-4 items-center justify-center pl-4"
                    >
                        <div
                            className="relative last-of-type:before:hidden before:absolute before:top-1/2 before:right-3 before:-translate-y-1/2 dark:before:text-gray-600"
                        >
                            <div className='h-16 w-28 aspect-video lg:w-32  lg:h-28 lg:mx-auto'>
                                <img
                                    className=''
                                    src={require('../assets/logo_flag.png')}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='mx-3 lg:mx-8 my-3'>                            
                            Un exemple de coopération Sud-Sud
                            <p className='font-medium mt-2'>
                                © Copyright 2023
                            </p>
                        </div>
                        {/* <div className="inline-block relative pr-8 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-3 before:-translate-y-1/2 before:content-['/'] before:text-gray-300 dark:before:text-gray-600">
                            <a className="inline-flex gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200" href="#">
                                Services
                            </a>
                        </div>
                        <div className="inline-block relative pr-8 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-3 before:-translate-y-1/2 before:content-['/'] before:text-gray-300 dark:before:text-gray-600">
                            <a className="inline-flex gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200" href="#">
                                Blog
                            </a>
                        </div> */}
                    </div>
                    <div className='text-lg font-medium' >
                        <p>Suivez nous</p>
                        <div className="md:text-center space-x-2 mt-2">
                            <a className="footerSocialLink" href="https://www.linkedin.com/company/flag-cameroon">
                                <AiFillLinkedin
                                    size={28}
                                />
                            </a>
                            <a className="footerSocialLink" href="https://www.facebook.com/OngFLAG/">
                                <AiFillFacebook
                                    size={28}
                                />
                            </a>
                            {/* <a className="footerSocialLink" href="#">
                                <AiFillGoogleCircle
                                    size={28}
                                />
                            </a> */}
                            <a className="footerSocialLink" href="https://twitter.com/flagcameroon">
                                <AiFillTwitterCircle
                                    size={28}
                                />
                            </a>
                        </div>
                        <div>
                            <CustomButton
                                icon={<MdContactMail size={18}/>}
                                navigateTo={'/contact'}
                                className='text-xs font-medium mt-4 mx-auto flex-row-reverse'
                                title='Nous Contacter'
                            />
                            <CustomButton
                                icon={<MdPerson size={18}/>}
                                className='text-xs font-medium mt-2 mx-auto flex-row-reverse'
                                title={authContext?.user? authContext.isAdmin?(authContext.user.email+' (Admin)'):authContext.user.email:'Connexion'}
                                navigateTo={authContext?.isAdmin?'/admin':undefined}
                                onClick={() => {if(!authContext?.user) setSignInVisible(true)}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SignIn
                hideModal={() => setSignInVisible(false)}
                showModal={signInVisible}
            />
        </footer>
    )
};

export default Footer;